import React, { Component } from 'react';
import Backend from './backend'

export default class YouTubeView extends Component {

    constructor(props) {
        super(props)
        this.backend = Backend.get();;
    }

    componentDidMount() {
    }

    render() {
      return <div>"Logging in..."</div>;
    }
}