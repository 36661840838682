import React, { Component } from 'react'
import { KEYCODES } from '../utils'
import EngagementManager from './EngagementManager'
import Backend from '../backend'
import remoteImage from '../../includes/images/remote.svg'
import _ from 'underscore';
import moment from 'moment';

const PROMOTION_TYPE = {
    'LINK': 'link',
}

const LINK_PROMOTION_MEDIA_TYPES = { IMAGE_THUMBNAIL:0,
                                     FULL_WIDTH_IMAGE:1,
                                     FULL_WIDTH_VIDEO:2,
                                     FULL_WIDTH_GLTF:3
                                    }

export default class Engagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            engagement: props.engagement,
            engagement_action: null,
            show_engagement: true,
            show_content: false,
            promotion_ttl: null,
            ack: null
        }

        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.executeEngagementAction = this.executeEngagementAction.bind(this)
        this.setupTimer = this.setupTimer.bind(this)
        this.promotion_auto_remove_timer = null;
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress, true)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress, true)
    }

    handleKeyPress(event) {
        if (this.state.engagement) {
            event.stopPropagation()
            event.preventDefault()
            switch (event.keyCode) {
                case KEYCODES.ENTER:
                    this.executeEngagementAction()
                    break;
                case KEYCODES.ESC:
                    EngagementManager.removeEngagement()
                    break;
                default:
                    break;
            }
            return false;
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.engagement !== this.state.engagement) {
            let { engagement_action, promotion_ttl, ack } = this.getEngagementActionAndTTL(nextProps.engagement)
            this.setState({
                engagement: nextProps.engagement,
                show_content: false,
                engagement_action,
                promotion_ttl,
                ack
            }, this.setupTimer)
        }
    }


    executeEngagementAction() {
        if (this.state.engagement_action) {
            if (!_.isEmpty(this.state.ack)){
                let ack = this.state.ack
                ack['ttl'] = 5000
                let engagement = { ...this.state.engagement}
                engagement['action_meta'] = { promotion_type: PROMOTION_TYPE['LINK'], link_promotion: ack }
                let { engagement_action, promotion_ttl } = this.getEngagementActionAndTTL(engagement)
                Backend.getEnv().actionsManager.executeAction(this.state.engagement_action)
                console.log(engagement, engagement_action, promotion_ttl, ack);
                EngagementManager.removeEngagement()
                EngagementManager.addEngagement(engagement)

                this.setState({
                    show_content: false,
                    ack: null,
                    engagement_action: null,
                    engagement,
                    promotion_ttl,
                }, this.setupTimer)
            }else{
                Backend.getEnv().actionsManager.executeAction(this.state.engagement_action)
                EngagementManager.removeEngagement()
            }
        }

    }

    getEngagementActionAndTTL(engagement) {
        if (engagement && engagement.action_meta) {
            let action = engagement.action_meta
            
            switch (action.promotion_type) {
                case PROMOTION_TYPE['LINK']:
                    let link_promotion, promotion_ttl, ack, expires_at
                    link_promotion = action.link_promotion
                    promotion_ttl = link_promotion.ttl
                    ack = {}
                    if (link_promotion.is_ack_available){
                        let ack_keys = Object.keys(link_promotion).map(key => {
                            if (key.startsWith('ack_')) {
                                ack[key.replace('ack_', '')] = link_promotion[key]
                            }
                        })
                    }
                    return { engagement_action: link_promotion.action, promotion_ttl, ack }

                default:
                    return {}
            }
        }
        return {}
    }

    getPromotion() {
        let action_meta = this.state.engagement.action_meta
        console.log(action_meta.promotion_type);
        
        switch (action_meta.promotion_type) {
            case PROMOTION_TYPE['LINK']:
                let link_promotion = action_meta.link_promotion
                let ttl = link_promotion.ttl
                console.log(link_promotion);
                
                if(link_promotion.media_type===1){
                    return <div className='d-flex h-100 p-2 box-sizing-border-box'>
                        <img src={link_promotion.image} onError={()=>{EngagementManager.removeEngagement()}}/>
                    </div>
                } else{

                
                return <div className='d-flex h-100 p-2 box-sizing-border-box'>
                   
                    {link_promotion.image && <div className='promotion-img-container'>
                        <img src={link_promotion.image} alt="" className='promotion-img' />
                    </div>}
                    <div className='d-flex flex-column justify-content-center' style={{flexGrow: 1}}>
                        <h4 className='promotion-label'>{link_promotion.label}</h4>
                        <h2 className='promotion-title'>{link_promotion.display_title}</h2>
                        <h5 className='promotion-subtitle'>{link_promotion.display_subtitle}</h5>
                        {link_promotion.button_text && <div>
                            <button className="promotion-btn">{link_promotion.button_text}</button>
                        </div>}
                    </div>
                    
                    {link_promotion.show_chrome && <div className='remote-img-container'>
                        <div className="promotion-instruction">
                            Press OK on <br />
                            your TV Remote
                        </div>
                        <div className='d-inline-block p-relative h-100'>
                            <img className='remote-img roll-in' src={remoteImage} alt="" />
                            <div className="promotion-countdown">
                                <svg className='circle-container' viewBox="0 0 32 32">
                                    <circle className='circle' r="16" cx="16" cy="16" style={{ "animation": `countdown ${ttl}ms linear infinite forwards` }} />
                                </svg>
                            </div>
                        </div>
                    </div>}
                </div>
                }
            default:
                return null
        }
    }

    setupTimer() {
        console.log(this.state.promotion_ttl);
        if (this.state.promotion_ttl) {
            clearTimeout(this.promotion_auto_remove_timer)
            this.promotion_auto_remove_timer = setTimeout(() => {
                EngagementManager.removeEngagement()
            }, this.state.promotion_ttl)
        }
    }

    render() {

        if (!this.state.show_engagement === true || !this.state.engagement) {
            return (<div></div>)
        }
        let promotion = this.getPromotion()
        console.log("promotion",this.state.engagement, promotion);
        if(!promotion){
            return <div></div>
        }
        return (
            <div className='engagement-strip-container'>

                <div className='engagement-strip' onAnimationEnd={() => {
                    setTimeout(() => { this.setState({ show_content: true }) }, 0)

                }}>

                    {promotion}
                </div>

            </div>
        )
    }
}
