import React, { Component } from 'react'
import Backend from './backend'
import { DPadNav } from './dpadnav'
import loaderSVG from '../includes/images/loader.svg'
import tvImg from '../includes/images/tv.png'
import connectWithAlexaStepsImg from '../includes/images/connect_alexa_steps.png'
import alexaEnabledLogo from '../includes/images/amazon_alexa_enabled.svg'

const API_OTP_ENDPOINT = 'api/v2/chatbot/auth_code'
export default class PairWithAlexaView extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            is_loading:true,
            auth_code:null,
        }
        this.backend = Backend.get()
        this.nav = new DPadNav(this.state.navState);
        this.fetchOTPForPairing = this.fetchOTPForPairing.bind(this);
    }
    componentDidMount(){
        this.fetchOTPForPairing()
        this.nav.bind(document);
        let that = this;
        this.nav.onNavStateChanged = function (navState) {
            that.setState({
                navState: navState
            });
        }
        let env = Backend.getEnv()
        env.hidePip()
    }
    fetchOTPForPairing(){
        this.backend.postForJSONResult(API_OTP_ENDPOINT, {}, (err, response)=> {
            if(err){
                console.log("error while fetching OTP")
                return
            }
            console.log(response)
            this.setState({
                auth_code:response.auth_code,
                is_loading:false
            })
        });
    }
   
    
    render() {
        
        return (
            <div className='alexa-screen'>
                {
                    this.state.is_loading &&
                    <img className="loader" alt="Loading" src={loaderSVG} />
                }
                {
                    !this.state.is_loading && <div>
                        <div className="FullOpaqueOverlay"></div>
                        <div>
                            <div className='pull-right'>
                                <div className='olap-container'>
                                    <img src={tvImg} alt="" className='olap-image' />
                                    {this.state.auth_code && <div className='olap-content'>
                                        OTP to Connect
                            <br /><br />
                                        <div className='thicc-text'>{this.state.auth_code}</div></div>
                                    }
                                </div>
                            </div>
                            <br /><br />
                            <img src={alexaEnabledLogo} alt="" className='alexa-enabled-logo' />
                            <div className='thicc-text'> Connect to Amazon Alexa</div>
                            <span className='light-text'> Let's get started! Follow these steps.</span>
                        </div>
                        <div className="clearBoth" />
                        <img src={connectWithAlexaStepsImg} alt="" style={{ maxWidth: '100%', height: 'auto' }} className='mb-5' />
                    </div>
                }
                
            </div>
        )
    }
}
