import React, { Component } from 'react'
import Backend from '../backend'
import { KEYCODES, checkIsFullScreen, goFullScreen } from '../utils';
import { MediaDetail } from '../models'
import { Spinner } from 'react-bootstrap'
import { DPadNav } from '../dpadnav';

const API_DETAIL = "api/v4/epg/detail/";
const VIEW_KEY = "panorama";


export default class Panorama extends Component {

    constructor(props) {
        super(props)

        this.state = {
            index: 0,
            items: [],
            isLoading: false,
        }
        this.panoramaRef = React.createRef()
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.backend = Backend.get()
        this.nav = DPadNav.get()
        this.fetchPanoramaDetails = this.fetchPanoramaDetails.bind(this)
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);
        if (this.panoramaRef?.current) {
            this.fetchPanoramaDetails()
            goFullScreen(this.panoramaRef.current)
        } else {
            console.error("something went wrong - couldn't go fullscreen")
        }

        this.nav.pushToNavStateStack({ //doesn't matter
            "row": 1,
            "cols": {}
        })
        this.nav.bind(document);

        this.nav.onNavStateChanged = (navState) => {
            // this.setState({
            //     navState: navState
            // }); // doesn't matter
            this.backend.setNavState(VIEW_KEY, this.nav);
        }
        this.nav.onKeyDown = (event) => {
            return true
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
        this.nav.popFromNavStateStack()
    }

    handleKeyPress(event) {
        event.stopPropagation();
        let index = this.state.index
        switch (event.keyCode) {
            case KEYCODES.LEFT:
                index -= 1;
                if (index <= -1) {
                    if (this.state.items?.length) {
                        index = this.state.items.length - 1
                    } else {
                        index = 0
                    }
                }
                this.setState({
                    index
                })
                break;
            case KEYCODES.RIGHT:
                index += 1
                if (index >= this.state.items?.length) {
                    index = 0
                }
                this.setState({
                    index
                })
                break;
        }
    }


    fetchPanoramaDetails() {
        console.log(this.props.panorama_identifier)
        let [itemType, itemId] = this.props.panorama_identifier?.split(':')

        console.log("Fetching pano details: " + itemType + ", " + itemId);

        this.setState({
            "items": [],
            "isLoading": true
        });

        this.backend.getJSONResult(API_DETAIL + itemType + "/" + itemId, (err, response) => {
            if (err) {
                this.setState({
                    "error": true,
                    "items": []
                })
            } else {
                console.log(response)
                let mdp = new MediaDetail(response)
                let first_row = mdp.mediaRows.length > 0 ? mdp.mediaRows[0] : null
                let items = []
                if (first_row) {
                    items = first_row.mediaItems
                }
                this.setState({
                    "items": items,
                    "isLoading": false
                });
            }

        });
    }


    render() {
        let content
        // console.log("mediaRow.mediaItems", this.props.items)
        let item = this.state.items[this.state.index]
        let image = item?.image_hd || item?.image
        content = <img className='fullscreen-image-candidate' style={{ zIndex: "2" }} src={image} />
        console.log("rendering pano", item)
        return (
            <div className='panorama center' style={{ position: "relative" }} ref={this.panoramaRef}>
                <div className='center' style={{ position: "absolute", width: "100%", height: "100%" }}>
                    <Spinner animation="border" variant="info" />
                </div>
                {content}
            </div>
        )
    }
}
