import React, { Component } from 'react';
import { TVProviderPreference } from './models'
import Backend from './backend'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import backImg from '../includes/images/icon_back.png'
import searchImg from '../includes/images/icon_search.png'
import homeImg from '../includes/images/icon_home.png'
import powerIcon from '../includes/images/icon_power.png'
import personIcon from '../includes/images/icon_person.png'
import settingsIcon from '../includes/images/icon_settings.png'
import languageIcon from '../includes/images/icon_language.png'
import alexaIcon from '../includes/images/icon_alexa.png'
import providerIcon from '../includes/images/icon_provider.png'
import sensyLogo from '../includes/images/logo_sensy.png'

var API_LANDING_TABS = "api/v4/epg/detail/landing/tabs"
const CHANNELS_TAB_KEY = "channels"

class SearchBar extends Component {

    constructor(props) {
        super(props)

        this.state = {}

        this.backend = Backend.get();
        this.env = Backend.getEnv();
        this.onPowerClicked = this.onPowerClicked.bind(this);
        this.isColFocused = this.isColFocused.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        let that = this;
        this.backend.getJSONResultCached(API_LANDING_TABS, 1000 * 60 * 60, function (err, response) {
            if (response) {
                let tabs = response.media_rows[0].media_items
                that.setState({
                    tabs
                })

                console.log("Got tabs", tabs);
            }
        });
    }

    onPowerClicked() {
        this.env.sendRemoteKey("POWER")
    }

    isColFocused(col) {
        if (!this.props.isFocused) {
            return false;
        }
        return this.props.focusedCol === col;
    }

    should_show_special() {
        let build_type = Backend.getBuildType()
        if (build_type === 'cloudDemo') {
            return true
        }
        return false
    }

    getTabs(tabs) {
        let nonAppTabs = tabs.filter(tab => tab.item_type !== "app")
        let appTabs = tabs.filter(tab => tab.item_type === "app").slice(0, 6)
        let has_home = false
        let filtered_tabs = []
        for (const tab of tabs) {
            if (nonAppTabs.includes(tab) || appTabs.includes(tab)) {
                filtered_tabs.push(tab)
            }
            if (tab.item_id === 'home') has_home = true
        }
        return [filtered_tabs, has_home]
    }


    render() {
        let pathname = this.props.location.pathname
        let home_mode = pathname && pathname.indexOf("/on_air") !== -1
        let { tabs } = this.state

        let col_counter = 0
        let has_home = false
        // if (tabs && !this.env.isHybridMode()){
        //     tabs = tabs.filter(t=>t.item_id!==CHANNELS_TAB_KEY)
        // }
        if (tabs) {
            [tabs, has_home] = this.getTabs(tabs)
        }

        return (
            <div className="SearchBar NavRow row">
                {
                    !home_mode && <div className="detail-page-search-bar d-flex align-items-center">
                        <span className={"NavCard searchIconContainer center " + (this.isColFocused(col_counter++) ? " cardFocus" : "")}>
                            <Link className="HeaderTabs" onClick={() => {
                                console.log(this.props)
                                this.props.history.goBack()
                            }}>
                                <img className="icon" alt="Back" src={backImg} />
                            </Link>
                        </span>
                        <span key={0} className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                            <Link to='/on_air' className="HeaderTabs">
                                <img className="searchIcon" alt="Home" src={homeImg} />
                            </Link>
                        </span>
                        <span className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                            <Link to='/search' className="HeaderTabs">
                                <img className="searchIcon" alt="Search" src={searchImg} />
                            </Link>
                        </span>
                    </div>
                }



                {
                    home_mode &&
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div className='d-flex align-items-center'>
                            <img className="sensyLogo" alt="Sensy" src={sensyLogo} />
                            {!has_home && <span key={0} className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                                <Link to='/on_air' className="HeaderTabs">
                                    Home
                                </Link>
                            </span>}
                            {tabs && <>
                                {
                                    tabs.map((tab, index) => <span key={index + 1} className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                                        <Link to={tab.item_id.toLowerCase() === 'home' ? '/on_air' : '/detail/' + tab.item_type + '/' + tab.item_id} className="HeaderTabs">
                                            {tab.item_type === 'icon'
                                                ? <img style={{display: "inline-block"}} className="sensyLogo" alt="Sensy" src={tab.image} />
                                                : tab.title}
                                            { }
                                        </Link>
                                    </span>)
                                }
                            </>}
                        </div>
                        <div className='d-flex align-items-center'>

                            <span className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                                <Link to='/search'>
                                    <img className="searchIcon iconSettings" alt="Search" src={searchImg} />
                                </Link>
                            </span>
                            <span className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                                <Link to="/you">
                                    <img className="icon iconSettings" alt="Sensy" src={personIcon} />
                                </Link>
                            </span>
                            {this.env.isAlexaEnabled() && <span className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                                <Link to="/settings/pair_with_alexa">
                                    <img className="icon iconSettings" alt="Sensy" src={alexaIcon} />
                                </Link>
                            </span>}
                            {this.env.isLanguageSelectionEnabled() && <span className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                                <Link to='/settings/languages'>
                                    <img className="icon iconSettings" alt="Sensy" src={languageIcon} />
                                </Link>
                            </span>}
                            {
                                this.props.isHybridMode &&
                                <span className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                                    <Link to="/settings/provider">
                                        <img className="icon iconSettings" alt="Sensy" src={providerIcon} />
                                    </Link>
                                </span>
                            }
                            {
                                this.props.isHybridMode &&
                                <span className={(this.isColFocused(col_counter++) ? "NavCard cardFocus" : "NavCard")} >
                                    <a onClick={this.onPowerClicked}>
                                        <img className="icon iconSettings c-pointer" alt="power" src={powerIcon} />
                                    </a>
                                </span>
                            }
                            <div className="clearBoth" />
                        </div>
                    </div>

                }

            </div>
        )
    }
}

export default withRouter(SearchBar)