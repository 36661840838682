import React from 'react'
import './spinner.scss'

export default function Spinner(props) {
    console.log(props);
    
    return (
        <div className={"ispinner ispinner-large " + props.classNames} >
            {[...new Array(12).keys()].map(idx => <div key={idx} className="ispinner-blade" />
            )}
        </div>
    )
}
