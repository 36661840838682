import React, { Component } from 'react';
import Backend, { CSRF_TOKEN } from './backend'
import { messaging, can_use_fcm } from "./init-fcm";
import AuthWall from './AuthWall';
import { UserInterestsManager } from './models';
import { setUserProfile } from '../actions';
import { connect } from "react-redux";

class LoginRequiredComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
        }
        this.backend = Backend.get();
        this.fetchAndSetupToken = this.fetchAndSetupToken.bind(this);
        this.sendTokenToServer = this.sendTokenToServer.bind(this);
        this.performLogin = this.performLogin.bind(this)
    }

    async componentDidMount() {
        this.performLogin()
    }

    async performLogin() {
        let { setUserProfile } = this.props
        let curr_url = new URL(window.location)
        console.log("checking login status")
        if (!this.backend.isLoggedIn()) {
            console.log('not logged in')
            let token = curr_url.searchParams.get('code')
            if (token) {
                this.backend.completeLogin(() => {
                    this.setState({
                        is_loading: false
                    })
                })
            } else {
                // this.backend.startLogin(window.location);
                this.setState({
                    is_loading: false
                })
            }
        } else {
            console.log("Can use FCM and Alexa: ", can_use_fcm)
            if (can_use_fcm) {
                document.addEventListener('service-worker-registration-success', async () => {
                    await this.fetchAndSetupToken()
                })
            }
            let userProfile = await UserInterestsManager.getUserProfile()
            setUserProfile(userProfile)
            this.setState({
                is_loading: false,
            })
        }
    }

    async fetchAndSetupToken() {
        console.log("setting up fcm")
        try {
            const _ = await messaging.requestPermission()
        } catch (err) {
            console.log("Unable to get permission to notify.", err);
            return;
        }
        try {
            const token = await messaging.getToken();
            if (token && token !== 'null') {
                window.localStorage.setItem('fcmToken', token);
                if (CSRF_TOKEN) {
                    console.log("csrf token available - setting up fcm")
                    this.sendTokenToServer(token);
                } else {
                    console.log("csrf token not available delaying fcm setup")
                    document.addEventListener('csrf-token-available', () => {
                        console.log("csrf token available event received - setting up fcm")
                        this.sendTokenToServer(token)
                    })
                }
            } else {
                console.log(`FCM token is null: ${token}`)
            }
        } catch (err) {
            console.log('An error occurred while retrieving FCM token. ', messaging, err);
            this.setTokenSentToServer(false);
            return;
        }

        messaging.onTokenRefresh(async () => {
            try {
                const refreshedToken = await messaging.getToken()
                console.log('FCM Token refreshed.');
                this.setTokenSentToServer(false);
                window.localStorage.setItem('fcmToken', refreshedToken);
                this.sendTokenToServer(refreshedToken);
            } catch (err) {
                console.log('Unable to retrieve refreshed FCM token ', err);
            }
        })

        navigator.serviceWorker.addEventListener('message', (message) => {
            console.log(message)
            let chat_message = JSON.parse(message.data['firebase-messaging-msg-data']?.data?.chat_message || message.data?.data?.chat_message);
            console.log("Push Message", chat_message)
            Backend.getEnv().actionsManager.executeAction(chat_message, (data) => {
                if (data) {
                    this.props.history.push(data)
                }
            })
        });
    }

    sendTokenToServer(token) {
        if (!this.isTokenSentToServer()) {
            console.log(`Sending FCM token to server...`);
            this.backend.postFCMToken(token, (err, response) => {
                if (err) {
                    this.setTokenSentToServer(false);
                } else {
                    this.setTokenSentToServer(true);
                }
            })
        } else {
            console.log("FCM Token already sent to server so won't send it again unless it changes");
        }
    }

    isTokenSentToServer() {
        let fcmToken = window.localStorage.getItem('fcmToken')
        if (fcmToken === 'null' || fcmToken === 'undefined' || window.localStorage.getItem('fcmTokenSentToServer') === '0') {
            return false
        }
        return window.localStorage.getItem('fcmTokenSentToServer') === '1';
    }

    setTokenSentToServer(sent) {
        window.localStorage.setItem('fcmTokenSentToServer', sent ? '1' : '0');
    }

    render() {
        let isLoggedIn = this.backend.isLoggedIn()
        if (isLoggedIn) {
            return <React.Fragment>{this.props.children}</React.Fragment>
        } else if (this.state.is_loading) {
            return null
        } else {
            return <AuthWall onLoggedIn={() => {
                this.performLogin()
            }} />;
        }
    }
}

const mapStateToProps = state => ({
    userProfile: state.userProfile,
});

const mapDispatchToProps = dispatch => ({
    setUserProfile: (userProfile) => dispatch(setUserProfile(userProfile)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginRequiredComponent);
