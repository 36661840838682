import React, { Component } from 'react';
import Logger from './Logger'

import Backend from './backend'
import SearchBar from './SearchBar';

import { Channel, OnAirItem } from './models'
import NavHelper from './NavHelper'

import { DPadNav } from './dpadnav'

import OnAirItemView from './OnAirItemView'
import ChannelCard from './ChannelCard'
import TimelineItemCard from './TimelineItemCard'
import DeeplinkListView from './DeeplinkListView'
import MoreChannelsLinkCard from './MoreChannelsLinkCard'
import EngagementManager from './Engagement/EngagementManager';
import loaderSVG from '../includes/images/loader.svg'

const API = "api/v1/epg/on_air"
const API_CHANNELS = "api/v1/epg/channels?type=top"
const API_TIMELINE_FEED = "api/v1/epg/timeline/live/"
const API_EPISODE_DETAILS = "api/v1/epg/episode/__EPISODE_ID__?no_details=true"

const VIEW_KEY = "tv";

export default class TVView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            "isLoading": true,
            "isSearchActive": false,
            "channelsEpisodeIndex": {},
            "navState": {
              "row": 1,
              "cols": {}
            },
            "isOverlayActive": false,
            "feedId": props.match.params.id,
        }

        this.backend = null;
        this.fetchData = this.fetchData.bind(this);
        this.fetchChannels = this.fetchChannels.bind(this);
        this.fetchTVMoments = this.fetchTVMoments.bind(this);
        this.onSearchStateChanged = this.onSearchStateChanged.bind(this);

        this.nav = DPadNav.get();
    }

    componentWillReceiveProps(props) {
        this.setState({
            "isOverlayActive": false,
            "feedId": props.match.params.id,
        });
        var that = this;
        setTimeout(function() {
            that.fetchTVMoments();
        }, 50);
        this.nav.reset();
    }

    componentDidMount() {

        console.log("TV View mounted");

        Backend.getEnv().showPip();

        var that = this;
        setTimeout(function() {
            that.backend = Backend.get();
            that.fetchData();
            that.fetchTVMoments();
        }, 50);
        this.nav.bind(document);
        this.nav.setNavState(this.state.navState);

        this.nav.onOKPressed = function() {
            if (!that.state.isOverlayActive){
                that.setState({
                    "isOverlayActive": true
                })
                console.log("OK pressed");
                return true;
            }
            return false;
        }

        this.nav.onBackPressed = function() {
            console.debug("On Back pressed");
            if (that.state.isOverlayActive) {
                that.setState({
                    "isOverlayActive": false
                });
                return true;
            }
            return false;
        }

        this.nav.onNavStateChanged = function(navState) {
          that.setState({
            navState: navState
          },()=>{
              that.nav.scrollContent()
              that.backend.setNavState(VIEW_KEY, that.nav);
          });
          // if (navState.row !== row_search) {
          //   document.querySelector(".searchBar").blur();
          // }
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    fetchChannels() {
        var that = this;
        that.backend.getJSONResultCached(API_CHANNELS, 1000 * 60 * 5, function(err, response) {
            var channels = Channel.fromList(response);

            that.setState({
                "channels": channels,
                "tv_inputs": [
                    {
                        "id": 1,
                        "title": "HDMI 1",
                        "image": "./static/images/ic_input_hdmi.png"
                    },
                    {
                        "id": 2,
                        "title": "HDMI 2",
                        "image": "./static/images/ic_input_hdmi.png"
                    },
                    {
                        "id": 3,
                        "title": "HDMI 3",
                        "image": "./static/images/ic_input_hdmi.png"
                    },
                    {
                        "id": 4,
                        "title": "AV",
                        "image": "./static/images/ic_input_hdmi.png"
                    },
                ],
                "isLoading": false
            })

            that.backend.getChannelGroups(function(channel_groups, indexed_groups) {

                var channelsEpisodeIndex = {};

                var recommendedChannels = [];
                for (var i=0; i<channels.length; i++) {
                    recommendedChannels.push(channels[i].id);
                }

                for (i in channel_groups) {
                    var group = channel_groups[i];
                    for (var j in group.episodes) {
                        var episode = group.episodes[j];
                        channelsEpisodeIndex[episode.channel_id] = episode;
                    }
                    
                }
                that.setState({
                    "channelsEpisodeIndex": channelsEpisodeIndex
                })

                that.fetchTVMoments();
            });

        });

    }

    fetchData() {
        var that = this;
        this.setState({
            "isLoading": true,
        })


        that.backend.getJSONResultCached(API, 1000 * 60 * 5, function(err, response) {

            var on_air_items = OnAirItem.fromList(response.result);

            that.setState({
                "on_air_items": on_air_items,
                "isLoading": false

            })
        });

        setTimeout(function() {

            that.fetchChannels();

            // that.backend.getJSONResultCached(API_PEOPLE, 1000 * 60 * 45, function(err, response) {

            //     that.setState({
            //         "facets": response.results,
            //     })

            // });

            // that.backend.getJSONResultCached(API_DEEPLINKS, 1000 * 60 * 45, function(err, response) {

            //     that.setState({
            //         "deeplinks": response.deeplinks,
            //     })

            // });

        }, 200);

    }

    fetchTVMoments() {
        const feedId = this.state.feedId;

        const endpoint = API_TIMELINE_FEED + feedId;

        if (feedId === 0 || !feedId) {
            console.debug("TVMoments: No feedID " + endpoint);
            return;
        }

        console.log("Fetching TV Moments from " + endpoint);

        const that = this;

        that.backend.getJSONResult(endpoint, function(err, response) {

            if (!window.location.href.includes("/tv/")) {
                console.debug("Got TV Moments. But not on TVView. Will exit.");
                return;
            }

            console.debug("Got TV Moments", response);

            that.setState({
                "timeline_feed": response,
            });

            that.timer = setTimeout(that.fetchTVMoments, 5000);
        });

        var episode = this.state.channelsEpisodeIndex[this.state.feedId];
        if (episode) {
            const episodeDetailsEndpoint = API_EPISODE_DETAILS.replace("__EPISODE_ID__", episode.id);
            that.backend.getJSONResultCached(episodeDetailsEndpoint, 1000 * 60 * 60, function(err, response) {
                if (!err) {
                    console.debug("EPP2.4", response);
                    that.setState({
                        "episodeDetail": response
                    });
                }
            })
        }

    }

    onSearchStateChanged(state) {
        this.setState({
            "isSearchActive": state
        })
    }


    render() {

        var row_search = -1;
        var row_tv_moments = -1;
        var row_channels = -1;
        var row_episodes = -1;
        var row_facets = -1;

        if (!this.state.isOverlayActive) {
            return <div>
                <video id="eng-demo-video" controls>
                  <source src="http://index.ext.sensalore.com/scratch/clips/DemoClip.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <EngagementManager />
            </div>;
        }

        var counter = 0;
        row_search = counter;
        var searchBar = <SearchBar nav={this.nav} onStateChanged={this.onSearchStateChanged}
            isFocused={this.nav.isFocusedRow(row_search)}
            focusedCol={this.nav.getColPos(counter)} />;
        counter++

        var i=0, feed_items = [], episode;
        if (!this.state.isSearchActive && this.state.timeline_feed && this.state.timeline_feed.feed_items && this.state.timeline_feed.feed_items.length > 0) {
            row_tv_moments = counter;
            for (i=0; i < this.state.timeline_feed.feed_items.length; i++) {
                var feed_item = this.state.timeline_feed.feed_items[i];
                feed_items.push(<TimelineItemCard key={i} item={feed_item}
                isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(counter)===i}  />);
            }
        }

        if (this.state.channelsEpisodeIndex) {
            row_tv_moments = counter;
            episode = this.state.channelsEpisodeIndex[this.state.feedId];
            if (episode) {
                var showItem = {
                    "title": episode.getTitle(),
                    "item_type": "episode",
                    "item_id": episode.id,
                    "image": episode.image,
                    "timestamp": 0
                }
                feed_items.push(<TimelineItemCard key={i} item={showItem}
                    isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(row_tv_moments)===i}  />);
                i++;
            }

            const feeditemsLength = feed_items.length;

            if (this.state.episodeDetail) {
                const facets = this.state.episodeDetail.show.facets;
                for (var j=0; j<facets.length; j++) {
                    const facet = facets[j];
                    const facetItem = {
                        "title": facet.title,
                        "item_type": "people",
                        "item_id": facet.id,
                        "image": facet.image,
                        "timestamp": 0
                    }
                    feed_items.push(<TimelineItemCard key={i} item={facetItem}
                        isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(row_tv_moments)===i}  />);
                    i++;
                }
            }
        }

        console.debug("State", this.state); 
        if (row_tv_moments !== -1) {
            counter++;
        }
        
        var channel_items = [];
        if (!this.state.isSearchActive && this.state.channels && this.state.channels.length > 0) {
            row_channels = counter;
            for (i=0; i < this.state.channels.length; i++) {
                var channel = this.state.channels[i];
                episode = this.state.channelsEpisodeIndex[channel.id];
                channel_items.push(<ChannelCard key={channel.id} channel={channel} episode={episode}
                isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(counter)===i}  />);
                if (channel_items.length === 8) {
                    break; // REMOVE THIS
                }
            }
            counter ++;
        }

        var items = [];
        if (!this.state.isSearchActive && this.state.on_air_items) {
            row_episodes = counter;
            for (i=0; i<this.state.on_air_items.length; i++) {
                var item = this.state.on_air_items[i];
                items.push(<OnAirItemView key={item.displayable} item={item} nav={this.nav}
                    row={counter} isFocusedRow={this.nav.isFocusedRow(row_episodes+i)}
                    focusedCol={this.nav.getColPos(counter)} />);
                counter++;                
            }
        }

        var facet_items = [];
        // if (!this.state.isSearchActive && this.state.facets && this.state.facets.length > 0) {
        //     for (i=0; i < this.state.facets.length; i++) {
        //         var facet = this.state.facets[i];
        //         facet_items.push(<FacetCard key={facet.id} facet={facet} 
        //         isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(counter)===i}  />);
        //     }
        //     counter ++;
        // }


        var deeplink_rows = [];
        // if (!this.state.isSearchActive && this.state.deeplinks) {
        //     for (i=0; i<this.state.deeplinks.length; i++) {
        //         item = this.state.deeplinks[i];
        //         deeplink_rows.push(<DeeplinkListView key={item.category_verbose} item={item}  nav={this.nav}
        //             row={counter} isFocusedRow={this.nav.isFocusedRow(counter)}
        //             focusedCol={this.nav.getColPos(counter)} />);
        //         counter++;
        //     }
        // }


        var translateStyleY = {
            // "transform": "translateY("+this.nav.getTranslateChannelsY(true)+"px)"
        }

        return (
        <div>
                <EngagementManager />

            {
                this.state.isLoading &&
                <img className="loader" alt="Loading" src={loaderSVG} />
            }
                <div className="transitionAll content-padding" style={translateStyleY}>

                {!this.state.isLoading && searchBar}
                {!this.state.isLoading && <div className="OnAirBanners"></div>}
                {
                    feed_items.length > 0 && 
                    <div className={"channelGroup NavRow " + (this.nav.isFocusedRow(row_tv_moments)?"activeRow":"inactiveRow")}>
                        <div className="cardTitle">TV Moments</div>
                        <div className="list-container" style={this.nav.getTranslateXStyle(row_tv_moments)}>
                            { feed_items }
                        </div>
                        <div className="clearBoth" />
                    </div>
                }
                {
                    channel_items.length > 0 && 
                    <div className={"channelGroup NavRow " + (this.nav.isFocusedRow(row_channels)?"activeRow":"inactiveRow")}>
                        <div className="cardTitle">Channels</div>
                        <div className="list-container" style={this.nav.getTranslateXStyle(row_channels)}>
                        { channel_items }
                        <MoreChannelsLinkCard isFocused={this.nav.isFocusedRow(row_channels) && this.nav.getColPos(row_channels)===8} />
                        </div>
                        <div className="clearBoth" />
                    </div>
                }
                {items}
                {
                    facet_items.length > 0 && 
                    <div className={"FacetGroup NavRow " + (this.nav.isFocusedRow(row_facets)?"activeRow":"inactiveRow")}>
                        <div className="cardTitle">People</div>
                        <div className="list-container" style={this.nav.getTranslateXStyle(row_facets)}>
                        { facet_items }
                        </div>
                        <div className="clearBoth" />
                    </div>
                }
                {deeplink_rows}
            </div>
            {
                (this.nav.isFocusedRow(row_search) || this.nav.isFocusedRow(row_tv_moments)) &&
                <div className="HeaderOverlay"></div>
            }
            {
                (this.nav.isFocusedRow(row_search) || this.nav.isFocusedRow(row_tv_moments)) &&
                <div className="BodyOverlay"></div>
            }            
            {
                (!this.nav.isFocusedRow(row_search) && !this.nav.isFocusedRow(row_tv_moments)) &&
                <div className="FullOpaqueOverlay"></div>
            }
            
            <NavHelper nav={this.nav} />
        </div>
        );
    }
}