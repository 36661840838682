import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UserInterestsManager } from './models';
import { ACTION_SET_MEDIA_ITEM_FAVORITE, ACTION_SET_MEDIA_ITEM_NOT_INTERESTED, ACTION_SET_MEDIA_ITEM_SEEN, ACTION_SET_MEDIA_ITEM_WATCHLIST, ACTION_VIEW_DEEPLINK, ACTION_VIEW_DETAIL, ACTION_VIEW_URL } from './SDKActionsManager';
import { ReactComponent as SeenIcon } from '../includes/images/icon_seen.svg'
import { ReactComponent as FavoriteIcon } from '../includes/images/icon_favorite.svg'
import { ReactComponent as BookmarkIcon } from '../includes/images/icon_bookmark.svg'
import { ReactComponent as DislikeIcon } from '../includes/images/icon_dislike.svg'
import { ReactComponent as NotSeenIcon } from '../includes/images/icon_not_seen.svg'
import { ReactComponent as NotFavoriteIcon } from '../includes/images/icon_not_favorite.svg'
import { ReactComponent as BookmarkedIcon } from '../includes/images/icon_bookmarked.svg'
import { ReactComponent as DislikedIcon } from '../includes/images/icon_disliked.svg'
import { setUserProfile } from '../actions';
import { connect } from "react-redux";

class MediaActionView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modified_content: null,
            is_favorite: false,
            is_seen: false,
            is_watchlisted: false,
            is_not_interested: false
        }
        this.applyProfileSpecificChanges = this.applyProfileSpecificChanges.bind(this)
    }


    componentDidMount() {
        this.applyProfileSpecificChanges()

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userProfile !== this.props.userProfile) {
            this.applyProfileSpecificChanges()
        }
    }

    applyProfileSpecificChanges() {
        let { mediaAction, userProfile } = this.props
        let new_title_types = [ACTION_SET_MEDIA_ITEM_FAVORITE, ACTION_SET_MEDIA_ITEM_SEEN, ACTION_SET_MEDIA_ITEM_WATCHLIST, ACTION_SET_MEDIA_ITEM_NOT_INTERESTED]
        let action_type = mediaAction?.chat_action?.action_type
        let action_id = mediaAction?.chat_action?.action_id
        if (new_title_types.includes(action_type)) {
            let item_type, item_id, arr;
            arr = action_id.split(":")
            item_type = arr[0]
            item_id = arr.slice(1).join(':')
            let userInterestsManager = new UserInterestsManager(userProfile)
            if (action_type === ACTION_SET_MEDIA_ITEM_FAVORITE) {
                if (userInterestsManager.isFavorite(item_type, item_id)) {
                    this.setState({
                        modified_content: <span> <FavoriteIcon className='size-2' /></span>,
                        is_favorite: true
                    })
                } else {
                    this.setState({
                        modified_content: <span> <NotFavoriteIcon className='not-selected size-2' /></span>,
                        is_favorite: false
                    })
                }

            } else if (action_type === ACTION_SET_MEDIA_ITEM_SEEN) {
                if (userInterestsManager.isSeen(item_type, item_id)) {
                    this.setState({
                        modified_content: <span> <SeenIcon className='size-2' /></span>,
                        is_seen: true
                    })
                } else {
                    this.setState({
                        modified_content: <span> <NotSeenIcon className='not-selected size-2' /></span>,
                        is_seen: false
                    })
                }

            } else if (action_type === ACTION_SET_MEDIA_ITEM_WATCHLIST) {
                if (userInterestsManager.isInWatchList(item_type, item_id)) {
                    this.setState({
                        modified_content: <span> <BookmarkedIcon className='size-2' /></span>,
                        is_watchlisted: true
                    })
                } else {
                    this.setState({
                        modified_content: <span> <BookmarkIcon className='not-selected size-2' /></span>,
                        is_watchlisted: false
                    })
                }

            } else if (action_type === ACTION_SET_MEDIA_ITEM_NOT_INTERESTED) {
                if (userInterestsManager.isInNotInterestedList(item_type, item_id)) {
                    this.setState({
                        modified_content: <span> <DislikedIcon className='size-2' /></span>,
                        is_not_interested: true
                    })
                } else {
                    this.setState({
                        modified_content: <span> <DislikeIcon className='not-selected size-2' /></span>,
                        is_not_interested: false
                    })
                }
            }
        }
        if (action_type === ACTION_VIEW_DEEPLINK && mediaAction?.title?.startsWith("Watch on") && mediaAction.icon){
            this.setState({
                modified_content: <div className="center">Watch on <img src={mediaAction.icon} className="MediaActionIcon" alt="" /></div>,
            })
        }
    }

    render() {
        let { modified_content, is_favorite, is_not_interested, is_seen, is_watchlisted } = this.state
        let { mediaAction, env } = this.props
        const chat_action = mediaAction.chat_action;
        let action_title = mediaAction.title
        let send_to_phone_action;
        var url = "#";
        var target = "";

        /** Should these exist?? **/
        if (chat_action.action_type === ACTION_VIEW_DETAIL) {
            url = "/detail/" + chat_action.action_id.replace(":", "/")
        } else if (chat_action.action_type === ACTION_VIEW_DEEPLINK || chat_action.action_type === ACTION_VIEW_URL || chat_action.action_type === "VIEW_VIDEO") {
            url = chat_action.action_id;
            target = "_blank";
        }
        if (chat_action.action_type === ACTION_VIEW_URL) {
            send_to_phone_action = true;
        }

        let content = modified_content || <span>{action_title}</span>
        let link = null;
        let linkOnClick = (event) => {
            event.preventDefault()
            if (send_to_phone_action) {
                console.log('sending action details')
                this.props.show_send_to_phone_card(mediaAction);
            } else {
                env.actionsManager.executeAction(chat_action, async () => {
                    console.log("executing callback")
                    let { setUserProfile } = this.props
                    let userProfile = await UserInterestsManager.getUserProfile()
                    setUserProfile(userProfile)
                }, {
                    is_favorite, is_not_interested, is_seen, is_watchlisted
                });
            }
        }
        let linkClassName = "MediaAction NavCard " + (this.props.isFocused ? "highlighted" : '')
        if (url.startsWith("http")) {
            link = <a href={url} target="_blank" rel="noopener noreferrer" className={linkClassName}>
                {content}
            </a>
        } else {
            link = <Link to={url} target={target} className={linkClassName} onClick={linkOnClick}>
                {content}
            </Link>
        }
        return <div className={"d-inline-block " +  (this.props.isFocused ? "cardFocus" : '')}>
            {link}
        </div>

    }
}

const mapStateToProps = state => ({
    userProfile: state.userProfile,
});

const mapDispatchToProps = dispatch => ({
    setUserProfile: (userProfile) => dispatch(setUserProfile(userProfile)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaActionView);
