let initialState = {
    userProfile: {},
}


const reduxStore = (state = initialState, action) => {
    console.log('action type ' + action.type, action)
    switch (action.type) {

        case "set-user-profile": {
            let { userProfile } = action
            return {
                ...state,
                userProfile
            }
        }

        default:
            console.log('executing default')
            return state
    }
}

export default reduxStore