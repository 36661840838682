import React, { Component } from 'react';

import moment from 'moment';

import CenterCropImage from './CenterCropImage';
import CardMeta from './CardMeta';

import { truncate } from 'underscore.string';
import { Link } from 'react-router-dom';
import Backend from './backend';

const MEDIA_ITEM_ACTION_TYPES = {
    SHOW_SLIDESHOW: "SHOW_SLIDESHOW",
    SHOW_PANORAMA: "SHOW_PANORAMA",
    SHOW_360_IMAGE: "SHOW_360_IMAGE",
    LAUNCH_SCREEN: "LAUNCH_SCREEN",
}


export default class MediaItemView extends Component {


    isPlayingNow(item) {

        if (!item.schedule) {
            return false;
        }

        const start = moment(item.schedule.start);
        const end = moment(item.schedule.start).add(item.schedule.duration, 'minutes');
        const now = moment();

        return now.isAfter(start) && now.isBefore(end);
    }

    getProgressParams(item) {

        if (!item.schedule) {
            return;
        }

        const start = moment(item.schedule.start);
        const end = moment(item.schedule.start).add(item.schedule.duration, 'minutes');
        const now = moment();

        if (start.isBefore(now) && end.isAfter(now)) {
            const startDiff = moment.duration(now.diff(start)).asMinutes();
            return {
                "width": ((startDiff / item.schedule.duration) * 100) + "%"
            }
        }

        return;
    }

    getPlayingStatus(item) {

        if (!item.schedule) {
            return;
        }

        const start = moment(item.schedule.start);
        const end = moment(item.schedule.start).add(item.schedule.duration, 'minutes');
        const now = moment();

        if (now.isBefore(start)) {
            return "Starts " + start.fromNow();
        }

        if (now.isAfter(end)) {
            return "Show has ended";
        }

        if (item.schedule.duration <= 30) {
            return "Playing now";
        }

        const startDiff = moment.duration(now.diff(start)).asMinutes();

        if (startDiff < 1) {
            return "Just Started";
        }

        if (startDiff < 20) {
            return "Started " + start.fromNow();
        }

        const endsIn = moment.duration(end.diff(now)).asMinutes();

        if (endsIn < 1) {
            return "Ends now";
        }

        if (endsIn < 20) {
            return "Ends " + end.fromNow();
        }

        return "Playing now";
    }

    getCardParams() {
        return {
            "maxWidth": this.props.displayConfig.width + "px"
        }
    }

    getImageParams() {

        const item = this.props.mediaItem;
        const displayConfig = this.props.displayConfig;


        var imageParams = {
            "height": displayConfig.height + "px",
            "width": displayConfig.width + "px",
        }

        if (item.image) {
            imageParams.backgroundImage = "url(" + item.image + ")";
        }

        return imageParams;

    }

    getUrl() {

        const item = this.props.mediaItem;

        if (item.item_type === "deeplink") {
            return item.item_id;
        }

        return "/detail/" + item.item_type + "/" + item.item_id;

    }


    render() {
        let { displayConfig, mediaItem: item } = this.props;
        let action
        if(item.actions){
            action = item.actions[0]// take the first action - until action selection popup is implemented
        }
        let is_slideshow = false, is_panorama = false, is_image_360 = false, is_launch_screen = false
        if (action?.chat_action?.action_type === MEDIA_ITEM_ACTION_TYPES.SHOW_SLIDESHOW) {
            is_slideshow = true
        } else if (action?.chat_action?.action_type === MEDIA_ITEM_ACTION_TYPES.SHOW_PANORAMA) {
            is_panorama = true
        } else if (action?.chat_action?.action_type === MEDIA_ITEM_ACTION_TYPES.SHOW_360_IMAGE) {
            is_image_360 = true
        } else if(action?.chat_action?.action_type === MEDIA_ITEM_ACTION_TYPES.LAUNCH_SCREEN){
            is_launch_screen = true
        }
        const playingStatus = this.getPlayingStatus(item)
        var subtitle = item.subtitle || "";
        if (playingStatus) {
            subtitle += " . " + playingStatus;
        }

        if (!displayConfig) {
            console.log("Warning: No display config available!!")
            displayConfig = {
                width: 105,
                height: 150
            }
        }
        var width = displayConfig.width * 1.4;
        var height = displayConfig.height * 1.4;

        var cardStyle = {
            width: width + "px",
        };

        var metaChars = Number.parseInt(width / 10, 10) - 3;
        var icons = [];
        if (item.icon_ids) {
            for (var i = 0; i < item.icon_ids.length; i++) {
                var iconId = item.icon_ids[i];
                var icon = this.props.icons[iconId];
                if (icon) {
                    icons.push(<img key={iconId} className="MediaItemIcon" src={icon.url} title={icon.title} />);
                }
            }
        }
        let content = <>
            <CenterCropImage src={item.image} alt={item.title}
                width={width} height={height} />
            <CardMeta title={truncate(item.title, metaChars)} subTitle={truncate(subtitle, metaChars * 1.4)} />
            <div className="cardOverlay" />
            <div className="MediaItemIcons">
                {icons}
            </div>
        </>

        return (
            <div className={"card mediaItemCard NavCard " + (this.props.isFocused ? "cardFocus" : "")} style={cardStyle}>

                {(!is_slideshow && !is_panorama && !is_image_360 && !is_launch_screen)
                    && (this.getUrl().startsWith("http")
                    ? <a href={this.getUrl()} target="_blank" rel="noopener noreferrer" >
                        {content}
                    </a>
                    : <Link to={this.getUrl()}>
                        {content}
                    </Link>
                    )
                }
                {
                    is_slideshow && <div className='a-alt' onClick={this.props.showSlideShow}>
                        {content}
                    </div>
                }
                {
                    (is_panorama || is_image_360 || is_launch_screen) && <div className='a-alt'
                        onClick={() => {
                            Backend.getEnv().actionsManager.executeAction(action?.chat_action)
                        }}
                    >
                        {content}
                    </div>
                }
            </div>
        )

    }
}
