import React from 'react'
import { KEYCODE_W, KEYCODE_A, KEYCODE_S, KEYCODE_D, KEYCODE_PLUS, KEYCODE_MINUS, KEYCODE_UP, KEYCODE_DOWN, KEYCODE_RIGHT, KEYCODE_LEFT } from '../dpadnav'

const CameraControlButton = ({ text, onPressed, className }) => {
    return <div className={"camera-control-button " + (className || '')} onClick={(event) => {
        event.preventDefault()
        onPressed();
    }}>
        {text}
    </div>
}

export default function OnScreenCameraController({ onCameraControlPress }) {
    return (
        <div className="on-screen-camera-controller-container">
            <div className="frosted-glass-container">
                <div className="frosted-glass" />
            </div>
            <div className="controls-grid">
                <div className="control-grid-item">
                    <CameraControlButton className='mx-auto' text="&#8593;" onPressed={() => onCameraControlPress(KEYCODE_UP)} />
                    <div className="d-flex">
                        <CameraControlButton text="&#8592;" onPressed={() => onCameraControlPress(KEYCODE_LEFT)} />
                        <CameraControlButton text="&#8595;" onPressed={() => onCameraControlPress(KEYCODE_DOWN)} />
                        <CameraControlButton text="&#8594;" onPressed={() => onCameraControlPress(KEYCODE_RIGHT)} />
                    </div>
                </div>
                <div className="control-grid-item" style={{alignSelf: "end", fontSize: "0.9em"}}><p> Pan/Tilt Camera </p></div>
                <div className="control-grid-item">
                    <div className="center">
                        <CameraControlButton text="+" onPressed={() => onCameraControlPress(KEYCODE_PLUS)} />
                        <CameraControlButton text="-" onPressed={() => onCameraControlPress(KEYCODE_MINUS)} />
                    </div>
                </div>
                <div className="control-grid-item" style={{ alignSelf: "end", fontSize: "0.9em"}}><p> Zoom In/Out </p></div>
            </div>
        </div>
    )
}