import Dexie from 'dexie';

var connection = null

export default class DatabaseManager {

    constructor() {
        this.db = null
        this.initializeDatabase = this.initializeDatabase.bind(this)
        this.setupTestData = this.setupTestData.bind(this)
    }

    initializeDatabase() {
        this.db = new Dexie('tizen')
        this.db.version(2).stores({
            screensaver_promotions: '++id,title,expires_at,promotion_id'
        });

        // this.setupTestData().catch(err => {
        //     console.error("Uh oh! " + err.stack);
        // });
    }

    async setupTestData() {

        var id = await this.db.tasks.put({ date: Date.now(), description: 'Test Dexie', done: 0 });
        console.log("Got id " + id);
        // Now lets add a bunch of tasks
        await this.db.tasks.bulkPut([
            { date: Date.now(), description: 'Test Dexie bulkPut()', done: 1 },
            { date: Date.now(), description: 'Finish testing Dexie bulkPut()', done: 1 }
        ]);
        // Ok, so let's query it

        var tasks = await this.db.tasks.where('done').above(0).toArray();
        console.log("Completed tasks: " + JSON.stringify(tasks, 0, 2));

        // Ok, so let's complete the 'Test Dexie' task.
        await this.db.tasks
            .where('description')
            .startsWithIgnoreCase('test dexi')
            .modify({ done: 1 });

        console.log("All tasks should be completed now.");
        console.log("Now let's delete all old tasks:");

        // And let's remove all old tasks:
        await this.db.tasks
            .where('date')
            .below(Date.now())
            .delete();

        console.log("Done.");
    }

    static getConnection() {
        if (!connection) {
            connection = new DatabaseManager()
            connection.initializeDatabase()
            console.log("getting idb connection")
            return connection.db
        }
        return connection.db

    }
}



