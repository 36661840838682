import React, { Component } from 'react';

export default class NavHelper extends Component {

  render() {

    var nav = this.props.nav;

    if (!nav.showHelper) return <div/>;

    var selectedRow = nav.navState.row;

    var rows = [];
    if (nav.getRow(0) !== undefined) {
        for (var i=0; i<nav.getRowCount(); i++) {
            rows.push(<div key={i} className={i===selectedRow?"navAxisValue selected":"navAxisValue"}>
                <span>{nav.getColPos(i)}</span>
                <span className={nav.navState.isSearchActive?"":"navParamActive"}>{nav.getItemCount(i)}</span>
            </div>)
        }        
    }

    return (<div className="navHelper">
        {rows}
    </div>)
  }
}