import React, { Component } from 'react'
import { DPadNav } from './dpadnav'
import { KEYCODES } from './utils'

const BACKSPACE = "⌫"
const GO = "GO"


export default class PhoneNumPad extends Component {
    constructor(props) {
        super(props)

        this.state = {
            input_value: '',
            "navState": {
                "row": 0,
                "cols": {},
            },
            curr_col: 0,
        }
        this.onButtonPressed = this.onButtonPressed.bind(this)
        this.nav = new DPadNav(this.state.navState);
        this.decrColPos = this.decrColPos.bind(this)
        this.incrColPos = this.incrColPos.bind(this)

    }

    decrColPos(row) {
        let navState = this.state.navState
        navState.cols[row] = this.state.curr_col;
        navState.cols[row] -= 1;
        if (navState.cols[row] < 0) {
            navState.cols[row] = 0;
        }
        return navState
    }

    incrColPos(row) {
        let navState = this.state.navState
        navState.cols[row] = this.state.curr_col;
        navState.cols[row] += 1;
        let itemCount = this.nav.getItemCount(row);
        if (navState.cols[row] >= itemCount) {
            navState.cols[row] = Math.max(0, itemCount - 1);
        }
        return navState
    }

    componentDidMount() {
        this.nav.bind(document);
        let that = this
        this.nav.onNavStateChanged = function (navState) {
            that.setState({
                navState: navState
            });
        }
        this.nav.onKeyDown = (event) => {
            let navState = this.state.navState
            let curr_col = this.state.curr_col
            switch (event.keyCode) {
                case KEYCODES.LEFT:
                    navState = this.decrColPos(navState.row);
                    curr_col = navState.cols[navState.row]
                    break
                case KEYCODES.RIGHT:
                    navState = this.incrColPos(navState.row)
                    curr_col = navState.cols[navState.row]
                    break
                case KEYCODES.UP:
                    navState.row = Math.max(0, navState.row - 1);
                    navState.cols[navState.row] = this.state.curr_col
                    break
                case KEYCODES.DOWN:
                    navState.row = Math.min(this.nav.getRowCount() - 1, navState.row + 1);
                    navState.cols[navState.row] = this.state.curr_col
                    break
                case KEYCODES.ENTER:
                    let button = document.querySelector("button.focus");
                    button.click()
                    break
            }
            console.log("setting ", navState.row, navState.cols[navState.row], navState.cols)
            // this.setState({
            //     navState,
            //     curr_col
            // })
            this.nav.setNavState(navState)
            this.setState({
                curr_col
            })


        }
    }

    onButtonPressed(num) {
        if (Number.isInteger(num)) {
            this.setState({
                input_value: this.state.input_value + num
            })
        } else {
            if (num === BACKSPACE) {
                let input_value = this.state.input_value
                this.setState({
                    input_value: input_value.slice(0, input_value.length - 1)
                })
            }
            else if (num === GO) {
                if (this.props.onOKPressed && typeof this.props.onOKPressed === "function") {
                    this.props.onOKPressed()
                }
            }
        }
    }

    render() {

        const nums = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [BACKSPACE, 0, GO]]
        let numPad = nums.map((num_row, row_index) => {
            return <div key={row_index} className='tv2phone-numpad-row NavRow'>
                {num_row.map((num, col_index) => {
                    return <button key={num} className={"tv2phone-numpad-num NavCard c-pointer" + (this.nav.isFocused(row_index, col_index) ? ' focus' : '')} onClick={this.onButtonPressed.bind(this, num)}>{num}</button>
                })}
            </div>
        })

        return (
            <div className='tv2phone-card'>
                <div style={{ marginBottom: "20px" }}>
                    <strong style={{ color: "#fff" }}>Send this to my devices</strong>
                    <div style={{ color: "#a4a4a4" }}>https://twitter.com/abcd</div>
                </div>
                <div style={{ marginBottom: "15px", color: "#fff" }}>Send to Mobile via SMS</div>
                <div style={{ marginBottom: "15px" }}>
                    <input value={this.state.input_value} type="text" className='tv2phone-num-input' onChange={(event) => {
                        if (!isNaN(parseInt(event.target.value))) {
                            this.setState({ input_value: event.target.value })
                        }
                    }} />
                </div>
                <div className="tv2phone-numpad">
                    {numPad}
                </div>
            </div>
        )
    }
}
