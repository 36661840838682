import moment from 'moment';
import Backend from './backend';

class Facet {
    constructor(props) {
        this.id = props.id;
        this.title = props.title;
        this.image = props.image;
    }

    static fromList(json_items) {
        var items = [];
        if (json_items && json_items.length > 0) {
            for (var i=0; i<json_items.length; i++) {
                items.push(new Facet(json_items[i]));
            }
        }
        return items;
    }
}

class Channel {
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.image = props.image;
    }

    static fromList(json_items) {
        var items = [];
        if (json_items && json_items.length > 0) {
            for (var i=0; i<json_items.length; i++) {
                items.push(new Channel(json_items[i]));
            }
        }
        return items;
    }

}

class Show {
    constructor(props) {
        this.id = props.id;
        this.title = props.title;
        this.image = props.image;
        this.description = props.description;

        this.facets = Facet.fromList(props.facets);
    }
}

class Episode {
    constructor(props) {
        this.id = props.id;

        this.title = props.title;
        this.image = props.image;

        this.show_id = props.show;
        this.channel_id = props.channel;

        this.starts_at = props.starts_at;
        this.duration = props.duration;

        this.getTitle = this.getTitle.bind(this);
    }

    getTitle() {

        if (!this.show) {
            return;
        }

        return this.show.title;
    }

    getImage() {

        if (this.image) {
            return this.image;
        }

        if (this.show) {
            return this.show.image;
        }
    }

    getDescription() {
        if (this.show) {
            return this.show.description;
        }        
    }


}

class EPG {
    constructor(props) {

        if (!props) {
            return;
        }

        var channels = {};
        for (var i=0; i<props.channels.length; i++) {
            var channel = new Channel(props.channels[i]);
            channels[channel.id] = channel;
        }

        var shows = {};
        for (i=0; i<props.shows.length; i++) {
            var show = new Show(props.shows[i]);
            shows[show.id] = show;
        }



        var episodes = [];
        for (i=0; i<props.episodes.length; i++) {
            var episode = new Episode(props.episodes[i]);
            episode.show = shows[episode.show_id];
            episode.channel = channels[episode.channel_id];
            episodes.push(episode);
        }

        this.episodes = episodes;
    }
}

var LANGUAGES = [    
    "Hindi", "English", "Tamil", "Telugu", "Malayalam", "Kannada", 
    "Bhojpuri", "Gujarati", "Bengali", "Marathi", "Punjabi", "Oriya", 
    "Assamese", "Rajasthani", "Urdu", "French", "Sindhi"
];

class ChannelGroup {

    constructor(props) {
        this.title = props.title;
        this.items = props.items;

        this.episodes = (new EPG(this.items)).episodes;

        this.getLanguage = this.getLanguage.bind(this);
        this.language = this.getLanguage();
    }

    static fromList(json_groups) {
        var groups = [];

        if (json_groups && json_groups.length > 0) {
            for (var i=0; i<json_groups.length; i++) {
                groups.push(new ChannelGroup(json_groups[i]));
            }
        }

        return groups;
    }

    getLanguage() {
        var title = this.title;
        var language = title.substr(0, title.indexOf(" "));

        if (title.indexOf(" ") === -1) {
            language = title;
        }

        return language;
    }

    static getIndex(groups) {

        var keys = [];
        var index = {};

        for (var i in groups) {
            var group = groups[i];
            var language = group.getLanguage();

            if (keys.indexOf(language) === -1 && LANGUAGES.indexOf(language) !== -1) {
                keys.push(language);
            }

            if (!index[language]) {
                index[language] = [];
            }

            index[language].push(group);
        }

        index._keys = keys;

        return index;
    }

}

class OnAirItem {

    constructor(props) {
        if (props) {
            this.displayable = props.displayable;
            this.epg = new EPG(props.epg);
        }
    }

    static fromList(json_items) {
        var items = [];
        if (json_items && json_items.length > 0) {
            for (var i=0; i<json_items.length; i++) {
                items.push(new OnAirItem(json_items[i]));
            }
        }
        return items;
    }

    static fromEpisodeList(episodeList, title) {
        var item = new OnAirItem();
        item.displayable = title;
        item.epg = new EPG();
        item.epg.episodes = episodeList;
        return item;
    }
}

class Deeplink {

    constructor(props) {
        this.thumbnail = props.thumbnail;
        this.title = props.title;
        this.url = props.url;
    }

    static fromList(json_items) {
        var items = [];
        if (json_items && json_items.length > 0) {
            for (var i=0; i<json_items.length; i++) {
                items.push(new Deeplink(json_items[i]));
            }
        }
        return items;
    }

}

class DeeplinkList {

    constructor(props) {
        this.category_verbose = props.category_verbose;
        this.documents = Deeplink.fromList(props.documents);
    }

    static fromList(json_items) {
        var items = [];
        if (json_items && json_items.length > 0) {
            for (var i=0; i<json_items.length; i++) {

                if (json_items[i].category !== "videos_2") {
                    continue;
                }

                items.push(new DeeplinkList(json_items[i]));
            }
        }
        return items;
    }
}

class EpisodeDetails {

    constructor(props) {
        this.episode = new Episode(props.episode);
        this.episode.show = new Show(props.show);
        this.episode.channel = new Channel(props.channel);

        this.deeplinks = DeeplinkList.fromList(props.deeplinks);
    }

}

var TV_PROVIDER_KEY = "tv_provider";
var PROVIDERS = [
    ["Airtel", "airtel-dth"],
    ["Den Digital Noida", "den-digital-noida"],
    ["Dish TV", "dish-tv-dth"],
    ["Sun Direct", "sun-direct-dth"],
    ["Tata Sky", "tata-sky"],
];

class TVProviderPreference {

    constructor(props) {

        this.title = props.title;
        this.slug = props.slug;

        this.icon_character = props.icon_character;
        this.isChecked = this.isChecked.bind(this);
    }

    static getProvider() {
        return localStorage.getItem(TV_PROVIDER_KEY) || "airtel-dth";
    }

    static getProviderVerbose() {
        var provider = TVProviderPreference.getProvider();
        for (var i=0; i<PROVIDERS.length; i++) {
            if (provider === PROVIDERS[i][1]) {
                return PROVIDERS[i][0];
            }
        }
        return provider;
    }

    static setProvider(provider) {
        localStorage.setItem(TV_PROVIDER_KEY, provider);
    }

    static getPreferenceList() {
        var items = [];
        for (var i=0; i<PROVIDERS.length; i++) {
            var props = {
                title: PROVIDERS[i][0],
                slug: PROVIDERS[i][1],
            }
            items.push(new TVProviderPreference(props));
        }
        return items;
    }

    isChecked() {
        return this.slug === TVProviderPreference.getProvider();
    }

    toggleChecked() {
        TVProviderPreference.setProvider(this.slug);
    }
}

var LANGUAGES_STORAGE_KEY = "languages"

class LanguagePreference {

    constructor(props) {

        this.title = props.title;

        this.storageSep = ',';

        this.icon_character = props.icon_character;
        this.getLanguages = this.getLanguages.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.toggleChecked = this.toggleChecked.bind(this);
    }

    getLanguages() {
        var languages = localStorage.getItem(LANGUAGES_STORAGE_KEY);
        if (languages) {
            return languages.split(this.storageSep);
        }
        return [];
    }

    static getLanguagesConfig() {
        return localStorage.getItem(LANGUAGES_STORAGE_KEY);
    }

    isChecked() {
        return this.getLanguages().indexOf(this.title) !== -1;
    }

    static addLanguages(new_languages){
        if (Array.isArray(new_languages)){
            localStorage.setItem(LANGUAGES_STORAGE_KEY, new_languages.join(this.storageSep))
        }
    }

    static removeLanguages(to_remove_languages){
        if (Array.isArray(to_remove_languages)) {
            var languages = localStorage.getItem(LANGUAGES_STORAGE_KEY);
            if(languages){
                languages = languages.split(",")
                languages = languages.filter(language => {
                    return !to_remove_languages.includes(language)
                })
                localStorage.setItem(LANGUAGES_STORAGE_KEY, languages.join(this.storageSep))
            }
        }
    }

    toggleChecked() {
        var languages = this.getLanguages();
        var matchPos = languages.indexOf(this.title);
        if (matchPos > -1) {
            languages.splice(matchPos, 1);
        } else {
            languages.push(this.title);
        }
        localStorage.setItem(LANGUAGES_STORAGE_KEY, languages.join(this.storageSep))
    }

    static getPreferenceList() {
        var items = [];
        for (var i=0; i<LANGUAGES.length; i++) {
            var props = {
                title: LANGUAGES[i],
                icon_character: "/static/images/languages/lang_" + LANGUAGES[i].toLocaleLowerCase() + ".png"
            }
            items.push(new LanguagePreference(props));
        }
        return items;
    }
}

class UniqueID {

    static generateUUID() { // Public Domain/MIT https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    static generateDeviceID() {
        var d = new Date().getTime();
        var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        var uuid = 'xxxxxxxx-xxxx-cdxxx-yxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;
            if(d > 0){
                var r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {
                var r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c=='x' ? r : (r&0x7|0x8)).toString(16);
        });
        return uuid;
    }

    static getDeviceID(){
        if ((typeof (window.localStorage.uniqueDeviceID) !== "undefined" &&
            window.localStorage.uniqueDeviceID !== null)){
                return window.localStorage.uniqueDeviceID
            }else{
                let device_id = this.generateDeviceID()
                window.localStorage.uniqueDeviceID = device_id;
                return device_id
            }
    }
}

class BubblePlotItem {
    constructor(props) {
        this._id = props._id;
        this.value = props.value;
        this.displayText = props.display_text;
        this.colorValue = props.color_value;
        this.selected = false;
    }
}

class BubblePlot {
    constructor(props) {
        this._id = props._id;
        this.children = props.items.map((element) => {
            return new BubblePlotItem(element)
        });
    }

    static fromList(json_items) {
        var items = [];
        if (json_items && json_items.length > 0) {
            for (var i=0; i<json_items.length; i++) {
                items.push(new Facet(json_items[i]));
            }
        }
        return items;
    }
}


class MediaRow {

    constructor(props) {
        this.title = props.title;
        this.contentType = props.content_type;
        this.displayConfig = props.display_config;
        this.mediaItems = props.media_items;
        this.mediaItemGroups = props.media_item_groups;
        this.mediaItemIcons = props.media_item_icons;
    }

    static fromOnAirItems(onAirItems) {
        var mediaRow = new MediaRow({title: "On Air"});
        mediaRow.displayConfig = {
            row_type: 1,
            width: 267,
            height: 150
        }

        var itemGroups = {};
        var itemPositions = {};

        var items = [];
        var seenItemIDs = [];

        var groupId = 0;
        var groups = [];

        for (var i=0; i<onAirItems.length; i++) {

            var item = onAirItems[i];
            groupId += 1;
            groups.push({
                "id": groupId,
                "title": item.displayable
            })

            for (var j=0; j<item.epg.episodes.length; j++) {
                var episode = item.epg.episodes[j];

                var episodeId = episode.id.toString();

                if (seenItemIDs.indexOf(episode.id) === -1) {
                    seenItemIDs.push(episode.id);

                    const start = moment(episode.starts_at);
                    const end = moment(episode.starts_at).add(episode.duration, 'minutes');
                    const now = moment();
                    const isPlayingNow = now.isAfter(start) && now.isBefore(end);

                    if (!isPlayingNow) {
                        continue;
                    }

                    items.push({
                      "item_type": "episode",
                      "item_id": episodeId,
                      "title": episode.show.title,
                      "subtitle": episode.channel.name,
                      "image": episode.image,
                      "video": null,
                      "aliases": null,
                      "actions": null,
                      "schedule": {
                        "channel_id": episode.channel.id,
                        "channel_title": episode.channel.name,
                        "start": episode.starts_at,
                        "duration": episode.duration
                      },
                      "package": null,
                      "group_ids": null,
                      "positions": null
                    });
                }

                if (itemGroups[episodeId] === undefined) {
                    itemGroups[episodeId] = [];
                }

                if (itemPositions[episodeId] === undefined) {
                    itemPositions[episodeId] = [];
                }

                itemGroups[episodeId].push(groupId);
                itemPositions[episodeId].push(j);

            }
        }

        for (var k=0; k<items.length; k++) {
            var itemId = items[k].item_id;
            items[k].group_ids = itemGroups[itemId];
            items[k].positions = itemPositions[itemId];
        }

        mediaRow.mediaItems = items;
        mediaRow.mediaItemGroups = groups;

        return mediaRow;
    }
}


class MediaDetail {
    constructor(props) {
        this.mediaHeader = props.media_header;
        this.mediaRows = (props.media_rows||[]).map((element) => {
            return new MediaRow(element)
        });

        if (this.mediaHeader && this.mediaHeader.bubble_plot) {
          this.mediaHeader.bubblePlot = new BubblePlot(this.mediaHeader.bubble_plot);
          this.mediaHeader.bubble_plot = null;
        }
    }
}

class ChatMessage {

    constructor(props) {
        this.id = props.id;
        this.message = props.message;
        this.response = props.response;
        this.action_type = props.action_type;
        this.action_id = props.action_id;
        this.action_title = props.action_title;
        this.action_meta = props.action_meta;
    }

    hasEPG() {
        return this.action_meta && this.action_meta.epg;
    }

    hasDetail() {
        return this.action_meta && this.action_meta.detail;
    }

    shouldSwitch() {
        return this.action_id != null;
    }

    isActionOnTV() {
        return this.action_meta && this.action_meta.device && this.action_meta.device === "tv";
    }

    needsVibrate() {
        if (this.action_meta || this.action_meta.vibrate) {
            return true;
        } else {
            return this.action_meta.vibrate;
        }
    }

    isActionOnSTB() {
        return this.action_meta && this.action_meta.device && this.action_meta.device === "stb";
    }

    isActionOnAC() {
        return this.action_meta && this.action_meta.device && this.action_meta.device === "ac";
    }

    getSwitchChannelId() {
        return parseInt(this.action_id)
    }

    getSwitchChannelName() {
        if (this.action_title) {
            return this.action_title;
        }
    }
}

class UserInterestsManager{
    constructor(userProfile){
        this.favorites = userProfile.favorites || []
        this.seen = userProfile.seen || []
        this.watchlist = userProfile.watchlist || []
        this.notInterestedList = userProfile.not_interested_list || []
    }

    static async getUserProfile(){
        let backend = Backend.get()
        try {
            let res = await backend.getJSONResultAsync("api/v2/user/user_interests")
            if (res.results) {
                console.log("user_interests", res)
            }
            return res.results
        } catch (err) {
            console.log(err, err.response)
            return {}
        }
    }

    checkIfPresentOnProfile(item_type, item_id, category_values){
        for (const item of category_values) {
            if (item.item_type === item_type && item.item_ids.includes(item_id)) {
                return true
            }
        }
        return false
    }

    isFavorite(item_type, item_id){
        return this.checkIfPresentOnProfile(item_type, item_id, this.favorites)
    }

    isSeen(item_type, item_id) {
        return this.checkIfPresentOnProfile(item_type, item_id, this.seen)
    }

    isInWatchList(item_type, item_id){
        return this.checkIfPresentOnProfile(item_type, item_id, this.watchlist)
    }

    isInNotInterestedList(item_type, item_id){
        return this.checkIfPresentOnProfile(item_type, item_id, this.notInterestedList)
    }

}



export {
    EPG,
    Facet,
    Channel,
    MediaRow,
    UniqueID,
    OnAirItem,
    MediaDetail,
    ChatMessage,
    ChannelGroup,
    EpisodeDetails,
    LanguagePreference,
    UserInterestsManager,
    TVProviderPreference,
}