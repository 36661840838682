
var NOT_IMPLEMENTED = "Warning: Not implemented";
// import Logger from './Logger'

export default class BaseEnv {

  constructor(props) {
    // Logger.log("BaseEnv called");

    this.isPip = false;
    this.isOverlay = false;
    this.isYoutube = false;
    this.referrerURL = ["/on_air"];
  }

  isBackgroundPosterEnabled() {
    return false;
  }

  setPipFlag() {
    this.isPip = true;
  }

  unsetPipFlag() {
    this.isPip = false;
  }

  setOverlayFlag() {
    this.isOverlay = true;
  }

  unsetOverlayFlag() {
    this.isOverlay = false;
  }

  setYoutubeFlag() {
    this.isYoutube = true;
  }

  unsetYoutubeFlag() {
    this.isYoutube = false;
  }

  switchChannel(channelId) {
    throw new Error(NOT_IMPLEMENTED);
  }

  showPip() {
    throw new Error(NOT_IMPLEMENTED);
  }

  hidePip() {
    throw new Error(NOT_IMPLEMENTED);
  }

  hideOverlay() {
    throw new Error(NOT_IMPLEMENTED);
  }

  getTVInputs() {
    throw new Error(NOT_IMPLEMENTED);
  }

  isHybridMode() {
    return true;
  }

  isAlexaEnabled() {
    return true
  }

  isLanguageSelectionEnabled() {
    return true
  }

  getProductID() {
    return ""
  }

  getProductName() {
    return "Sensy TV"
  }

  getBannerZoneID() {
    return 18
  }

  isYoutubeTrailersEnabled() {
    return true
  }

  isEpisodesEnabledForOTT() {
    return false
  }
}