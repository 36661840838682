import React, { Component } from 'react';
import Logger from './Logger'
import CenterCropImage from './CenterCropImage';
import CardMeta from './CardMeta';
import Backend from './backend'

import { truncate } from 'underscore.string';

export default class BannerCard extends Component {

  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isFocused) {
      // props.backend.bannerObservable.notify(props.promotion);
      this.props.onBannerChanged(this.props.promotion)
    }
  }

  onClick(event) {
  }

  render() {
    let { promotion, link } = this.props

    var title = "";
    var subTitle = "";


    return (
      <div className={"card bannerCard NavCard " + (this.props.isFocused ? "cardFocus" : "")}>
        {link}
      </div>
    )
  }
}