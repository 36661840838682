import React, { Component } from 'react'
import { Pannellum } from "pannellum-react";
import { toast } from 'react-toastify'
import { VannilaAlert } from "../Alerts/alertTemplates";

import { goFullScreen, KEYCODES } from '../utils';
import { DPadNav } from '../dpadnav';
import Backend from '../backend';

const VIEW_KEY = "image360";


export default class Image360View extends Component {
    constructor(props) {
        super(props)
        this.backend = Backend.get()
        this.nav = DPadNav.get()
        this.pannellumRef = React.createRef()
        this.image360Ref = React.createRef()
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    async componentDidMount() {

        document.addEventListener("keydown", this.handleKeyPress, false);

        if (this.image360Ref?.current) {
            // console.log(this.pannellumRef.current, this.pannellumRef.current.toggleFullscreen )
            // this.pannellumRef.current.toggleFullscreen()
            goFullScreen(this.image360Ref.current)
        } else {
            console.error("something went wrong - couldn't go fullscreen")
        }

        this.nav.pushToNavStateStack({ //doesn't matter
            "row": 1,
            "cols": {}
        })
        this.nav.bind(document);

        this.nav.onNavStateChanged = (navState) => {
            // this.setState({
            //     navState: navState
            // }); // doesn't matter
            this.backend.setNavState(VIEW_KEY, this.nav);
        }
        this.nav.onKeyDown = (event) => {
            return true
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
        this.nav.popFromNavStateStack()
    }

    handleKeyPress(event) {
        event.stopPropagation();
        event.preventDefault()
        console.log("handling keypress in 360View")
        return true
    }


    render() {

        return (
            <div ref={this.image360Ref}>
                <Pannellum
                    ref={this.pannellumRef}
                    width="100%"
                    height="100%"
                    image={this.props.image360}
                    pitch={10}
                    yaw={180}
                    hfov={110}
                    autoLoad
                    showZoomCtrl={false}
                    showFullscreenCtrl={false}
                    onLoad={() => {
                        console.log("panorama loaded");
                        this.image360Ref.current.querySelector('.pnlm-container').focus()
                    }}
                >
                    {/* <Pannellum.Hotspot
                        type="custom"
                        pitch={31}
                        yaw={150}
                        handleClick={(evt, name) => console.log(name)}
                        name="hs1"
                    /> */}
                </Pannellum>
            </div>
        )
    }
}
