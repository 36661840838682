import React, { Component, useState } from 'react';

import Backend from '../backend'
import { toast, Flip } from "react-toastify";

import NavHelper from '../NavHelper'

import { DPadNav, KEYCODE_W, KEYCODE_A, KEYCODE_S, KEYCODE_D, KEYCODE_PLUS, KEYCODE_MINUS, KEYCODE_LEFT, KEYCODE_RIGHT, KEYCODE_UP, KEYCODE_DOWN, KEYCODE_ENTER } from '../dpadnav'

import _ from 'underscore';
import loaderSVG from '../../includes/images/loader.svg'
import audioSVG from '../../includes/images/icon_audio.svg'
import audioMutedSVG from '../../includes/images/icon_audio_muted.svg'
import menuIcon from '../../includes/images/icon_menu.svg'
import clipboardIcon from '../../includes/images/icon_clipboard.svg'
import codeIcon from '../../includes/images/icon_code.svg'
import whatsappIcon from '../../includes/images/icon_whatsapp.svg'
import logo from '../../includes/images/we-are-live.svg'
import shareIcon from '../../includes/images/icon_share.svg'
import playIcon from '../../includes/images/icon_play.svg'
import VideoPlayer from './CameraDevicesPlayer';
import OnScreenCameraController from './OnScreenCameraController.js'
import { Helmet } from 'react-helmet';
import CameraDeviceCard from './CameraDeviceCard';
import { ViewerCountGlass } from './CameraDeviceCard';
import { truncate } from 'underscore.string';
import { Modal } from 'react-bootstrap';
import { IconButton, PrimaryButton } from './Buttons';
import { Link } from 'react-router-dom';
import { thresholdFreedmanDiaconis } from 'd3';



const CAMERA_DEVICES_ENDPOINT = "api/v4/user/camera_device/list?published=true"
const CAMERA_DEVICES_STATUS_ENDPOINT = "api/v4/user/camera_device/status?stream_ids="
const DEFAULT_IMG = "http://sensara-static-files.sensara.co/camera_devices/reel.jpg"
const VIEW_KEY = "camera_devices";
const CAMERA_DEVICES_ROW = 0;
const CARD_COUNT_IN_ROW = 4;
const DEFAULT_IS_MUTED_STATE = true;
const TOPICS = ['Outdoor', "Bakery", "Saree Store", "Museum"]

var isHybridMode = false;

var STREAM_QUERY_PARAM = 'id'

function EmbedPopUp({ show, onHide, onCopyButtonClicked, embed_stream_url, embed_stream_id }) {
    function getSnippet() {
        return `<iframe width="769" height="432" src="${embed_stream_url}" frameborder="0" allow=" autoplay; clipboard-write;" allowfullscreen/>`
    }
    const [snippet, setSnippet] = useState(getSnippet())
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            aria-labelledby="contained-modal-title-sm"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-sm" style={{ color: "black" }}>Embed Stream</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="center row">
                    {/* <div className='p-relative col-md-7'>
                        <img src="" alt="" className='embedded-stream-image' />
                        <img src="" alt="" className='embedded-stream-image' />
                    </div> */}
                    {/* <div className="col-md-5 embed-stream-snippet-container"> */}
                    <div className="col-md-12 embed-stream-snippet-container">
                        <textarea className='embed-stream-snippet' name="embed-snippet" id="" cols="30" rows="5" value={snippet} onChange={(event) => setSnippet(event.target.value)}></textarea>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className='d-flex align-items-center justify-content-between'>
                <h5><Link to={`/embed/sample?id=${embed_stream_id}`} target='_blank' className='link'>See it in action here</Link></h5>
                <PrimaryButton onClick={(event) => onCopyButtonClicked(event, snippet)}>Copy</PrimaryButton>
            </Modal.Footer>
        </Modal>
    )
}


export default class CameraDevicesView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEmbedView: this.props.location.pathname.startsWith('/embed'),
            showEmbedPopUp: false,
            isLoading: true,
            rowsShown: true,
            showMenu: false,
            navState: {
                row: CAMERA_DEVICES_ROW - 1,
                cols: {}
            },
            focusCard: null,
            selected_camera_device_id: null,
            isMuted: DEFAULT_IS_MUTED_STATE,
            isPlaying: true,
            camera_device_lookup: new Map(),
            ...this.getInitState(props)
        }
        this.getInitState = this.getInitState.bind(this)
        this.backend = null;
        this.fetchData = this.fetchData.bind(this);
        this.fetchDeviceStatus = this.fetchDeviceStatus.bind(this);
        this.onCameraDeviceClick = this.onCameraDeviceClick.bind(this);
        this.onCameraControlPress = this.onCameraControlPress.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.pushUnauthorizedChatMessage = this.pushUnauthorizedChatMessage.bind(this)
        this.nav = DPadNav.get();
        this.toggleAudio = this.toggleAudio.bind(this)
        this.forceUnmuteOnMount = this.forceUnmuteOnMount.bind(this)
        this.iframeRef = React.createRef()
        // this.handleClick = this.handleClick.bind(this)
        this.handleMouseMove = this.handleMouseMove.bind(this)
        this.setMenuTimer = this.setMenuTimer.bind(this)
        this.copyCurrentURLToClipboard = this.copyCurrentURLToClipboard.bind(this)
        this.copyEmbedSnippetToClipboard = this.copyEmbedSnippetToClipboard.bind(this)
        this.shareToWhatsapp = this.shareToWhatsapp.bind(this)
        this.messageOnWhatsapp = this.messageOnWhatsapp.bind(this)
        this.attachAutoPlayEventListeners = this.attachAutoPlayEventListeners.bind(this)
        this.resetViewPortHeight = this.resetViewPortHeight.bind(this)

        this.playAudio = this.playAudio.bind(this)
        this.pauseAudio = this.pauseAudio.bind(this)
        this.playVideo = this.playVideo.bind(this)
        this.pauseVideo = this.pauseVideo.bind(this)
        this.menuTimeout = null;
        this.pendingUnmute = null;
    }

    getInitState(props) {
        console.log("props", props);
        let search_params = new URLSearchParams(this.props.location.search)
        if (search_params.has(STREAM_QUERY_PARAM)) {
            return {
                selected_camera_device_id: parseInt(search_params.get(STREAM_QUERY_PARAM)),
                rowsShown: false,
                isMuted: DEFAULT_IS_MUTED_STATE
            }
        }
        return {}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                ...this.getInitState(this.props)
            }, () => {
                this.pendingUnmute = true
                this.resetViewPortHeight()
                if (DEFAULT_IS_MUTED_STATE && this.state.isMuted === false) {
                    this.playAudio();
                }
            })
        }
    }

    componentDidMount() {
        let that = this;
        setTimeout(function () {
            that.backend = Backend.get();
            that.fetchData();
        }, 100);

        this.nav.bind(document);
        this.nav.setNavState(this.state.navState);
        this.nav.setChangeRowOnEndReached(true);
        this.nav.onOKPressed = () => {

            if (!this.state.rowsShown) {
                this.setState({
                    rowsShown: true
                })
                return
            }

            let currRow = this.state.navState.row
            let currentColValue = this.state.navState.cols[currRow];
            if (this.state.camera_options?.length >= currentColValue) {
                let index = (currRow * CARD_COUNT_IN_ROW) + currentColValue
                this.onCameraDeviceClick(this.state.camera_options[index])
            }
        };
        this.nav.onBackPressed = () => {
            this.setState({
                rowsShown: !this.state.rowsShown
            })
            return true;
        };

        this.nav.onNavStateChanged = function (navState) {
            console.log("new navstate", navState.row, navState.cols[navState.row])
            that.setState({
                navState: navState
            });
            that.backend.setNavState(VIEW_KEY, that.nav);
        }
        setInterval(this.fetchDeviceStatus, 10000)

        document.addEventListener('keydown', this.handleKeyPress, true);
        // document.addEventListener('click', this.handleClick)
        document.addEventListener('mousemove', this.handleMouseMove)
        window.addEventListener("resize", this.resetViewPortHeight);
        this.resetViewPortHeight()
        this.attachAutoPlayEventListeners()
    }

    resetViewPortHeight() {
        if (window.matchMedia("(max-width:768px)").matches && !this.state.rowsShown) {
            document.querySelector('.camera-feeds-menu').style.height = window.innerHeight + "px";
        } else {
            document.querySelector('.camera-feeds-menu').style.height = null
        }
    }

    attachAutoPlayEventListeners() {
        //meaning they have visited a specific stream
        if (!this.state.rowsShown) {
            this.pendingUnmute = true
        }
        // this.pauseVideo(); //onmount if the there is an iframe -> paused -> play button will act as an interaction
        window.onmessage = (e) => {
            console.log(`message received`, e.data);
            if (e.data === 'video-paused') {
                this.hideVideoControls()
                this.setState({
                    isPlaying: false
                })
            } else if (e.data === 'video-played') {
                if (this.pendingUnmute) {
                    this.playAudio()
                    this.pendingUnmute = null
                }
                this.hideVideoControls()
                this.setState({
                    isPlaying: true
                })
            }
        }
    }



    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress, true);
        // document.removeEventListener('click', this.handleClick)
        document.removeEventListener('mousemove', this.handleMouseMove)

    }
    handleMouseMove(event) {
        if (!this.state.rowsShown && this.state.showMenu === false) {
            this.setMenuTimer()
        }
    }

    setMenuTimer() {
        this.setState({
            showMenu: true
        }, () => {
            clearTimeout(this.menuTimeout);
            this.menuTimeout = setTimeout(() => {
                this.setState({
                    showMenu: false,
                })
            }, 3000)
        })
    }

    // handleClick(event) {
    //     console.log("handling click", event, event.defaultPrevented, this.state.rowsShown)
    //     if (!event.defaultPrevented && !this.state.rowsShown && !this.state.isEmbedView) {
    //         console.log(this.state.showMenu, this.state.rowsShown)
    //         if (!this.state.showMenu) {
    //             this.handleMouseMove()
    //             return;
    //         }
    //         // this.setState({
    //         //     rowsShown: true
    //         // })
    //     }
    // }

    pushUnauthorizedChatMessage(action_type, action_id) {
        let selected_camera_device = this.state.camera_device_lookup.get(this.state.selected_camera_device_id)
        if (!selected_camera_device) return;
        this.backend.sendPushMessageWithoutAuth({
            "user_id": selected_camera_device.user,
            "action_type": action_type,
            "action_id": action_id,
        }, function (err, resp) {
            if (typeof err !== 'undefined' && err !== null) {
                console.log("ERROR!")
            } else {
                console.log("Success!");
            }
        })
    }

    onCameraDeviceClick(camera_device, navState = this.state.navState) {
        console.log("onCameraDeviceClick", camera_device.user)

        let search_params = new URLSearchParams(this.props.location.search)
        search_params.set(STREAM_QUERY_PARAM, camera_device.user)
        this.setMenuTimer()
        if (this.state.selected_camera_device_id === camera_device.user
            && this.props.location.search.slice(1,) === search_params.toString()) {
            this.setState({
                rowsShown: false,
                navState,
                isMuted: DEFAULT_IS_MUTED_STATE
            }, () => {
                this.pendingUnmute = true
                this.resetViewPortHeight()
            })
            return;
        }
        this.props.history.push({
            ...this.props.location,
            search: search_params.toString(),
        })

    }

    // Called every 10s
    fetchDeviceStatus() {

        if (!this.state.camera_options) {
            return;
        }

        let streamIDs = ""
        this.state.camera_options.forEach(co => {
            streamIDs += co.user
            streamIDs += ","
        })
        let statusEndpoint = CAMERA_DEVICES_STATUS_ENDPOINT + streamIDs
        this.backend.getJSONResultWithoutAuth(statusEndpoint, (error, response) => {
            if (error) {
                return;
            }
            //Update the .live value for each item in camera_options
            let deviceStatuses = response
            let statusLookup = {}
            let viewerCountLookup = {}
            let statuses = deviceStatuses.forEach(d => {
                statusLookup[d.stream_id] = d.live
                viewerCountLookup[d.stream_id] = d.viewer_count
            })

            let camera_options = [...this.state.camera_options]
            camera_options.forEach(co => {
                co.live = statusLookup[co.user]
                co.viewer_count = viewerCountLookup[co.user]
            })
            this.setState({ camera_options })
        });
    }

    onCameraControlPress(keyCode) {
        console.log("onCameraControlPress", keyCode);

        var action_type = "";
        var action_id = "";
        if (keyCode === KEYCODE_PLUS) {
            action_type = "CAMERA_ZOOM";
            action_id = "in";
        } else if (keyCode === KEYCODE_MINUS) {
            action_type = "CAMERA_ZOOM";
            action_id = "out";
        } else if (keyCode === KEYCODE_D || keyCode === KEYCODE_RIGHT) {
            action_type = "PAN_CAMERA";
            action_id = "10";
        } else if (keyCode === KEYCODE_A || keyCode === KEYCODE_LEFT) {
            action_type = "PAN_CAMERA";
            action_id = "-10";
        } else if (keyCode === KEYCODE_W || keyCode === KEYCODE_UP) {
            action_type = "TILT_CAMERA";
            action_id = "10";
        } else if (keyCode === KEYCODE_S || keyCode === KEYCODE_DOWN) {
            action_type = "TILT_CAMERA";
            action_id = "-10";
        }
        this.pushUnauthorizedChatMessage(action_type, action_id);

    }

    handleKeyPress(event) {
        if (!this.state.rowsShown
            && [KEYCODE_W, KEYCODE_A, KEYCODE_S, KEYCODE_D, KEYCODE_PLUS, KEYCODE_MINUS, KEYCODE_UP, KEYCODE_DOWN, KEYCODE_RIGHT, KEYCODE_LEFT].includes(event.keyCode)
            && !event.repeat) {
            event.preventDefault()
            event.stopPropagation()
            this.onCameraControlPress(event.keyCode)
        }
        // let selected_camera_device_id = this.state.selected_camera_device_id
        // let camera_options = this.state.camera_device_lookup || {}
        // let current_index = camera_options.indexOf(camera)
        // if (!selected_camera_device_id) {
        //     selected_camera_device_id = 
        // }
        // switch(event.keyCode){

        //     case KEYCODE_LEFT: 
        //     case KEYCODE_RIGHT:
        //     case KEYCODE_UP:
        //     case KEYCODE_DOWN:
        //     case KEYCODE_ENTER:

        // }
    }


    fetchData() {
        this.setState({
            "isLoading": true,
        })

        this.backend.getJSONResultWithoutAuth(CAMERA_DEVICES_ENDPOINT, (error, response) => {
            if (error) {
                return;
            }

            let camera_options = _.pairs(response).map((kv) => {
                kv[1]['title'] = kv[1]['name'];
                kv[1]['subtitle'] = kv[1]['address_city'] + ", " + kv[1]['address_state'];
                if (kv[1]['image'] == null) {
                    kv[1]['image'] = DEFAULT_IMG;
                }
                return kv[1]
            })

            let live_with_image = []
            let live_without_image = []
            let others_with_image = []
            let others_without_image = []

            camera_options.forEach(co => {
                if (co.live) {
                    if (co.image === DEFAULT_IMG) {
                        live_without_image.push(co)
                    } else {
                        live_with_image.push(co)
                    }
                } else {
                    if (co.image === DEFAULT_IMG) {
                        others_without_image.push(co)
                    } else {
                        others_with_image.push(co)
                    }
                }
            })

            camera_options = [...live_with_image, ...live_without_image, ...others_with_image, ...others_without_image];

            let selected_camera_device_id = this.state.selected_camera_device_id || camera_options[0].user
            let navState = { ...this.state.navState }
            camera_options.forEach((co, index) => {
                // console.log(co, index);
                if (selected_camera_device_id === co.user) {
                    console.log("Setting selected col", selected_camera_device_id, co.user, index);
                    navState.cols[CAMERA_DEVICES_ROW] = index
                }
            })
            let camera_device_lookup = new Map();
            camera_options.forEach(c => {
                camera_device_lookup.set(c.user, c)
            });
            this.setState({
                isLoading: false,
                camera_options,
                selected_camera_device_id,
                camera_device_lookup,
                navState
            }, () => {
                this.nav.setNavState(this.state.navState);
            })
            console.log("camera_options", camera_options);
        });
    }

    forceUnmuteOnMount() {
        if (this.state.isMuted) {
            this.playAudio();
        }
        this.setState({
            isMuted: !this.state.isMuted
        })
    }

    toggleAudio(event) {
        console.log("toggling")
        if (this.state.isMuted) {
            this.playAudio();
        } else {
            this.pauseAudio();
        }

        event.preventDefault()
    }

    playAudio() {
        let iframe = this.iframeRef.current
        if (!iframe) return;
        iframe.contentWindow.postMessage('play-audio', '*');
        this.setState({
            isMuted: false
        })
    }

    pauseAudio() {
        let iframe = this.iframeRef.current
        if (!iframe) return;
        iframe.contentWindow.postMessage('pause-audio', '*');
        this.setState({
            isMuted: true
        })
    }

    pauseVideo() {
        let iframe = this.iframeRef.current
        if (!iframe) return;
        iframe.contentWindow.postMessage('pause-video', '*');
        this.setState({
            isPlaying: false
        })
    }

    playVideo() {
        let iframe = this.iframeRef.current
        if (!iframe) return;
        iframe.contentWindow.postMessage('play-video', '*');
        this.setState({
            isPlaying: true,
        })
    }

    hideVideoControls(){
        let iframe = this.iframeRef.current
        if (!iframe) return;
        iframe.contentWindow.postMessage('hide-controls', '*');
    }

    copyCurrentURLToClipboard(event) {
        this.copyToClipboard(window.location.toString())
        toast("URL copied to clipboard!", { className: 'green-toast', autoClose: 1500, position: "bottom-center", hideProgressBar: true, closeButton: false, transition: Flip })
        event.preventDefault()
    }

    copyEmbedSnippetToClipboard(event, embed_snippet) {
        this.copyToClipboard(embed_snippet)
        toast("Snippet copied to clipboard!", { className: 'green-toast', autoClose: 1500, position: "bottom-center", hideProgressBar: true, closeButton: false, transition: Flip })
        event.preventDefault()
    }

    copyToClipboard(copy_text) {
        try {
            var dummy = document.createElement("textarea");
            // to avoid breaking orgain page when copying more words
            // cant copy when adding below this code
            // dummy.style.display = 'none'
            document.body.appendChild(dummy);
            //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
            dummy.value = copy_text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            let successful = document.execCommand('copy');
            if (!successful) {
                throw new Error("Unable to copy to clipboard");
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
    }

    shareToWhatsapp() {
        var whatsapp_url = "https://api.whatsapp.com/send?text=" + window.location.toString()
        window.open(whatsapp_url, 'mywindow');
    }

    messageOnWhatsapp() {
        var phone_number = this.state.camera_device_lookup.get(this.state.selected_camera_device_id).phone_number
        phone_number = phone_number.replace(/[\s()\-\+]/g, "")
        var whatsapp_url = "https://wa.me/" + phone_number
        window.open(whatsapp_url, 'mywindow');
    }

    getEmbedSnippet(selected_camera_device_id) {
        let url = new URL(window.location.toString().replace('/streams', '/embed/streams'));
        url.searchParams.set('id', selected_camera_device_id)
        return url.toString()
    }

    render() {

        let helmet = <Helmet>
            <title>WeAreLive</title>
            <body className="with-bootstrap" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>

        if (this.state.isEmbedView && (this.state.rowsShown || !this.state.selected_camera_device_id)) {
            return <div className='h-100 text-center center flex-column' style={{ transform: "translateY(-20%)", color: "white" }}>
                {helmet}
                <h3>Something wrong 🤔</h3>
                Please make sure you specify the stream id when trying to embed
                </div>
        }

        var overlayRowBound = 2;
        if (isHybridMode) {
            overlayRowBound = 3;
        }
        let camera_options = [...(this.state.camera_options || [])]

        let counter = 0;
        var camera_devices = [];
        let render_items = [];
        let row_count = 0
        for (let i = 0; i < camera_options.length; i += CARD_COUNT_IN_ROW) {
            let cds = camera_options.slice(i, Math.min(i + CARD_COUNT_IN_ROW))
            render_items.push(<div className={'row NavRow mb-4' + (this.nav.isFocusedRow(row_count) ? " activeRow" : " inactiveRow")} key={`row${i}`}>
                {cds.map((cd, idx) => <div className='col-md-3 mx-auto text-center m-mb-3' key={cd.id}>
                    <CameraDeviceCard
                        key={cd.id}
                        camera_device={cd}
                        isFocused={this.nav.isFocusedRow(row_count) && this.nav.getColPos(row_count) === idx % CARD_COUNT_IN_ROW}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            let navState = { ...this.state.navState }
                            navState.cols[CAMERA_DEVICES_ROW] = i
                            this.onCameraDeviceClick(cd, navState)
                        }} />
                </div>)}
            </div>)
            row_count++;
        }

        var translateStyleY = {
            "transform": "translateY(" + this.nav.getTranslateChannelsY(true) + "px)"
        }
        let selected_camera_device = this.state.camera_device_lookup.get(this.state.selected_camera_device_id)
        console.log("selected camera device", selected_camera_device)
        let phone_number = selected_camera_device?.phone_number
        let has_phone_number = false
        if (phone_number && phone_number !== '') {
            has_phone_number = true
        }
        let viewer_count = selected_camera_device?.viewer_count || 0
        var rtspURL = `https://demostream.sweb1.com:5443/LiveApp/play.html?name=${selected_camera_device?.user}`;
        // console.log("items", render_items, this.state.navState.row, this.state.navState.cols[this.state.navState.row]);

        return (
            <div className='app camera-devices-view'>
                {helmet}
                {/* <VideoPlayer key={selected_camera_device.user} stream_name={selected_camera_device?.user?.toString()} /> */}
                {!this.state.rowsShown &&
                    <iframe ref={this.iframeRef} className="backgroundIframe" src={rtspURL} style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, width: "100%", height: "100%" }} allow="autoplay" />
                }
                {/* !this.nav.isFocusedRowBelow(overlayRowBound) && */}
                <div className="FullOpaqueOverlay" style={{ backgroundColor: this.state.rowsShown ? "black" : "transparent" }}></div>

                {
                    !this.state.rowsShown && this.state.showMenu &&
                    <div className='on-screen-camera-controller'>
                        <OnScreenCameraController onCameraControlPress={this.onCameraControlPress} />
                    </div>
                }
                {/* show menu all the time - quickfix */}
                {!this.state.isPlaying && <div className="camera-feeds-play-overlay" onClick={this.playVideo}>
                    <div className="translucent-overlay" />
                    <img src={playIcon} alt="" className='play-icon' />
                </div>}
                <div className={"camera-feeds-menu" + (this.state.rowsShown || this.state.showMenu || true ? " show" : "")} data-vertical={!this.state.rowsShown}>
                    <div className='center stream-details-container'>
                        {!this.state.isEmbedView && <img src={menuIcon} alt="" className='menu-icon' onClick={() => {
                            if (!this.state.rowsShown) {
                                this.setState({
                                    rowsShown: true
                                }, () => {
                                    this.resetViewPortHeight()
                                })
                            }
                        }} />}
                        <img src={logo} alt="" className='we-are-live-logo' />
                        {!this.state.rowsShown && selected_camera_device && <div className='d-inline-block px-2'><div className='camera-device-meta p-2'>
                            <div>
                                <div className='camera-device-meta-heading'>{truncate(selected_camera_device.title, 16)}</div>
                                <div className='camera-device-meta-sub-heading'>{selected_camera_device.address_city && selected_camera_device.address_city}{selected_camera_device.address_city && `, ${selected_camera_device.address_city}`}</div>
                            </div>
                        </div></div>}
                    </div>
                    <div className="stream-control-container" data-rowsshown={this.state.rowsShown} style={{ whiteSpace: "nowrap" }}>
                        {
                            selected_camera_device?.phone_number &&
                            <IconButton
                                className="stream-control mr-3 whatsapp-icon"
                                onClick={this.messageOnWhatsapp}
                                icon={whatsappIcon}
                            >
                                Contact
                                </IconButton>
                        }
                        {!this.state.isEmbedView && <>
                            {this.state.selected_camera_device_id && <div className="stream-control code-icon mr-3" onClick={() => this.setState({ showEmbedPopUp: true })} title='Embed'>
                                <img src={codeIcon} alt="Embed" />
                            </div>}
                        </>}
                        <div className="stream-control mr-3" onClick={this.copyCurrentURLToClipboard} title="Copy URL to clipboard">
                            <img src={clipboardIcon} alt="Copy URL to clipboard" />
                        </div>
                        <div className="stream-control mr-3" onClick={this.shareToWhatsapp} title="Share on WhatsApp">
                            <img src={shareIcon} alt="Share on WhatsApp" />
                        </div>
                        <div className="stream-control m-col-md-4" onClick={this.toggleAudio}>
                            {!this.state.isMuted && <img src={audioSVG} alt="" />}
                            {this.state.isMuted && <img src={audioMutedSVG} alt="" />}
                        </div>

                    </div>
                </div>

                {!this.state.rowsShown && selected_camera_device?.live && <div className='viewer-count-for-set-stream' data-haswhatsappicon={has_phone_number}>
                    <ViewerCountGlass viewer_count={viewer_count} />
                </div>}

                {/* {!this.state.isLoading && <div style={{ height: "50vh" }}></div>} */}
                {
                    this.state.isLoading &&
                    <img className="loader" alt="Loading" src={loaderSVG} />
                }

                <div className="transitionAll content-padding mt-3" style={{ ...translateStyleY }}>
                    {
                        this.state.rowsShown && <>
                            <div className="center flex-column m-mb-3">
                                <p> To get started, pick a live stream</p>
                                {/*
                                <div className="topics-container">
                                    {TOPICS.map(t => <div className='MediaItemGroup'>{t}</div>)}
                                </div>
                                */}
                            </div>
                            <div className="container list-container" >
                                {/* style={this.nav.getTranslateXStyle(counter)} */}
                                {render_items}
                            </div>
                            <div className="clearBoth" />
                        </>
                    }

                </div>
                {this.state.showEmbedPopUp && <EmbedPopUp embed_stream_id={this.state.selected_camera_device_id} embed_stream_url={this.getEmbedSnippet(this.state.selected_camera_device_id)} show={this.state.showEmbedPopUp} onHide={() => {
                    this.setState({
                        showEmbedPopUp: false
                    })
                }} onCopyButtonClicked={this.copyEmbedSnippetToClipboard} />}
                <NavHelper nav={this.nav} />
            </div>
        );
    }
}