import React, { Component } from 'react';

import Backend from './backend'
import SearchBar from './SearchBar';

import SubNavOptionCard from './SubNavOptionCard'
import ChannelGroupView from './ChannelGroupView'
import NavHelper from './NavHelper'

import { DPadNav } from './dpadnav'

var ROW_SEARCH = 0;
var ROW_LANGUAGES = 1;
var VIEW_KEY = "channels";

export default class ChannelsView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            "isLoading": false,
            "selected_language": null,
            "isSearchActive": false,
            "navState": {
              "row": 1,
              "cols": {}
            }
        }

        this.backend = Backend.get();
        this.fetchData = this.fetchData.bind(this);
        this.onSubNavClick = this.onSubNavClick.bind(this);
        this.onSearchStateChanged = this.onSearchStateChanged.bind(this);

        this.nav = this.backend.getNavState(VIEW_KEY) || new DPadNav(this.state.navState);
    }

    componentDidMount() {

        Backend.getEnv().hidePip();

        this.fetchData();
        this.nav.bind(document);

        var that = this;
        this.nav.onNavStateChanged = function(navState) {

          if (navState.row === ROW_LANGUAGES) {
            var language = that.state.indexed_groups._keys[that.nav.getColPos(ROW_LANGUAGES)];

            if (that.state.selected_language !== language) {
              that.nav.clearColPosAfter(1);
            }

            that.setState({
              "selected_language": language
            });
          }

          that.setState({
            navState: navState
          },()=>{
            that.nav.scrollContent()
            that.backend.setNavState(VIEW_KEY, that.nav);
          });
        }
    }

    fetchData() {
        var that = this;
        this.setState({
            "isLoading": true,
        })
        this.backend.getChannelGroups(function(channel_groups, indexed_groups) {

            var selected_language = that.state.selected_language;
            if (!selected_language && indexed_groups._keys.length > 0) {
              selected_language = indexed_groups._keys[0];
            }

            that.setState({
                "indexed_groups": indexed_groups,
                "selected_language": selected_language,
                "isLoading": false

            });

        });
    }

    onSearchStateChanged(state) {
        this.setState({
            "isSearchActive": state
        })
    }

    onSubNavClick(title) {
      this.setState({
        "selected_language": title
      })
    }

    render() {

      var indexed_groups = this.state.indexed_groups;
      var selected_language = this.state.selected_language;

      var channel_group_option_cards = [];
      var selected_channel_groups = [];

      var counter = 0;
      var searchBar = <SearchBar nav={this.nav} onStateChanged={this.onSearchStateChanged}
          isFocused={this.nav.isFocusedRow(ROW_SEARCH)}
          focusedCol={this.nav.getColPos(counter)} />;
      counter++

      if (!this.state.isSearchActive && indexed_groups) {

        channel_group_option_cards = []
        for (var i=0; i<this.state.indexed_groups._keys.length; i++) {
          var option = this.state.indexed_groups._keys[i];
          channel_group_option_cards.push(<SubNavOptionCard 
              isSelected={indexed_groups._keys[i]===this.state.selected_language}
              isFocused={this.nav.isFocused(counter, i, indexed_groups._keys.length)}
              key={option} title={option} onClick={this.onSubNavClick} />);
        }

        if (this.state.indexed_groups._keys.length > 0) {
          counter++;
        }

        for (i=0; i<indexed_groups[selected_language].length; i++) {
            var channel_group = indexed_groups[selected_language][i];
            selected_channel_groups.push(<ChannelGroupView nav={this.nav}
              row={counter} isFocusedRow={this.nav.isFocusedRow(counter)}
              focusedCol={this.nav.getColPos(counter)} key={channel_group.title} group={channel_group} />);
            counter++;
        }
      }

      var subNavStyle = {
        "transform": "translateX("+this.nav.getTranslateX(320, ROW_LANGUAGES, indexed_groups?indexed_groups._keys.length:0)+"px)",
      }

      var translateStyleY = {
        // "transform": "translateY("+this.nav.getTranslateChannelsY()+"px)"
      }

      return (
        <div>
          <div className="transitionAll content-padding" style={translateStyleY}>
            {searchBar}
            <div className={"list-container NavRow "+(this.nav.isFocusedRow(ROW_LANGUAGES)?"activeRow":"inactiveRow")} 
                 style={subNavStyle}>
              {channel_group_option_cards}
              <div className="clearBoth" />
            </div>
            {selected_channel_groups}
            <NavHelper nav={this.nav} />
          </div>
          <div className="FullOpaqueOverlay"></div>
        </div>
      );
    }
}