import Logger from './Logger'
import Backend from './backend'
import CloudDemoEnv from './CloudDemoEnv';
import DemoActionsManager from './DemoActionsManager';

export default class OTTDemoEnv extends CloudDemoEnv {

    constructor(props) {
        super(props)

        Logger.log("OTTDemoEnv called");

        this.isHybridMode = this.isHybridMode.bind(this);
        this.switchChannel = this.switchChannel.bind(this);
        this.showPip = this.showPip.bind(this);
        this.hidePip = this.hidePip.bind(this);
        this.hideOverlay = this.hideOverlay.bind(this);
        this.isPipShown = false;
        this.actionsManager = new DemoActionsManager(this);
        this.backend = Backend.get();
    }

    isHybridMode() {
      return false;
    }

    isBackgroundPosterEnabled() {
      return false;
    }

    switchChannel(channelId) {
    }

    switchToSource(source) {
    }

    sendRemoteKey(key) {
        key = key.toString();
        this.backend.performWebRemoteKey(key);
    }

    showDeeplink(deeplink) {

        // Logger.log("Clicked deeplink", deeplink);

        // var split_url = deeplink.split("/");
        // var hostname = split_url[2];

        // var appId = null;
        // var contentTarget = null;
        // if (hostname.endsWith(".netflix.com")) {
        //     appId = "netflix";
        //     var contentId = null;
        //     if (split_url.indexOf("title") >= 0) {
        //         contentId = split_url[split_url.indexOf("title") + 1];
        //     }
        //     if (contentId === null && split_url.indexOf("watch") >= 0) {
        //         contentId = split_url[split_url.indexOf("watch") + 1];
        //     }
        //     if (contentId !== null && !isNaN(contentId)) {
        //         contentTarget = "-Q m=http://api-global.netflix.com/catalog/titles/movies/" + contentId;
        //     }
        // } else if (deeplink.startsWith("#/youtube/")) {
        //     appId = "youtube.leanback.v4";
        //     contentTarget = "v=" + split_url[split_url.length-1];
        // }

        // Logger.log("App", appId, "Target", contentTarget);

        // if (appId !== null && contentTarget !== null) {
        //     webOS.service.request("luna://com.webos.applicationManager/", {
        //         method: "launch",
        //         parameters: {
        //             "checkUpdateOnLaunch": true,
        //             "id": appId,
        //             "params": {
        //                 "contentTarget": contentTarget
        //             }
        //         },
        //         onSuccess: function (inResponse) {
        //             Logger.log(inResponse);
        //             return true;
        //         },
        //         onFailure: function (inError) {
        //             Logger.log(inError);
        //             return;
        //         }
        //     });

        //     return true;
        // }

        // return false;
    }

    showPip() {
    }

    hidePip() {
    }

    hideOverlay() {
    }

    getTVInputs() {
        
    }

    getHeaders(){
        return {
            'X-Api-Host': 'com.cloud.demo',
            'X-Device-Manufacturer': 'Cloud Demo',
            'X-Device-Model': 'Cloud Demo',
            'X-Device-Product': 'OTT Demo',
            'X-Device-Fingerprint': 'OTT Demo',
            'X-API-Key': "api-key-5051df3c-fbf7-4023"
        }
    }

    getBannerZoneID() {
        return 200
    }
}
