import Backend from './backend'
import { scrollToTop } from './utils';
// import Logger from './Logger'

export var KEYCODE_HOME = 36;
export var KEYCODE_LEFT = 37;
export var KEYCODE_UP = 38;
export var KEYCODE_RIGHT = 39;
export var KEYCODE_DOWN = 40;
export var KEYCODE_ENTER = 13;
export var KEYCODE_ESCAPE = 27;
export var KEYCODE_BACK = 461;
export var KEYCODE_MENU = 93;
export var KEYCODE_CH_UP = 33;
export var KEYCODE_CH_DOWN = 34;
export var KEYCODE_NUM_0 = 48;
export var KEYCODE_NUM_9 = 57;
export var KEYCODE_W = 87;
export var KEYCODE_A = 65;
export var KEYCODE_S = 83;
export var KEYCODE_D = 68;
export var KEYCODE_PLUS = 187;
export var KEYCODE_MINUS = 189;

const navStateStack = []

class RowConfig {
    constructor(rowIndex, maxItems, rowHeight) {
        this.rowIndex = rowIndex
        this.maxItems = maxItems
        this.rowHeight = rowHeight
    }
}

var navSingleton = null;

class DPadNav {

    static get() {
        if (navSingleton === null) {
            navSingleton = new DPadNav();
            return navSingleton
        }
        return navSingleton
    }

    constructor(navState) {

        this.onNavStateChanged = null;
        this.onOKPressed = null;
        this.onBackPressed = null;
        this.showHelper = false;
        this.changeRowOnEndReached = false;


        this.onKeyDown = this.onKeyDown.bind(this);
        this.getTranslateX = this.getTranslateX.bind(this);

        this.reset = this.reset.bind(this);
        this.incrColPos = this.incrColPos.bind(this);
        this.decrColPos = this.decrColPos.bind(this);
        this.incrRowPos = this.incrRowPos.bind(this);
        this.decrRowPos = this.decrRowPos.bind(this);
        this.getColPos = this.getColPos.bind(this);
        this.isFocused = this.isFocused.bind(this);
        this.isFocusedRow = this.isFocusedRow.bind(this);
        this.clearColPosAfter = this.clearColPosAfter.bind(this);
        this.popFromNavStateStack = this.popFromNavStateStack.bind(this)
        this.pushToNavStateStack = this.pushToNavStateStack.bind(this)
        this.setNavState = this.setNavState.bind(this)

        this.rowCount = 0;
        this.rowConfigIndex = {};
        this.searchRowConfigIndex = {};
        this.navState = {
            "row": 1,
            "cols": {}
        }


        if (navState) {
            this.navState.row = navState.row;
            this.navState.cols = navState.cols;
        }
    }

    setChangeRowOnEndReached(bool) {
        this.changeRowOnEndReached = bool
    }

    setNavState(navState) {
        this.navState.row = navState.row;
        this.navState.cols = navState.cols;
        // scrollToTop()
    }

    calcScrollTop(){
        let focussedCard = document.querySelector(".NavCard.cardFocus") || document.querySelector(".NavCard.MediaItemGroupActive")
        let scrollTop = (focussedCard?.getBoundingClientRect().top + document.querySelector(".App").scrollTop) * -1 + 100
        console.log(scrollTop, focussedCard)
        return scrollTop
    }

    scrollContent(scrollTopVal=null){
        let app = document.querySelector(".App")
        let scrollTop = -1 * (scrollTopVal !== null ? scrollTopVal : this.calcScrollTop())
        console.log(app.scrollTop, scrollTopVal, scrollTop)
        app.scrollTop = scrollTop
    }

    popFromNavStateStack(){
        if(navStateStack.length > 0){
            let prevNavState = navStateStack.pop()
            console.log(this)
            Object.assign(this, prevNavState)
            // console.log("reset to old navState", this, this.onKeyDown)
            this.bind(document)
            this.onNavStateChanged && this.onNavStateChanged(this.navState)
            return this.navState
        }
        return null
    }

    pushToNavStateStack(navState) {
        let oldNavState = {...this}
        navStateStack.push(oldNavState)
        // console.log("navStateStack", navStateStack, this.onKeyDown)
        this.navState = navState
        this.onNavStateChanged && this.onNavStateChanged(navState)
    }

    navJumpTo(new_row, new_row_col, element){
        this.navState.row = new_row
        this.navState.cols[new_row] = new_row_col
        this.onNavStateChanged(this.navState);
    }

    reset(row) {
        this.navState = {
            "row": row || 1,
            "cols": {}
        }
        if (this.onNavStateChanged) {
            this.onNavStateChanged(this.navState);
        }
    }

    getColPos(col) {
        if (this.navState.cols[col] === undefined) {
            return 0;
        }
        return this.navState.cols[col];
    }

    incrColPos(row) {
        if (this.navState.cols[row] === undefined) {
            this.navState.cols[row] = 0;
        }
        this.navState.cols[row] += 1;
        var itemCount = this.getItemCount(row);
        if (this.navState.cols[row] >= itemCount) {
            this.navState.cols[row] = Math.max(0, itemCount - 1);
            if (this.changeRowOnEndReached) {
                // go to next row start
                this.navState.row = Math.min(this.getRowCount() - 1, this.navState.row + 1);
                this.navState.cols[this.navState.row] = 0;
            }
        }

        // If this is a media group row
        // the content row needs to be reset
        if (this.isMediaGroupRow(row)) {
            this.navState.cols[row + 1] = 0;
        }
    }



    decrColPos(row) {
        if (this.navState.cols[row] === undefined) {
            this.navState.cols[row] = 0;
        }
        this.navState.cols[row] -= 1;
        if (this.navState.cols[row] < 0) {
            this.navState.cols[row] = 0;
            if (this.changeRowOnEndReached) {
                // go to previous row end
                this.navState.row = Math.max(0, this.navState.row - 1);
                this.navState.cols[this.navState.row] = this.getRowItems(this.navState.row).length - 1;
            }
        }
        if (this.isMediaGroupRow(row)) {
            this.navState.cols[row + 1] = 0;
        }
    }

    incrRowPos() {
        this.navState.row = Math.min(this.getRowCount() - 1, this.navState.row + 1);
        if (this.changeRowOnEndReached) {
            this.navState.cols[this.navState.row] = this.navState.cols[this.navState.row - 1] || 0
        }
    }

    decrRowPos() {
        this.navState.row = Math.max(0, this.navState.row - 1);
        if (this.changeRowOnEndReached) {
            this.navState.cols[this.navState.row] = this.navState.cols[this.navState.row + 1] || 0
        }
    }

    isFocused(row, col, maxCols) {
        return row === this.navState.row && col === this.getColPos(this.navState.row);
    }

    isFocusedRow(row) {
        return row === this.navState.row;
    }

    isFocusedRowBelow(bound) {
        return bound > this.navState.row;
    }

    getItemCount(row) {
        const items = this.getRowItems(row);
        if (items === undefined) {
            return 0;
        }
        return items.length;
    }

    clearColPosAfter(index) {
        for (var col in this.navState.cols) {
            if (this.navState.cols.hasOwnProperty(col) && Number(col) > index) {
                this.navState.cols[col] = 0;
            }
        }
    }

    bind(document) {
        document.onkeydown = this.onKeyDown;
    }

    onKeyDown(event) {
        event = event || window.event;

        console.debug("Keydown " + event.keyCode);

        var navStateChanged = true;

        if (event.keyCode == 68 && event.ctrlKey) { // Ctrl+D
            document.getElementById("eng-demo-video").style.display = "block";
            return;
        }

        if (event.keyCode === KEYCODE_LEFT) {
            this.decrColPos(this.navState.row);
        } else if (event.keyCode === KEYCODE_UP) {
            // this.navState.row = Math.max(0, this.navState.row - 1);
            this.decrRowPos()
        } else if (event.keyCode === KEYCODE_RIGHT) {
            this.incrColPos(this.navState.row)
        } else if (event.keyCode === KEYCODE_DOWN) {
            // this.navState.row = Math.min(this.getRowCount() - 1, this.navState.row + 1);
            this.incrRowPos()
        } else if (event.keyCode === KEYCODE_ENTER) {
            console.log("enter")
            if (this.onOKPressed && this.onOKPressed()) {
                // Do Nothing
            } else {
                var a = document.querySelector(".cardFocus a");
                if (a) {
                    a.click();
                } else {
                    let a_alt = document.querySelector(".cardFocus .a-alt");
                    if (a_alt) {
                        a_alt.click();
                    }
                }

            }

        } else if (event.keyCode === KEYCODE_ESCAPE || event.keyCode === KEYCODE_BACK || event.keyCode === KEYCODE_MENU) {
            if (!this.onBackPressed || !this.onBackPressed()) {
                window.history.back();
            }
        } else if (event.keyCode >= KEYCODE_NUM_0 && event.keyCode <= KEYCODE_NUM_9) {
            if (event.target.id === 'search-input-field') {
                navStateChanged = false
            } else {
                Backend.getEnv().sendRemoteKey(event.keyCode - KEYCODE_NUM_0);
            }
        } else if (event.keyCode === KEYCODE_CH_UP) {
            Backend.getEnv().sendRemoteKey("ch up");
        } else if (event.keyCode === KEYCODE_CH_DOWN) {
            Backend.getEnv().sendRemoteKey("ch down");
        } else if (event.keyCode === KEYCODE_HOME) {
            // window.location.pathname = ''
            let customEvent = new CustomEvent("perform-route-change", { detail: { path: "/", data: {} } });
            document.dispatchEvent(customEvent);
        } else {
            navStateChanged = false;
        }

        if (navStateChanged && this.onNavStateChanged) {
            this.onNavStateChanged(this.navState);
        }

        if (navStateChanged) {
            event.preventDefault();
        }

    }

    getRowCount() {
        return document.querySelectorAll(".NavRow").length;
    }

    getRow(row) {
        return document.querySelectorAll(".NavRow")[row]
    }

    getRowItems(row) {
        const selectedRow = this.getRow(row);
        if (selectedRow === undefined) {
            return [];
        }
        return selectedRow.querySelectorAll(".NavCard");
    }

    isMediaGroupRow(row) {
        const selectedRow = this.getRow(row);
        return selectedRow?.classList.contains("MediaRowGroups");
    }

    getTranslateX(itemWidth, row) {

        var colPos = this.getColPos(row);

        if (colPos === 0) {
            return 0;
        }

        const rowItems = this.getRowItems(row);

        var selectedItem = rowItems[colPos];

        if (!selectedItem) {
            selectedItem = rowItems[rowItems.length - 1];
        }

        if (!selectedItem) {
            return 0;
        }

        var offsetLeft = selectedItem.offsetLeft;

        // Logger.log("NavRow", offsetLeft);

        return Math.min(-offsetLeft + 60, 0);

    }

    getTranslateXStyle(row) {
        return {
            "transform": "translateX(" + this.getTranslateX(0, row) + "px)"
        }
    }

    getTranslateChannelsY(isOverlay) {

        var mul = 0.3;
        if (isOverlay) {
            mul = 0.6;
        }

        var selectedRow = this.getRow(this.navState.row);

        if (!selectedRow) {
            return 0;
        }

        if (selectedRow.classList.contains("hasMediaRowGroups")) {
            selectedRow = this.getRow(this.navState.row - 1);
        }

        var altY = -selectedRow.offsetTop + 100;
        if (-altY < (window.outerHeight * mul)) {
            altY = 0;
        }

        // Logger.log("Navstate " + this.navState.row, mul, window.outerHeight, window.outerHeight * mul, -altY, altY);

        return altY;
    }
}

export {
    DPadNav,
    RowConfig,
}