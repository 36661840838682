import React, { Component } from 'react'
import Image360View from './Image360View';
import Panorama from './Panorama'
import { checkIsFullScreen } from '../utils';

export default class FullscreenViewManager extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show360Image: false,
            showPanorama: false,
            Image360: null,
            panorama_identifier: null
        }
        FullscreenViewManager.__singletonRef = this;
        this.onFullScreenChange = this.onFullScreenChange.bind(this)
        this.show360Image = this.show360Image.bind(this)
    }

    componentDidMount() {
        ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'].forEach(evt =>
            document.addEventListener(evt, this.onFullScreenChange)
        );
    }

    componentWillUnmount() {
        ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'].forEach(evt =>
            document.removeEventListener(evt, this.onFullScreenChange)
        );
    }

    onFullScreenChange() {
        let isFullScreen = checkIsFullScreen()
        let { show360Image, showPanorama } = this.state
        if (show360Image && !isFullScreen) {
            show360Image = false
        }else if (showPanorama && !isFullScreen){
            showPanorama = false
        }
        this.setState({
            show360Image,
            showPanorama
        })
    }

    static show360Image(image360) {
        FullscreenViewManager.__singletonRef.show360Image(image360)
    }

    show360Image(image360) {
        console.log("show360Image", image360)
        this.setState({
            image360,
            show360Image: true
        })
    }

    static showPanorama(panorama_identifier) {
        FullscreenViewManager.__singletonRef.showPanorama(panorama_identifier)
    }

    showPanorama(panorama_identifier) {
        console.log("showPanorama", panorama_identifier)
        this.setState({
            panorama_identifier,
            showPanorama: true
        })
    }

    render() {
        if (!this.state.show360Image && !this.state.showPanorama) {
            return null
        }
        if(this.state.show360Image){
            return (
                <Image360View
                    onError={() => this.setState({ show360Image: false })}
                    image360={this.state.image360}
                />
            )
        }else if (this.state.showPanorama){
            return (
                <Panorama
                    is_shown={this.state.showPanorama}
                    panorama_identifier={this.state.panorama_identifier}
                />
            )
        }
        
    }
}
