import React from "react";


const AddedLanguageAlert = ({languages}) => {
    return <div className='added-favorite-alert'>Added to {languages.join(', ')} language{(languages.length>0?'s':'')} to Favorites</div>
}

const VannilaAlert = ({text, classNames})=>{
    return <div className={'vannila-alert '+classNames}>{text}</div>
}

export {
    AddedLanguageAlert,
    VannilaAlert,
}

