import React, { Component } from 'react';

import CenterCropImage from './CenterCropImage';
import CardMeta from './CardMeta';
import Backend from './backend'

import { truncate } from 'underscore.string';
import { Link } from 'react-router-dom';

export default class FacetCard extends Component {

  constructor(props) {
      super(props)
      this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (Backend.get().referrerURL !== undefined && Backend.get().referrerURL.lastIndexOf(window.location.hash) === -1){
      Backend.get().referrerURL.push(window.location.hash);
    }
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    var facet = this.props.facet;
    var subTitle = facet.entity_type;

    return (
        <div className={"card facetCard NavCard " + (this.props.isFocused?"cardFocus":"")}>
          <Link to={"/detail/facet/" + facet.id} onClick={this.onClick}>
            <CenterCropImage width={160} src={facet.image} alt={facet.title} />
            <CardMeta title={truncate(facet.title, 17)} subTitle={truncate(subTitle, 17)} />
            <div className="cardOverlay" />
          </Link>
        </div>
      )
  }
}