import React, { Component } from 'react';

import DeeplinkCard from './DeeplinkCard'

export default class DeeplinkListView extends Component {

  render() {

    var item = this.props.item;

    var cards = [];
    var deeplinks = item.documents;
    for (var i=0; i < deeplinks.length; i++) {
        var deeplink = deeplinks[i];
        cards.push(<DeeplinkCard isFocused={this.props.isFocusedRow && this.props.focusedCol===i} key={deeplink.url} deeplink={deeplink} />);
        if (cards.length === 10) {
            break;
        }
    }

    var translateStyle = {
        "transform": "translateX("+this.props.nav.getTranslateX(380, this.props.row)+"px)"
    }

    return (
        <div className={"channelGroup NavRow " + (this.props.isFocusedRow?"activeRow":"inactiveRow")}>
            <div className="cardTitle">{ item.category_verbose }</div>
            <div className="list-container" style={translateStyle}>
                {cards}
            </div>
            <div className="clearBoth" />
        </div>
      );
  }
}