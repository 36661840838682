import React, { Component } from 'react'
import Backend from '../backend'
import { KEYCODES } from '../utils'

const API_PREROLL_AD = 'api/v4/engagement/test_predefined_promotion?mode=pull&id=-9&user_id=50287417'

export default class PreRollAdManager extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show_preroll_ad:false,
            preroll_ad: null,
            error: null,
            url_to_view_after_ad_end: null
        }
        this.backend = Backend.get()
        this.preloadAPreRollAd = this.preloadAPreRollAd.bind(this)
        this.navigateToDeeplink = this.navigateToDeeplink.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)


        PreRollAdManager.__singletonRef = this;
        this.__showPrerollAd = this.__showPrerollAd.bind(this);
        this.__hidePrerollAd = this.__hidePrerollAd.bind(this);
        this.__isAdPlaying = this.__isAdPlaying.bind(this);
    }

    componentDidMount() {
        this.preloadAPreRollAd()
        document.addEventListener('keydown', this.handleKeyPress, true)

    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress, true)
    }

    handleKeyPress(event) {
        if (this.state.show_preroll_ad) {
            event.stopPropagation()
            event.preventDefault()
            switch (event.keyCode) {
                case KEYCODES.RIGHT:
                    this.navigateToDeeplink()
                    break;
                default:
                    break;
            }
            return false;
        }
    }

    static showPrerollAd(new_preroll_ad) {
        PreRollAdManager.__singletonRef.__showPrerollAd(new_preroll_ad)
    }
    __showPrerollAd(url_to_view_after_ad_end) {
        this.setState({
            show_preroll_ad: true,
            url_to_view_after_ad_end: url_to_view_after_ad_end 
        })
    }

    static hidePrerollAd() {
        PreRollAdManager.__singletonRef.__removePrerollAd()
    }

    __hidePrerollAd() {
        this.setState({
            show_preroll_ad: false
        })
    }

    static __isAdPlaying() {
        PreRollAdManager.__singletonRef.__isAdPlaying()
    }

    __isAdPlaying() {
        return this.state.show_preroll_ad
    }

    preloadAPreRollAd() {
        this.backend.getJSONResult(API_PREROLL_AD, (error, response) => {
            if (error) {
                this.setState({
                    error: error
                })
            } else {
                console.log(response)
                this.setState({
                    preroll_ad: response.video
                })
            }
        })
    }

    navigateToDeeplink(){
        if(this.state.url_to_view_after_ad_end){
            window.location.replace(this.state.url_to_view_after_ad_end)
        }
    }

    render() {
        if(!this.state.show_preroll_ad){
            return null
        }
        return (
            <div className='preroll-ad-container'>
                {this.state.preroll_ad && <video className='preroll-ad' autoPlay controls onEnded={this.navigateToDeeplink}>
                    <source src={this.state.preroll_ad} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>}
            </div>
        )
    }
}
