import Backend from './backend'
import BaseEnv from './BaseEnv'

var tuneCB = {
   onsuccess: function() {
       console.log("tuneUp() is successfully done. And there is a signal.");
   },
   onnosignal: function() {
       console.log("tuneUp() is successfully done. But there is no signal.");
   }
};

export default class TizenEnv extends BaseEnv {

    constructor(props) {
        super(props)

        var that = this;

        console.log("TizenEnv called");


        this.switchChannel = this.switchChannel.bind(this);
        this.showPip = this.showPip.bind(this);
        this.hidePip = this.hidePip.bind(this);
        this.hideOverlay = this.hideOverlay.bind(this);

        window.addEventListener('appcontrol', this.deepLink);
        this.deepLink();

        //48 to 57 for 0 to 9
        var keys = ["ChannelUp", "ChannelDown", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        for (var i = 0; i < keys.length; i++) {
            try {
                window.tizen.tvinputdevice.registerKey(keys[i]);
            } catch(error) {
                console.log("failed to register " + keys[i] + ": " + error);
            }
        }

        function navigateBack() {
            var currentPage = window.location.href;

            console.log(that.referrerURL);
            var referrer = that.referrerURL.pop();

            //on_air always present in the stack at the bottom
            if (referrer !== undefined){
              if (referrer === "#/on_air"){
                that.referrerURL.push("#/on_air");          
              }
              window.location.href = referrer;
            }else{
              window.history.go(-1);
            }

            // if (currentPage.lastIndexOf("channels") === -1) {
            //   window.location.href = that.referrerURL;
            // }else{
            //   window.location.href = "#/on_air";
            // }

            setTimeout(function(){ 
                if (window.location.href === currentPage) {
                    var conf = window.confirm("Are you sure you want to quit Sensy?");
                    if (conf === true) {
                      window.tizen.application.getCurrentApplication().exit(); 
                    }
                }
            }, 250);
        }

        var codeToNumber = {
          48: 0,
          49: 1,
          50: 2,
          51: 3,
          52: 4,
          53: 5,
          54: 6,
          55: 7,
          56: 8,
          57: 9,
        }

        var timerVar;

        document.addEventListener("keydown", function(e) {
          if (e.keyCode === 10009 || e.keyCode === 27) {
            //back button
            if (that.isOverlay){
              //if on search page do "navigate" back..
              if (window.location.hash.indexOf("search") !== -1){
                navigateBack();
              }
              window.tizenWrapper.hideOverlay();
              that.unsetOverlayFlag();
            }else if (that.isPip){
              //"navigate back" instead of history and then remove pip
              navigateBack();
              window.tizenWrapper.hidePip();
              that.unsetPipFlag();
            }else if (that.isYoutube){
              that.unsetYoutubeFlag();
              //brute fix.. try to not let it propagate till here
            }else{
              navigateBack()
            }
          }
          if (e.keyCode === 27) {
            if (!that.isYoutube){
              navigateBack();
            }else{
              that.unsetYoutubeFlag();
            }
          }
          if (e.keyCode === 40) {
            //down arrow.
            if (that.isPip){
              window.tizenWrapper.showOverlay();
              that.setOverlayFlag();
            }
          }
          if (e.keyCode === 13){
            //Ok button.
            if ((that.isOverlay) || (that.isPip)) {
              if (document.getElementById("numberOverlay").style.visibility === "visible"){
                window.tizen.tvchannel.tune({major: Number(document.getElementById("numberOverlay").innerHTML)}, tuneCB);
                document.getElementById("numberOverlay").innerHTML = ""
                document.getElementById("numberOverlay").style.visibility = "hidden";
              }        
            }
          }
          if (e.keyCode === 38) {
            //up arrow
          }
          if (e.keyCode === 39) {
            //rt arrow
            // if (that.isPip){
              //get episodes listing and switch
            // }
          }
          if (e.keyCode === 37) {
            //left arrow
            // if (that.isPip){
              
            // }
          }
          if (e.keyCode === 427) {
            //ch up
            if ((that.isOverlay) || (that.isPip)) {
              try {
                window.tizen.tvchannel.tuneUp(tuneCB, null, "ALL");
              } catch (error) {
                console.log("Error name = "+ error.name + ", Error message = " + error.message);
              }
            }
          }
          if (e.keyCode === 428) {
            //ch down
            if ((that.isOverlay) || (that.isPip)) {
              try {
                window.tizen.tvchannel.tuneDown(tuneCB, null, "ALL");
              } catch (error) {
                console.log("Error name = "+ error.name + ", Error message = " + error.message);
              }
            }
          }

          //NUmber keys
          if (codeToNumber[e.keyCode] !== undefined){
            if (true || (that.isOverlay) || (that.isPip)) {
              clearTimeout(timerVar);
              document.getElementById("numberOverlay").style.visibility = "visible";
              document.getElementById("numberOverlay").innerHTML += codeToNumber[e.keyCode];
              var currentNumber = document.getElementById("numberOverlay").innerHTML;
              if (Backend.get().chNumsToChIds[currentNumber] !== undefined){
                var ctr = 0;
                var chNum;
                for (chNum in Backend.get().chNumsToChIds){
                  if (chNum.indexOf(currentNumber) !== -1){
                    ctr += 1;
                  }
                }
                if (ctr === 1){
                  window.tizen.tvchannel.tune({major: Number(currentNumber)}, tuneCB);
                  document.getElementById("numberOverlay").innerHTML = ""
                  document.getElementById("numberOverlay").style.visibility = "hidden";
                  
                  Backend.get().getChannelGroups(function(channel_groups, indexed_groups) {

                    var requiredChannel = Backend.get().chNumsToChIds[currentNumber];

                    for (i in channel_groups) {
                        var group = channel_groups[i];
                        for (var j in group.episodes) {
                            var episode = group.episodes[j];
                            if (requiredChannel === episode.channel_id) {
                                window.location.hash = "#/detail/episode/" + episode.id;
                                break;
                            }
                        }
                    }
                  });
                }
              }
              timerVar = setTimeout(function(){
                  document.getElementById("numberOverlay").innerHTML = ""
                  document.getElementById("numberOverlay").style.visibility = "hidden";
              }, 5000);
            }
          }

        });
    }

    deepLink() {

      var tizen = window.tizen || {};
      if (!tizen.application) {
        return;
      }

      var requestedAppControl = tizen.application.getCurrentApplication().getRequestedAppControl();
      var appControlData;
      var actionData;

      var episodeIdx;
      // var channelIdx;

      if (requestedAppControl) {
          appControlData = requestedAppControl.appControl.data; // get appcontrol data. action_data is in it.

          for (var i = 0; i < appControlData.length; i++) {
              if (appControlData[i].key === 'PAYLOAD') { // find PAYLOAD property.
                  actionData = JSON.parse(appControlData[i].value[0]).values; // Get action_data
                  var jsonData = JSON.parse(actionData)
                  episodeIdx = jsonData['episodeIdx'];
                  // channelIdx = jsonData['channelIdx'];
                  var lcn = jsonData['lcn']
                  window.location.hash = "#/detail/episode/" + episodeIdx;
                  window.tizenWrapper.showPip();
                  this.setPipFlag();
                  window.tizen.tvchannel.tune({major: Number(lcn)}, tuneCB); 
              }
          }
      } else {
        console.log("ummm");
      }

    }

    switchChannel(channelId) {

      if (channelId in Backend.get().lcn) {
        window.tizenWrapper.showPip();
        this.setPipFlag();
        window.tizen.tvchannel.tune({major: Backend.get().lcn[channelId]}, tuneCB);
      }        
    }

    showPip() {
        window.tizenWrapper.showPip();
    }

    hidePip() {
        window.tizenWrapper.hidePip();
    }

    hideOverlay() {
        window.tizenWrapper.hideOverlay();
    }

}