import * as firebase from "firebase/app";
import "firebase/messaging";
import Backend from "./backend";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDwFWcSVJ_rK6-nqEtjuaqDOHMH4rmy5j8",
    authDomain: "certain-gearbox-753.firebaseapp.com",
    databaseURL: "https://certain-gearbox-753.firebaseio.com",
    projectId: "certain-gearbox-753",
    storageBucket: "certain-gearbox-753.appspot.com",
    messagingSenderId: "164520993425",
    appId: "1:164520993425:web:fd581619510a6fcd1c9530"
});
let can_use_fcm = true
let messaging
try{
    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(
        "BBm8vTAEK-SjaDITwy78eVTR8nnP8oyScb7iJzPW8Wu80dfuNxqQlw4maJjm48zTDqdHd9QpCeY8LTf6JVDPD1w"
    );
}catch(e){
    can_use_fcm=false
}

let basename = Backend.getDeployedBasePath()
if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register(`${basename}/firebase-messaging-sw.js`)
        .then(function (registration) {
            messaging.useServiceWorker(registration);
            console.log("Service Worker Registration successful, scope is:", registration.scope);
            let event = new CustomEvent("service-worker-registration-success");
            document.dispatchEvent(event);
        })
        .catch(function (err) {
            console.log("Service worker registration failed, error:", err);
        });
}

export { messaging, can_use_fcm };