import React, { Component } from 'react';

export function PrimaryButton(props) {
    return <BaseButton btnClassName='btn btn-primary ' {...props} />
}

export function IconButton(props) {
    const iconButtonStyles = {
        width: "auto", height: "auto", padding: "7px 10px", fontSize: "1.3rem"
    }
    return <BaseButton btnClassName='btn btn-dark icon-button ' style={iconButtonStyles} {...props} >
        {props.icon && <img className='button-icon mr-2' src={props.icon} />}
        {props.children}
    </BaseButton>
}

function BaseButton(props) {
    let btnClassName = props.btnClassName
    return (
        <button className={btnClassName + (props.className || '')} style={props.style} onClick={props.onClick} disabled={props.disabled}>
            {props.children}
        </button>
    )
}