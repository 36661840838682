import React, { Component } from 'react';
import LazyLoad from 'react-lazy-load';

const spacer = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";


export default class CenterInsideImage extends Component {

  render() {

    const style = {
      backgroundImage: 'url(' + this.props.src + ')',
    };

    return (
        <LazyLoad height={160} offsetTop={700}>
            <img alt="" className="centerInsideImage" src={spacer} style={style} />
        </LazyLoad>
      )
  }
}