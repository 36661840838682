import React, { Component } from "react";
import Engagement from "./Engagement";

export default class EngagementManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            engagement: null,
            count: 0,
        }
        EngagementManager.__singletonRef = this;
        this.__removeEngagement = this.__removeEngagement.bind(this);
        this.__addEngagement = this.__addEngagement.bind(this);
        this.__hasEngagement = this.__hasEngagement.bind(this)
    }

    static addEngagement(new_engagement) {
        EngagementManager.__singletonRef.__addEngagement(new_engagement)
    }
    __addEngagement(new_engagement) {
        this.setState({
            engagement: new_engagement,
        })
    }

    static removeEngagement() {
        EngagementManager.__singletonRef.__removeEngagement()
    }

    __removeEngagement() {
        this.setState({
            engagement: null
        })
    }

    static hasEngagement(){
        if (EngagementManager.__singletonRef)
            return EngagementManager.__singletonRef.__hasEngagement()
        return false
    }

    __hasEngagement() {
        return this.state.engagement!==null
    }


    render() {
        return <Engagement engagement={this.state.engagement} />
    }
}

