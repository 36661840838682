import React from 'react';
import { truncate } from 'underscore.string';
import usersIcon from '../../includes/images/icon_users.svg'
import liveIcon from '../../includes/images/icon_live.svg'


export function ViewerCountGlass({ viewer_count }) {
    return (
        <div className="camera-device-viewer-count">
            <div className="frosted-glass-container">
                <div className="frosted-glass" />
            </div>
            <img src={usersIcon} alt="" style={{ width: "20px", height: "20px" }} />
            <span className='px-2' style={{ fontWeight: "bold" }}>{viewer_count}</span>
        </div>
    )
}


export default function CameraDeviceCard2({ onClick, camera_device, isFocused }) {
    let title = camera_device.name;
    let city = camera_device.address_city
    let state = camera_device.address_state
    let viewer_count = camera_device.viewer_count || 0

    return (
        <div className={"camera-device-card  NavCard " + (isFocused ? "cardFocus" : "")} onClick={onClick}>
            <div className="camera-device-cover-image-container col-md-12 p-0">
                {
                    camera_device.live &&
                    <div className="blinker live-badge">
                        LIVE
                    </div>
                }
                <div className='camera-device-cover-image' alt={camera_device.name} style={{ backgroundImage: `url(${camera_device.image})` }} />
                {
                    camera_device.live && <ViewerCountGlass viewer_count={viewer_count} />
                }
            </div>
            <div className='camera-device-meta p-2'>
                <div>
                    <div className='camera-device-meta-heading'>{truncate(title, 16)}</div>
                    <div className='camera-device-meta-sub-heading'>{city && city}{state && `, ${state}`}</div>
                </div>
                {
                    camera_device.live &&
                    <img src={liveIcon} alt="" style={{ width: "35px" }} />
                }

            </div>
        </div>
    )
}
