import React, { Component } from 'react';
import Logger from './Logger'
import CardMeta from './CardMeta';
import Backend from './backend'

import { truncate } from 'underscore.string';

export default class TVInputCard extends Component {

  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    try {
      Backend.getEnv().switchToSource(this.props.tv_input.title);
    } catch(error) {
      Logger.log(error)
    }    
  }

  render() {
    var title = this.props.tv_input.title;
    var link = "#";

    return (
        <div className={"card tvInputCard NavCard " + (this.props.isFocused?"cardFocus":"")}>
          <a href={link} onClick={this.onClick}>
            <img className="tvInputCardImage" alt="TV Input" src={this.props.tv_input.image} />
            <CardMeta title={truncate(title, 13)} />
          </a>
          <div className="cardOverlay" />
        </div>
      )
  }
}