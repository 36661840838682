import React, { Component } from 'react';

import Backend from './backend'

import CenterCropImage from './CenterCropImage';
import CardMeta from './CardMeta';

import { truncate } from 'underscore.string';

export default class DeeplinkCard extends Component {

  constructor(props) {
      super(props)

      this.env = Backend.getEnv();
      this.onClick = this.onClick.bind(this);
  }

  getUrl() {
    var url = this.props.deeplink.url;

    if (url.indexOf("youtube.com") !== -1) {
      var yt_id = url.split('v=')[1];
      var andPos = yt_id.indexOf('&');
      if(andPos !== -1) {
        yt_id = yt_id.substring(0, andPos);
      }

      url = "/youtube/" + yt_id;
    }

    return url;

  }

  onClick(event) {
    if (this.env.showDeeplink(this.getUrl())) {
      event.preventDefault();
    }
  }

  render() {
    var deeplink = this.props.deeplink;
    var url = this.getUrl();

    var width = 360;
    var height = 180;

    var cardStyle = {};

    if (deeplink.app !== null && deeplink.app.image_orientation === "portrait") {
      width = 151 * 1.4;
      height = 216 * 1.4;

      cardStyle.width = width + "px";
    }

    return (
        <div className={"card deeplinkCard NavCard " + (this.props.isFocused?"cardFocus":"")} style={cardStyle} >
          <a href={url} onClick={this.onClick}>
            <CenterCropImage width={width} height={height} src={deeplink.image} alt={deeplink.title} />
            <CardMeta title={truncate(deeplink.title, 50)}  />
          </a>
          <div className="cardOverlay" />
        </div>
      )
  }
}