import React, { Component } from 'react';

import Backend from './backend'
import SearchBar from './SearchBar'

import PreferenceCard from './PreferenceCard'
import NavHelper from './NavHelper'

import { DPadNav } from './dpadnav'
import { LanguagePreference } from './models'

var ROW_SEARCH = 0;
var ROW_LANGUAGES = 1;

export default class LanguagePreferenceView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            "isLoading": false,
            "preferenceList": [],
            "navState": {
              "row": 1,
              "cols": {}
            }
        }

        this.backend = Backend.get();
        this.onPreferenceClicked = this.onPreferenceClicked.bind(this);

        this.nav = new DPadNav(this.state.navState);
    }

    componentDidMount() {
        this.setState({
          "preferenceList": LanguagePreference.getPreferenceList()
        })
        this.nav.bind(document);

        var that = this;
        this.nav.onNavStateChanged = function(navState) {
          that.setState({
            navState: navState
          },()=>{
            that.nav.scrollContent()
          });
        }
    }

    onPreferenceClicked(preference) {
      this.setState({
        "preferenceList": LanguagePreference.getPreferenceList()
      })
      this.backend.setLanguages(LanguagePreference.getLanguagesConfig());
    }

    render() {

      var searchBar = <SearchBar nav={this.nav} isFocused={this.nav.isFocusedRow(ROW_SEARCH)} focusedCol={this.nav.getColPos(ROW_SEARCH)}/>;

      var counter = 1; // 0 is Search

      var items = [];
      var preferenceList = LanguagePreference.getPreferenceList();
      for (var i=0; i<preferenceList.length; i++) {
        var preference = preferenceList[i];
        items.push(<PreferenceCard key={i} preference={preference} isChecked={preference.isChecked()} onClick={this.onPreferenceClicked}
            row={counter} isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(counter) === i}
         />);
      }
      counter++;
 
      var subNavStyle = {
        "transform": "translateX("+this.nav.getTranslateX(320, ROW_LANGUAGES, items.length)+"px)",
      }

      var translateStyleY = {
        // "transform": "translateY("+this.nav.getTranslateChannelsY()+"px)"
      }

      return (
        <div>
          <div className="transitionAll content-padding" style={translateStyleY}>
            {searchBar}
            <h1>Select your languages</h1>
            <h2>Sensy will use these to suggest you TV programs</h2>
            <div className={"list-container NavRow "+(this.nav.isFocusedRow(ROW_LANGUAGES)?"activeRow":"inactiveRow")} style={subNavStyle} >
              { items }
              <div className="clearBoth" />
            </div>
          </div>
          <NavHelper nav={this.nav} />
          <div className="FullOpaqueOverlay"></div>
        </div>
      );
    }
}