import React, { Component } from 'react';
import Logger from './Logger'
import CenterCropImage from './CenterCropImage';
import CardMeta from './CardMeta';
import Backend from './backend'

import { truncate } from 'underscore.string';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class EpisodeCard extends Component {

  constructor(props) {
      super(props)

      this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    Logger.log("Episode card clicked", arguments);
    // set referrer
    if ((this.props.location.pathname.lastIndexOf('on_air') !== -1) && (Backend.getEnv().referrerURL.lastIndexOf("/on_air") === -1)){
      Backend.getEnv().referrerURL.push("/on_air");
    } else if ((this.props.location.pathname.lastIndexOf('channels') !== -1) && (Backend.getEnv().referrerURL.lastIndexOf("/channels") === -1)){
      Backend.getEnv().referrerURL.push("/channels");
    }
    
    try {
      Backend.getEnv().switchChannel(this.props.episode.channel.id);
    } catch(error) {
      Logger.log(error)
    }
    event.preventDefault();
  }

  render() {
    var episode = this.props.episode;

    var title = episode.getTitle();
    var subTitle = episode.channel.name;
    var link = "/detail/episode/" + episode.id;

    return (
        <div className={"card episodeCard NavCard " + (this.props.isFocused?"cardFocus":"")}>
          <Link to={link} onClick={this.onClick}>
            <CenterCropImage src={episode.getImage()} alt={title} />
            <CardMeta title={truncate(title, 25)} subTitle={truncate(subTitle, 30)} />
          </Link>
          <div className="cardOverlay" />
        </div>
      )
  }
}

export default withRouter(EpisodeCard)