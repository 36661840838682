import React, { Component } from 'react';
import YouTube from 'react-youtube';
import Backend from './backend'
import Logger from './Logger'

export default class YouTubeView extends Component {

    constructor(props) {
        super(props)
        this.props = props;
        this.env = Backend.getEnv();;

        this.state = {
          videoId: props.params.id,
          player: null,
        };

        this.onReady = this.onReady.bind(this);
    }

    onReady(event) {
        this.setState({
          player: event.target,
        });
        
        this.env.hideOverlay();
        this.env.unsetOverlayFlag();
        this.env.hidePip();
        this.env.unsetPipFlag();
        this.env.setYoutubeFlag();
    }

    onStateChange(event){
        Logger.log("State Changed");
        if (event.target.getPlayerState() === YouTube.PlayerState.ENDED){
            window.history.go(-1);
            this.env.showPip();
            this.env.setPipFlag();
            this.env.unsetYoutubeFlag();
        }
    }

    componentDidMount() {

      var that = this;

      document.onkeydown = function(e) {
          if (e.keyCode === 10009) {
            window.history.go(-1);
            that.env.showPip();
            that.env.setPipFlag();
          }
          if (e.keyCode === 27) {
            window.history.go(-1);
            that.env.showPip();
            that.env.setPipFlag();
          }
          if (e.keyCode === 40) {
            Logger.log("Down KEY");
            var playbackRates = that.state.player.getAvailablePlaybackRates()
            var currentSpeed = that.state.player.getPlaybackRate();
            var idx = playbackRates.indexOf(currentSpeed);
            if (idx > 0){
                that.state.player.setPlaybackRate(playbackRates[idx-1]);
            }
          }
          if (e.keyCode === 38) {
            Logger.log("Up KEY");
            playbackRates = that.state.player.getAvailablePlaybackRates()
            currentSpeed = that.state.player.getPlaybackRate();
            idx = playbackRates.indexOf(currentSpeed);
            if ((idx+1) < playbackRates.length){
                that.state.player.setPlaybackRate(playbackRates[idx+1]);
            }
          }
          if (e.keyCode === 39) {
            Logger.log("RIGHT KEY");
            var currentTime = that.state.player.getCurrentTime();
            if ((currentTime + 10) < that.state.player.getDuration()) {
               that.state.player.seekTo((currentTime + 10), true); 
            }
          }
          if (e.keyCode === 37) {
            Logger.log("Left KEY");
            currentTime = that.state.player.getCurrentTime();
            if ((currentTime - 10) > 0) {
               that.state.player.seekTo((currentTime - 10), true); 
            }
          }
          if (e.keyCode === 13) {
            Logger.log("OK KEY (Play/Pause)");
            var state = that.state.player.getPlayerState();
            if (state === window.YT.PlayerState.PLAYING){
                that.state.player.pauseVideo()
            }else if (state === window.YT.PlayerState.PAUSED){
                that.state.player.playVideo()
            }
          }
        };
    }

    render() {
        const opts = {
          height: window.outerHeight,
          width: window.outerWidth,
          playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            enablejsapi: 1,
          }
        };

        return (
          <YouTube
            videoId={this.state.videoId}
            opts={opts}
            onReady={this.onReady}
            onStateChange={this.onStateChange}
          />
        );
      }



}