import React from 'react';
// import Logger from './Logger'
import MediaItemView from './MediaItemView';
import MediaActionView from './MediaActionView';
import YouTube from 'react-youtube';
import ReactBubbleChart from 'react-bubble-chart';
import FullScreenVideo from './FullScreenVideo';
import playIcon from '../includes/images/icon_play.svg'
import { withRouter } from 'react-router';

const DESCRIPTION_MAX_LENGTH = 300
var colorLegend = [
    //reds from dark to light
    { color: "#67000d", text: 'Negative', textColor: "#ffffff" }, "#a50f15", "#cb181d", "#ef3b2c", "#fb6a4a", "#fc9272", "#fcbba1", "#fee0d2",
    //neutral grey
    { color: "#f0f0f0", text: 'Neutral' },
    // blues from light to dark
    "#deebf7", "#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", { color: "#08306b", text: 'Positive', textColor: "#ffffff" }
];

var tooltipProps = [{
    css: 'symbol',
    prop: '_id'
}, {
    css: 'value',
    prop: 'value',
    display: 'Last Value'
}, {
    css: 'change',
    prop: 'colorValue',
    display: 'Change'
}];

class MediaHeaderView extends MediaItemView {

    constructor(params) {
        super(params)
        this.state = {
            show_intersitial_video: true,
            isPlaying: false,
        }
        this.videoRef = React.createRef()
        this.isFacetPage = this.isFacetPage.bind(this)
    }

    getHeaderStyleParams() {

        const mediaHeader = this.props.mediaHeader;

        var bgColor = null, textColor = null;
        if (mediaHeader.styles) {
            for (var i = 0; i < mediaHeader.styles.length; i++) {
                var style = mediaHeader.styles[i];
                // TODO: Bring this back
                // if (style.key === "bg_color") {
                //     bgColor = style.value;
                // } else if (style.key === "text_color") {
                //     textColor = style.value;
                // } 
            }
        }

        if (bgColor !== null && textColor !== null) {

            return {
                "color": textColor,
                "backgroundColor": bgColor,
            }
        }
    }

    getFullViewOverlayStyleParams() {

        const mediaHeader = this.props.mediaHeader;

        var bgColor = null;
        if (mediaHeader.styles) {
            for (var i = 0; i < mediaHeader.styles.length; i++) {
                var style = mediaHeader.styles[i];
                if (style.key === "bg_color") {
                    bgColor = style.value;
                }
            }
        }

        return {
            "background": "linear-gradient(to top, " + bgColor + " 20%,rgba(0,0,0,0) 60%)"
        }
    }

    getTitleImageParams() {
        return {
            "maxHeight": "90px"
        }
    }

    isFacetPage() {
        console.log(this.props.match?.params?.itemType)
        if (this.props.match?.params?.itemType === "facet") {
            return true
        }
    }

    render() {

        let { can_show_video, onVideoEnded, env, mediaHeader = {} } = this.props
        let { item = {} } = mediaHeader
        let { video } = item
        let { isPlaying } = this.state
        let description = mediaHeader.description || item.description

        const playingStatus = this.getPlayingStatus(mediaHeader.item)
        let image = mediaHeader.item.image;
        let isBannerReplaced = false

        var bannerImage = null, titleImage = null, interstitialVideo = null;
        if (mediaHeader.images) {
            for (var i = 0; i < mediaHeader.images.length; i++) {
                var mhImage = mediaHeader.images[i];
                if (mhImage.image_type === "title") {
                    titleImage = mhImage.image;
                } else if (mhImage.image_type === "banner") {
                    bannerImage = mhImage.image;
                } else if (mhImage.image_type === 'interstitial_video') {
                    interstitialVideo = mhImage.image
                    // interstitialVideo = "https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_480_1_5MG.mp4"
                }
            }
        }

        let videoId = null;
        if (video && video.indexOf("youtube.com") !== -1) {
            let split_url = video.split("=");
            videoId = split_url[split_url.length - 1];
            if (!env.isYoutubeTrailersEnabled()) {
                videoId = null
                video = null
            }
        }

        var actions = [];

        if (mediaHeader.item.actions !== null && mediaHeader.item.actions.length > 0) {
            actions = mediaHeader.item.actions.map((mediaAction, key) =>
                <MediaActionView isFocused={this.props.isFocusedRow && this.props.focusedCol === key}
                    key={key + 1} mediaAction={mediaAction} env={env}
                    show_send_to_phone_card={this.props.show_send_to_phone_card}
                />
            );
            // this.props.focusedCol===key+1
        }

        var containerClass = "MediaHeaderView NavRow";
        if (this.isFacetPage()) {
            containerClass += " d-flex align-items-center"
        }
        if (bannerImage) {
            if (this.state.fullView) {
                containerClass = "MediaHeader NavRow MediaHeaderFullView";
            }
        }

        var bannerImageParams = {};

        if (mediaHeader.bg_color) {
            bannerImageParams["background"] = "linear-gradient(to right, " + mediaHeader.bg_color + " 0%,rgba(0,0,0,0) 100%)";
        }

        var subtitleClassName = "";
        if (this.state.fullView && mediaHeader.title_image) {
            subtitleClassName = "MediaSubtitleWithoutTitle";
        }

        const playerOpts = {
            height: '590',
            width: '980',
            playerVars: {
                autoplay: 1,
                controls: 0
            }
        };

        let showing_video = false
        if (can_show_video && (videoId || video)) {
            showing_video = true
        }

        let bannerContainerParams = {
            backgroundImage: (bannerImage !== null && !showing_video) ? 'url(' + bannerImage + ')' : '',
            backgroundPosition: "center"
        };

        // Logger.log("NB", bannerImageParams);
        console.log(this.isFacetPage(), bannerImage, bannerContainerParams)

        return <div className={containerClass} style={this.getHeaderStyleParams()}>
            <>

                {
                    this.props.mediaHeader.bubblePlot &&
                    <ReactBubbleChart
                        className="MediaHeaderBubbleChart"
                        colorLegend={colorLegend}
                        data={this.props.mediaHeader.bubblePlot}
                        selectedColor="#737373"
                        selectedTextColor="#d9d9d9"
                        fixedDomain={{ min: -1, max: 1 }}
                        tooltip={true}
                        tooltipProps={tooltipProps}
                        smallDiameter={50}
                    />
                }

                {interstitialVideo && this.state.show_intersitial_video && <FullScreenVideo video_src={interstitialVideo} onHide={() => this.setState({ show_intersitial_video: false })} />}

                {
                    (bannerImage || video) && !this.state.fullView &&
                    <div className="MediaHeaderBannerContainer" style={bannerContainerParams}>
                        {
                            videoId && can_show_video &&
                            <YouTube
                                videoId={videoId}
                                opts={playerOpts}
                                onEnd={() => {
                                    console.log("The Youtube video ended")
                                    onVideoEnded()
                                }}
                            />
                        }
                        {
                            !videoId && can_show_video && video &&
                            <>
                                {!isPlaying && <div className="MediaHeaderVideoPlayOverlay" onClick={() => this.videoRef.current?.play()}>
                                    <div className="translucent-overlay" />
                                    <img src={playIcon} alt="" className='play-icon' />
                                </div>}
                                <video ref={this.videoRef} controls="" autoPlay className="MediaHeaderVideo"
                                    onPause={() => this.setState({ isPlaying: false })}
                                    onPlay={() => this.setState({ isPlaying: true })}
                                    onEnded={() => {
                                        console.log("The normal video ended")
                                        onVideoEnded()
                                    }}>
                                    <source src={video} type="video/mp4" />
                                </video>
                            </>
                        }
                        <div alt={mediaHeader.item.title} className="MediaHeaderBannerObstructionOverlay" style={bannerImageParams} />
                        <div alt={mediaHeader.item.title} className="MediaHeaderBannerOverlay" style={bannerImageParams} />
                        <div alt={mediaHeader.item.title} className="MediaHeaderBannerHorizontalOverlay" style={bannerImageParams} />
                        {/* {bannerImage && !can_show_video && <img className='MediaHeaderBanner' src={bannerImage} alt="" />} */}
                    </div>
                }
                <div>
                    {
                        titleImage &&
                        <img alt={mediaHeader.item.title} className="MediaHeaderTitleImage" src={titleImage} style={this.getTitleImageParams()} />
                    }
                    {
                        !titleImage && image &&
                        <img alt={mediaHeader.item.title} className="MediaHeaderItemImage" src={image} />
                    }
                    <div className="MediaHeaderInfo">
                        {
                            !titleImage &&
                            <h3>{mediaHeader.item.title}</h3>
                        }
                        <h4 className={subtitleClassName}>{mediaHeader.item.subtitle} {playingStatus && <span>&middot;</span>} {playingStatus}</h4>
                        <p>{description?.length > DESCRIPTION_MAX_LENGTH ? `${description.substring(0, DESCRIPTION_MAX_LENGTH)}...` : description}</p>
                        <div className="MediaHeaderActions d-flex align-items-center">
                            {actions}
                        </div>
                    </div>
                </div>
            </>
        </div>

    }
}


export default withRouter(MediaHeaderView)