import React, { Component } from 'react';
import { ChatMessage } from "./models";
import PreRollAdManager from "./PreRollAds/PreRollAdManager";
import EngagementManager from "./Engagement/EngagementManager";
import { toast } from "react-toastify";
import { VannilaAlert } from "./Alerts/alertTemplates";
import Backend from './backend';

const ACTION_SWITCH_CHANNEL = "SWITCH_CHANNEL";
const ACTION_SWITCH_LCN = "SWITCH_LCN";
const ACTION_SWITCH_SOURCE = "SWITCH_SOURCE";
const ACTION_SET_TV = "SET_TV";
const ACTION_SET_OPERATOR = "SET_OPERATOR";
const ACTION_SET_OPERATOR_SUB_REMOTE = "SET_OPERATOR_SUB_REMOTE";
const ACTION_SEND_REMOTE_KEY = "SEND_REMOTE_KEY";
const ACTION_SYSTEM_INFO = "LOOKUP_SYSTEM_INFO";
const ACTION_SET_LANGUAGES = "SET_LANGUAGES";
const ACTION_DISPLAY_EPG = "DISPLAY_EPG";
const ACTION_DISPLAY_MEDIA_DETAIL = "DISPLAY_MEDIA_DETAIL";
// const ACTION_AC_CONFIG = "AC_CONFIG";
// const ACTION_AC_SUBREMOTE_CONFIG = "AC_SUBREMOTE_CONFIG";
// const ACTION_SET_AC_MODE = "SET_AC_MODE";
// const ACTION_SET_AC_SPEED = "SET_AC_SPEED";
// const ACTION_SET_AC_DIRECTION = "SET_AC_DIRECTION";
// const ACTION_INCREASE_TEMPERATURE = "INCREASE_TEMPERATURE";
// const ACTION_DECREASE_TEMPERATURE = "DECREASE_TEMPERATURE";
// const ACTION_SET_TEMPERATURE = "SET_TEMPERATURE";
const ACTION_CHATBOT_HELP = "CHATBOT_HELP";
const ACTION_SET_TV_SOURCE_INPUT = "SET_TV_SOURCE_INPUT";
const ACTION_LAUNCH_YOUTUBE_DEEPLINK = "LAUNCH_YOUTUBE_DEEPLINK";
export const ACTION_VIEW_DEEPLINK = "VIEW_DEEPLINK";
export const ACTION_VIEW_URL = "VIEW_URL";
export const ACTION_VIEW_DETAIL = "VIEW_DETAIL";
const ACTION_SEND_AC_CODE = "SEND_AC_CODE";
const ACTION_LAUNCH_SCREEN = "LAUNCH_SCREEN";
const ACTION_LAUNCH_SEARCH = "LAUNCH_SEARCH";
const ACTION_LAUNCH_APP = "LAUNCH_APP";
const INSTALL_APP = "INSTALL_APP";
const SEND_NOTIFICATION = "SEND_NOTIFICATION";
const ACTION_DO_NOTHING = "DO_NOTHING";
export const ACTION_SET_MEDIA_ITEM_FAVORITE = "SET_MEDIA_ITEM_FAVORITE"
export const ACTION_SET_MEDIA_ITEM_SEEN = "SET_MEDIA_ITEM_SEEN"
export const ACTION_SET_MEDIA_ITEM_WATCHLIST = "SET_MEDIA_ITEM_WATCHLIST"
export const ACTION_SET_MEDIA_ITEM_NOT_INTERESTED = "SET_MEDIA_ITEM_NOT_INTERESTED"
const ACTION_ADD_FAVORITE_CHANNEL = "ADD_FAVORITE_CHANNEL";
const ACTION_ADD_FAVORITE_SHOW = "ADD_FAVORITE_SHOW";
const ACTION_ADD_FAVORITE_FACET = "ADD_FAVORITE_FACET";
const ACTION_START_MONKEY = "START_MONKEY";
const ACTION_STOP_MONKEY = "STOP_MONKEY";
const ACTION_SEND_KEYCODE = "SEND_KEYCODE";
const ACTION_ALEXA_POWER_CONTROLLER = "ALEXA_POWER_CONTROLLER";
const ACTION_REBOOT_TV = "REBOOT_TV";
const SYSTEM_INFO_IP = "Network:IP";
const SYSTEM_INFO_SSID = "Network:SSID";
const ACTION_ENGAGEMENT = "START_ENGAGEMENT"
const ACTION_TV_TO_PHONE = "TV_TO_PHONE"
const SHOW_360_IMAGE = "SHOW_360_IMAGE"
const SHOW_PANORAMA = "SHOW_PANORAMA"
const ACTION_CALL_SERVER = "CALL_SERVER"

export default class SDKActionsManager {

    executeAction(chatMessage, callback, context={}) {
        chatMessage = new ChatMessage(chatMessage);
        console.log(chatMessage)
        if (!chatMessage.action_type) {
            console.log('Unsupported Action type')
        } else {
            switch (chatMessage.action_type) {
                case ACTION_DISPLAY_EPG: this.displayDetailAndSwitch(chatMessage, chatMessage.action_meta.detail, chatMessage.response, chatMessage.action_id, chatMessage.action_title)
                    break;
                case ACTION_SWITCH_CHANNEL: this.switchToChannel(chatMessage.action_id, chatMessage.action_title, chatMessage.response)
                    break;
                case ACTION_SWITCH_LCN:
                    // let send_select = false
                    // if (chatMessage.action_meta && chatMessage.action_meta.send_select) {
                    //     send_select = chatMessage.action_meta.send_select;
                    // }
                    this.sendSTBRemoteKey(chatMessage.action_id)
                    break
                case ACTION_SET_TV: //change tv config 
                    break;
                case ACTION_SET_OPERATOR: //change operator
                    break;
                case ACTION_SET_OPERATOR_SUB_REMOTE: //change sub remote
                    break;
                case ACTION_SEND_REMOTE_KEY: if (!chatMessage.isActionOnSTB()
                    && ("VOL UP".equals(chatMessage.action_id)
                        || "VOL DOWN".equals(chatMessage.action_id)
                        || "VOL MUTE".equals(chatMessage.action_id))
                ) {
                    let mute = null, increase = false;
                    if ("VOL UP".equals(chatMessage.action_id)) {
                        increase = true;
                    }
                    else if ("VOL DOWN".equals(chatMessage.action_id)) {
                        increase = false;
                    }
                    else {
                        mute = true;
                    }
                    // AudioUtil.getInstance().setTVVolume(mute, increase);
                }
                else {
                    let actionStatus
                    if (chatMessage.isActionOnTV()) {
                        actionStatus = this.sendTVRemoteKey(chatMessage.action_id);
                    } else if (chatMessage.isActionOnSTB()) {
                        actionStatus = this.sendSTBRemoteKey(chatMessage.action_id);
                    } else if (chatMessage.isActionOnAC()) {
                        actionStatus = this.sendACRemoteKey(chatMessage.action_id);
                    } else {
                        // actionStatus = this.sendSTBRemoteKey(chatMessage.action_id);
                        // this.sendTVRemoteKey(chatMessage.action_id); // FIXME: What happens to action status here?
                    }
                }
                    break;
                case ACTION_SET_LANGUAGES: if (chatMessage.action_id != null) {

                    var languages = chatMessage.action_id.split(",")
                    console.log(languages)
                    this.setPreferredLanguages(languages)
                }
                    break
                case ACTION_SYSTEM_INFO: switch (chatMessage.action_Id) {
                    case SYSTEM_INFO_IP: //show ip
                        break
                    case SYSTEM_INFO_SSID: // show ssid
                        break
                }
                    break
                case ACTION_SET_TV_SOURCE_INPUT: this.setTVInputSource(chatMessage.action_id);
                    break
                case ACTION_LAUNCH_YOUTUBE_DEEPLINK: //launch youtube
                    break
                case ACTION_VIEW_DEEPLINK: //view deeplink
                    let can_show_promo = chatMessage.action_meta ? chatMessage.action_meta.show_promo : false
                    this.viewDeeplink(chatMessage.action_id, can_show_promo)
                    break
                case ACTION_VIEW_URL:
                    let title, image, url = chatMessage.action_id
                    if (chatMessage.action_meta && chatMessage.action_meta.facet) {
                        title = chatMessage.action_meta.facet.title;
                        image = chatMessage.action_meta.facet.image;
                    }
                    this.executeActionUrl(url, title, image);
                    break;
                case ACTION_VIEW_DETAIL:
                    break;
                case ACTION_DISPLAY_MEDIA_DETAIL:
                    let media_detail = chatMessage.action_meta.media_detail
                    this.displayMediaDetail(media_detail, callback)
                    break;
                case ACTION_LAUNCH_SCREEN:
                    this.launchScreen(chatMessage)
                    break;
                case ACTION_SWITCH_SOURCE:
                    break;
                case ACTION_LAUNCH_APP: // needed?
                    break;
                case INSTALL_APP: // needed?
                    break;
                case ACTION_SET_MEDIA_ITEM_FAVORITE:{
                    let item_type, item_id, arr;
                    arr = chatMessage.action_id.split(":")
                    item_type = arr[0]
                    item_id = arr.slice(1).join(':')
                    this.changeFavoriteState(item_type, item_id, context?.is_favorite, callback);
                    break
                }
                case ACTION_SET_MEDIA_ITEM_SEEN: {
                    let toast_msg = chatMessage.response
                    let arr = chatMessage.action_id.split(":")
                    let item_type = arr[0]
                    let item_id = arr.slice(1).join(':')
                    this.changeSeenState(toast_msg, item_type, item_id, context?.is_seen, callback);
                    break
                }
                case ACTION_SET_MEDIA_ITEM_WATCHLIST: {
                    let toast_msg = chatMessage.response
                    let arr = chatMessage.action_id.split(":")
                    let item_type = arr[0]
                    let item_id = arr.slice(1).join(':')
                    this.changeWatchlistState(toast_msg, item_type, item_id, context?.is_watchlisted, callback);
                    break
                }
                case ACTION_SET_MEDIA_ITEM_NOT_INTERESTED: {
                    let toast_msg = chatMessage.response
                    let arr = chatMessage.action_id.split(":")
                    let item_type = arr[0]
                    let item_id = arr.slice(1).join(':')
                    this.changeNotInterestedState(toast_msg, item_type, item_id, context?.is_not_interested, callback);
                    break
                }
                case ACTION_ADD_FAVORITE_CHANNEL: this.createFavChannelAction();
                    break;
                case ACTION_ADD_FAVORITE_SHOW: this.createFavShowAction();
                    break
                case ACTION_ADD_FAVORITE_FACET:
                    break;
                case SEND_NOTIFICATION: this.showNotification(chatMessage);
                    break;
                case ACTION_SEND_KEYCODE: this.sendKeyEvent(chatMessage.action_id)
                    break;
                case ACTION_ALEXA_POWER_CONTROLLER:
                    break;
                case ACTION_REBOOT_TV:
                    break;
                case ACTION_ENGAGEMENT: this.showEngagementStrip(chatMessage)
                    break;
                case ACTION_TV_TO_PHONE: this.sendToPhone(chatMessage)
                    break;
                case ACTION_CALL_SERVER: this.callServer(chatMessage)
                    break;
                case SHOW_360_IMAGE:
                    let Image360 = chatMessage.action_id
                    this.show360Image(Image360)
                    break
                case SHOW_PANORAMA:
                    let panorama_identifier = chatMessage.action_id
                    this.showPanorama(panorama_identifier)
                    break
            }
        }
    }
    executeActionUrl(url, title, image) {
        console.log("Action not supported")
    }
    showNotification(chatMessage) {
        console.log("Action not supported")
    }

    changeFavoriteState(item_type, item_id, currentState, callback) {
        console.log("Action not supported")
    }

    changeSeenState(toast_msg, item_type, item_id, currentState, callback) {
        console.log("Action not supported")
    }

    changeWatchlistState(toast_msg, item_type, item_id, currentState, callback) {
        console.log("Action not supported")
    }

    changeNotInterestedState(toast_msg, item_type, item_id, currentState, callback) {
        console.log("Action not supported")
    }

    createFavShowAction() {
        console.log("Action not supported")
    }

    createFavChannelAction() {
        console.log("Action not supported")
    }

    createLaunchAppAction() {
    }

    displayMediaDetail(media_detail, callback) {
        console.log("Action not supported")
    }
    displayDetail(detail, speakText) {
        console.log("Action not supported")
    }
    displayDetailAndSwitch(chatMessage, detail, speakText, channelId, channelName) {
        console.log("Action not supported")
        toast(<div className='toast-content'>{`${chatMessage.action_type} Action is not supported`}</div>, { className: 'toast-container custom', closeButton: false, hideProgressBar: true, draggable: false, closeOnClick: false, position: toast.POSITION.BOTTOM_CENTER })
    }
    displayEPG(epg, speakText) {
        console.log("Action not supported")
    }
    displayEPGAndSwitch(epg, speakText, channelId, channelName) {
        console.log("Action not supported")
    }
    switchToChannel(channelId, channelName, speakText) {
        console.log("Action not supported")
    }
    sendChannelCode(channelCode, sendSelect) {
        console.log("Action not supported")
    }
    sendTVRemoteKey(remoteKey) {
        console.log("Action not supported")
    }
    sendSTBRemoteKey(remoteKey) {
        console.log("Action not supported")
    }
    sendKeyEvent() {
        console.log("Action not supported")
    }
    sendACRemoteKey(remoteKey) {
        console.log("Action not supported")
    }
    showTVView() {
        console.log("Action not supported")
    }
    setTVInputSource() {
        console.log("Action not supported")
    }
    setPreferredLanguages() {
        console.log("Action not supported")
    }
    viewDeeplink(url, can_show_promo) {
        // if (can_show_promo) {
        //     PreRollAdManager.showPrerollAd(url)
        // } else {
        window.location.replace(url)
        // }
    }
    showEngagementStrip(chatMessage) {
        console.log("Action not supported")
    }
    sendToPhone(chatMessage) {
        console.log("Action not supported")
    }
    show360Image(image360) {
        console.log("Action not supported")
    }
    showPanorama(panorama_identifier) {
        console.log("Action not supported")
    }
    launchScreen(chatMessage){
        console.log("Action not supported")
        toast(<VannilaAlert key={1} text={"Launch Screen action is not supported"} classNames="added-to-watched-alert" />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
    }
    callServer(chatMessage) {
        console.log(chatMessage)
        const { action_id, action_title, response: toastSuccessMessage } = chatMessage
        Backend.get().performCallServer(action_id, action_title, (error, response)=>{
            console.log(error, response)
            if (error){
                toast.error(<div className='toast-content text-center error'>An error occured while trying to report!</div>, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000, hideProgressBar: true, closeButton: false, className: 'toast-container custom' })
            }else{
                toast(<VannilaAlert key={1} text={toastSuccessMessage} classNames="added-to-watched-alert" />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
            }
        })
    }
}