import React, { Component } from 'react';

import _ from 'underscore';

import Backend from './backend'
import SearchBar from './SearchBar';

import { DPadNav } from './dpadnav'
import NavHelper from './NavHelper'

import MediaRowView from './MediaRowView'
import MediaHeaderView from './MediaHeaderView'

import { MediaDetail } from './models'
import SendToPhoneCard from './SendToPhoneCard';
import { withRouter } from 'react-router';
import loaderSVG from '../includes/images/loader.svg'

const API_DETAIL = "api/v4/epg/detail/";
const API_YOUPAGE = "api/v4/epg/reco/you";
const API_SPECIAL = "api/v4/epg/deeplinks?topic=";

const ROW_SEARCH = 0;
const ROW_ACTIONS = 1;

const VIEW_KEY = "detail";

const TRAILER_WAIT_TIME = 10000

class MediaDetailView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            "isLoading": true,
            "isSearchActive": false,
            "channelsEpisodeIndex": {},
            "navState": {
                "row": 1,
                "cols": {}
            },
            "isOverlayActive": false,
            "feedId": props.match.params.id,
            "show_send_to_phone_card": false,
            "can_show_video": false
        }

        this.backend = Backend.get();
        this.show_send_to_phone_card = this.show_send_to_phone_card.bind(this);
        this.nav = DPadNav.get();
        this.showVideoAfterInterval = this.showVideoAfterInterval.bind(this)
        this.fetchDetail = this.fetchDetail.bind(this)
    }

    componentDidMount() {
        this.nav.setNavState(this.state.navState)
        this.nav.bind(document);
        this.fetch(this.props);

        this.nav.onNavStateChanged = (navState) => {
            this.setState({
                navState: navState
            }, () => {
                this.nav.setNavState(navState)
                this.nav.scrollContent()
                this.backend.setNavState(VIEW_KEY, this.nav);
            });
        }

        Backend.getEnv().hidePip();
    }

    componentWillReceiveProps(props) {
        this.setState({
            can_show_video: false
        }, () => {
            // this.fetch(props);
            this.nav.reset();
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.itemType !== this.props.match.params.itemType || prevProps.match.params.itemId !== this.props.match.params.itemId) {
            this.fetch(this.props)
        }
    }

    fetch(props) {
        if (props.location.pathname === "/you") {
            this.fetchYouPage(props.location.search);
        } else if (props.location.pathname.indexOf("/special/") !== -1) {
            this.fetchSpecialPage(props.match.params.topic);
        } else {
            this.fetchDetail(props.match.params.itemType, props.match.params.itemId);
        }
    }

    showVideoAfterInterval() {
        setTimeout(() => {
            this.setState({
                can_show_video: true
            })
        }, TRAILER_WAIT_TIME);
    }

    fetchYouPage(locsearch) {
        console.debug("Fetching You Page for");

        var that = this;

        that.setState({
            "detail": null,
            "isLoading": true
        });

        this.backend.getJSONResultCached(API_YOUPAGE + locsearch, 1000 * 60 * 60, function (err, response) {
            if (err) {
                that.setState({
                    "error": true
                })
                return;
            }
            if (response.message) {
                that.setState({
                    "error": true,
                    "message": response.message
                })
                return;
            }
            that.setState({
                "detail": new MediaDetail(response),
                "isLoading": false
            }, () => {
                that.showVideoAfterInterval()
            });
        });
    }

    fetchSpecialPage(locsearch) {
        console.debug("Fetching You Page for" + locsearch);

        var that = this;

        that.setState({
            "detail": null,
            "isLoading": true
        });

        this.backend.getJSONResultCached(API_SPECIAL + locsearch, 1000 * 60 * 60, function (err, response) {
            if (err) {
                that.setState({
                    "error": true
                })
                return;
            }
            if (response.message) {
                that.setState({
                    "error": true,
                    "message": response.message
                })
                return;
            }
            that.setState({
                "detail": new MediaDetail(response),
                "isLoading": false
            }, () => {
                that.showVideoAfterInterval()
            });
        });
    }

    fetchDetail(itemType, itemId) {

        console.debug("Fetching Detail for " + itemType + ", " + itemId);

        var that = this;

        that.setState({
            "detail": null,
            "isLoading": true
        });

        let url = API_DETAIL + itemType + "/" + itemId
        let { location = {} } = this.props
        let urlSearchParam = new URLSearchParams();
        let { search } = location
        if (search) {
            urlSearchParam = new URLSearchParams(search.substring(1))
        }
        if (itemType === "ott" && Backend.getEnv().isEpisodesEnabledForOTT()) {
            urlSearchParam.set("episodes", "true")
        }
        url += '?' + urlSearchParam.toString()

        this.backend.getJSONResult(url, function (err, response) {
            if (err) {
                that.setState({
                    "error": true
                })
                return;
            }
            that.setState({
                "detail": new MediaDetail(response),
                "isLoading": false
            }, () => {
                that.showVideoAfterInterval()
            });
        });
    }

    show_send_to_phone_card(mediaAction) {
        console.log("Action", mediaAction);
        this.setState({
            show_send_to_phone_card: true,
            action_to_send_to_phone: { ...mediaAction }
        })
    }

    render() {
        let { can_show_video } = this.state
        let action_to_send_to_phone = this.state.action_to_send_to_phone
        if (!this.state.detail) {
            if (this.state.error) {
                return <div>
                    <span className="error">{this.state.message || "There was an error fetching the requested information. Please try again."}</span>
                    <div className="FullOpaqueOverlay"></div>
                </div>;
            } else {
                return <div>
                    <img className="loader" alt="Loading" src={loaderSVG} />
                    <div className="FullOpaqueOverlay"></div>
                </div>;
            }
        }

        var searchBar = <SearchBar nav={this.nav} onStateChanged={this.onSearchStateChanged}
            isFocused={this.nav.isFocusedRow(ROW_SEARCH)}
            focusedCol={this.nav.getColPos(0)} />;
        var counter = 1;

        if (this.state.detail.mediaHeader) {
            counter++;
        }

        const rows = this.state.detail.mediaRows.map((mediaRow, key) => {
            if (mediaRow?.mediaItems?.length > 0) {
                var hasGroups = mediaRow.mediaItemGroups && mediaRow.mediaItemGroups.length > 0;

                var rowHeaderKey = counter, rowValKey = counter;

                counter++;

                if (hasGroups) {
                    rowValKey++;
                    counter++;
                }
                var row = <MediaRowView key={key} mediaRow={mediaRow}
                    row={rowValKey} nav={this.nav}
                    rowHeaderKey={rowHeaderKey}
                    rowValKey={rowValKey}
                    isFocusedGroups={this.nav.isFocusedRow(rowHeaderKey)}
                    isFocusedDocs={this.nav.isFocusedRow(rowValKey)}
                    focusedGroupCol={this.nav.getColPos(rowHeaderKey)}
                    focusedDocsCol={this.nav.getColPos(rowValKey)}
                />;

                return row;
            }

        });

        var translateStyleY = {
            // "transform": "translateY(" + this.nav.getTranslateChannelsY() + "px)"
        }

        return <div className="MediaDetail">
            <div className="transitionAll content-padding" style={translateStyleY}>
                {!this.state.isLoading && searchBar}
                {
                    this.state.detail.mediaHeader &&
                    <MediaHeaderView isFocusedRow={this.nav.isFocusedRow(ROW_ACTIONS)}
                        focusedCol={this.nav.getColPos(ROW_ACTIONS)}
                        mediaHeader={this.state.detail.mediaHeader}
                        env={Backend.getEnv()}
                        show_send_to_phone_card={this.show_send_to_phone_card}
                        can_show_video={can_show_video}
                        onVideoEnded={() => {
                            this.setState({
                                can_show_video: false
                            })
                        }}
                    />
                }
                <div className="content-rows">
                    <div className="content-bg"></div>
                    {rows}
                </div>

            </div>
            {
                this.state.show_send_to_phone_card && <SendToPhoneCard
                    key={action_to_send_to_phone.title}
                    logo_img={action_to_send_to_phone.icon}
                    title={action_to_send_to_phone.title}
                    link={action_to_send_to_phone.chat_action.action_id}
                    close_send_to_phone_card={() => {
                        this.setState({
                            show_send_to_phone_card: false,
                        })
                    }}
                />
            }
            <NavHelper nav={this.nav} />
            <div className="FullOpaqueOverlay"></div>

        </div>
    }
}

export default withRouter(MediaDetailView)