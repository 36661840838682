import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { withRouter } from 'react-router';
import Backend from './backend';
import folksMediaLogo from '../includes/images/folksmedia_logo.png'

const LOGIN_API = "webapps/token_auth"

class AuthWall extends Component {
    constructor(props) {
        super(props)

        this.state = {
            is_loading: false,
            form_error: null,
        }
        this.backend = Backend.get();
        this.performLogin = this.performLogin.bind(this)
    }

    performLogin() {
        let data = {
            is_guest: true
        }
        this.setState({
            is_loading: true,
            form_error: null,
        })
        this.backend.postDataWithoutAuth(data, LOGIN_API, (err, response) => {
            console.log(err, response)
            if (err || !response?.body.code) {
                this.setState({
                    form_error: "Something went wrong!",
                    is_loading: false
                })
                return
            }
            this.setState({
                is_loading: false
            }, () => {
                let searchParams = new URLSearchParams(this.props.location.search)
                searchParams.set("code", response.body.code)
                this.props.history.push({
                    search: searchParams.toString()
                })
                this.props.onLoggedIn()
                // window.location.reload()
            })
        })
    }

    render() {
        let { is_loading, form_error } = this.state
        return (
            <div className='login-page'>
                <Modal animation={false} className="login-modal" centered show={true} onHide={() => { }}>
                    <Modal.Body>
                        <div className='left-section'>
                            <img className='folksmedia-logo' src={folksMediaLogo} alt=""/>
                            <h1 className="heading line-1"> All Your Streaming Services </h1>
                            <h1 className="heading line-2">in one place</h1>
                            <p>Discover & watch the best of Movies & TV Shows <br/> from across your apps</p>
        
                            <button className="primary-btn" style={{ marginBottom: "70px", marginTop: "20px" }} onClick={() => this.backend.startLogin(window.location)}>Login</button>
                            {/* <button disabled={is_loading} className='primary-btn' onClick={this.performLogin}>
                                {is_loading && <div className="spinner-border spinner-border-sm" style={{ marginRight: "7px" }}></div>
                                }
                            Checkout Demo
                        </button> <br /> */}
                            {/* <button className="primary-light-btn" style={{ marginBottom: "70px", marginTop: "20px"}} onClick={() => this.backend.startLogin(window.location)}>Have an account? Login</button> */}
                            {form_error && <div className="text-danger">
                                {form_error}
                            </div>}
                        </div>
                        <div className="right-section">
                            <div className="vods-img"/>
                        </div>
                       
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default withRouter(AuthWall)