import React, { Component } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { KEYCODES } from './utils'
import playIcon from '../includes/images/icon_play.svg'

export default class FullScreenVideo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isPlaying: false
        }
        this.videoRef = React.createRef()
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress, true)
        this.setState({
            isLoading: true
        })
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress, true)
    }

    handleKeyPress(event) {
        event.stopPropagation()
        event.preventDefault()
        if (event.keyCode === KEYCODES.RIGHT) {
            this.props.onHide()
        }

        return false;
    }
    render() {

        let { video_src, onHide } = this.props
        let { isPlaying } = this.state
        console.log("video is playing", isPlaying)

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={onHide}

            >
                <Modal.Body>

                    <div className='interstitial-video-container'>
                        {this.state.isLoading && <div className='center' style={{ width: "100%", height: "100%" }}>
                            <Spinner animation="border" variant="info" />
                        </div>}
                        <video
                            onLoadedData={() => this.setState({ isLoading: false })}
                            autoPlay
                            className='interstitial-video'
                            src={video_src}
                            onEnded={onHide}
                            onError={onHide}
                            ref={this.videoRef}
                            onPause={() => this.setState({ isPlaying: false })}
                            onPlay={() => this.setState({ isPlaying: true })}
                        />
                        {!isPlaying && <div className="full-screen-play-overlay" onClick={() => this.videoRef.current?.play()}>
                            <div className="translucent-overlay" />
                            <img src={playIcon} alt="" className='play-icon' />
                        </div>}
                    </div>
                    {!this.state.isLoading && <button className='skip-video-btn' onClick={onHide}>Skip Video</button>}
                </Modal.Body>
            </Modal>
        )
    }
}
