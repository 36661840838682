import React from 'react';

export default class VideoPlayer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            name: "60165062"|| "59807935" ,
            token: "null",
            autoPlay: false,
        }
        this.playOrder = ["webrtc", "hls"];
        this.webrtcNoStreamCallback = this.webrtcNoStreamCallback.bind(this)
        this.hlsNoStreamCallback = this.hlsNoStreamCallback.bind(this)
        this.vodNoStreamCallback = this.vodNoStreamCallback.bind(this)
        this.initializePlayer = this.initializePlayer.bind(this)
        this.startPlayer = this.startPlayer.bind(this)
        this.initializeWebRTCPlayer = this.initializeWebRTCPlayer.bind(this)
        this.init = this.init.bind(this)
        this.delayLoadingPlayer = this.delayLoadingPlayer.bind(this)
    }

    componentDidMount() {
        this.delayLoadingPlayer()
    }

    delayLoadingPlayer() {
        if (window.WebRTCAdaptor) {
            this.init()
        } else {
            console.log("dependencies not loaded")
            setTimeout(() => {
                this.delayLoadingPlayer()
            }, 3000)
        }
    }

    init() {
        console.log("init-ing player");
        
        let name = this.state.name;
        let playOrder = this.playOrder
        let playType = this.playType
        let token = this.state.token

        playType = null;
        if (playType == null || playType.equals("mp4,webm")) {
            playType = ["mp4", "webm"];
        }
        else if (playType.equals("webm,mp4")) {
            playType = ["webm", "mp4"];
        }
        else if (playType.equals("mp4")) {
            playType = ["mp4"];
        }
        else if (playType.equals("webm")) {
            playType = ["webm"];
        }

        var streamsFolder = "streams";

        if (name != "null") {

            if (name.startsWith(streamsFolder)) {
                /*
                * If name starts with streams, it's hls or mp4 file to be played
                */
                var lastIndexOfDot = name.lastIndexOf(".")
                var streamPath = name.substring(streamsFolder.length + 1, lastIndexOfDot);
                var extension = name.substring(lastIndexOfDot + 1);
                this.initializePlayer(streamPath, extension, token);
            }
            else {
                /*
                 * Check that which one is in the first order
                */
                if (this.playOrder[0] == "webrtc") {
                    this.initializeWebRTCPlayer(name, token, this.webrtcNoStreamCallback);
                }
                else if (this.playOrder[0] == "hls") {
                    window.tryToHLSPlay(name, token, this.hlsNoStreamCallback);
                }
                else if (this.playOrder[0] == "vod") {
                    window.tryToVODPlay(name, token, this.vodNoStreamCallback);
                }
                else {
                    alert("Unsupported play order requested. Supported formats are webrtc and hls. Use something like playOrder=webrtc,hls");
                }
            }
        }
        else {
            alert("No stream specified. Please add ?id={STREAM_ID}  to the url");
        }
    }

    initializePlayer(name, extension, token) {
        this.hideWebRTCElements();
        this.startPlayer(name, extension, token)
    }



    webrtcNoStreamCallback() {
        /**
     * If HLS is in the play order then try to play HLS, if not wait for WebRTC stream
     * In some cases user may want to remove HLS from the order and force to play WebRTC only
     * in these cases player only waits for WebRTC streams
     */
        let name = this.state.name
        let token = this.state.token
        setTimeout(() => {
            if (this.playOrder.includes("hls")) {
                window.tryToHLSPlay(name, token, this.hlsNoStreamCallback);
            }
            else {
                this.webRTCAdaptor.getStreamInfo(this.name);
            }
        }, 3000);
    }

    hlsNoStreamCallback() {
        let name = this.state.name
        let token = this.state.token
        document.getElementById("video_info").innerHTML = "Stream will start playing automatically<br/>when it is live";
        setTimeout(() => {
            if (this.playOrder.includes("webrtc")) {
                // It means there is no HLS stream, so try to play WebRTC stream
                if (this.webRTCAdaptor == null) {
                    this.initializeWebRTCPlayer(name, token, this.webrtcNoStreamCallback);
                }
                else {
                    this.webRTCAdaptor.getStreamInfo(name);
                }
            }
            else {
                window.tryToHLSPlay(name, token, this.hlsNoStreamCallback);
            }
        }, 3000);

    }

    vodNoStreamCallback() {
        let name = this.state.name
        let token = this.state.token
        document.getElementById("video_info").innerHTML = "Stream will start playing automatically<br/>when it is live";
        setTimeout(() => {
            if (this.playOrder.includes("vod")) {
                window.tryToVODPlay(name, token, this.vodNoStreamCallback);
            }
        }, 3000);

    }

    hideHLSElements() {
        document.getElementById("video_container").style.display = "none";
    }

    hideWebRTCElements() {
        document.getElementById("cameraDeviceRemoteVideo").style.display = "none";
        document.getElementById("play_button").style.display = "none";
    }

    playWebRTCVideo() {
        document.getElementById("cameraDeviceRemoteVideo").style.display = "block";
        document.getElementById("cameraDeviceRemoteVideo").play().then(function (value) {
            //autoplay started
            document.getElementById("play_button").style.display = "none";
        }).catch(function (error) {
            //
            document.getElementById("play_button").style.display = "block";
            console.log("User interaction needed to start playing");
        });
    }

    startPlayer(name, extension, token) {
        var type;
        var liveStream = false;
        if (extension == "mp4") {
            type = "video/mp4";
            liveStream = false;
        }
        else if (extension == "webm") {
            type = "video/webm";
            liveStream = false;
        }
        else if (extension == "m3u8") {
            type = "application/x-mpegURL";
            liveStream = true;
        }
        else {
            console.log("Unknown extension: " + extension);
            return;
        }

        var preview = name;
        if (name.endsWith("_adaptive")) {
            preview = name.substring(0, name.indexOf("_adaptive"));
        }

        var player = window.videojs('video-player', {
            poster: "previews/" + preview + ".png"
        });

        player.src({
            src: "streams/" + name + "." + extension + "?token=" + token,
            type: type,
        });

        player.poster("previews/" + preview + ".png");

        if (this.state.autoPlay) {
            player.ready(() => {
                player.play();
            });

        }
        document.getElementById("video_container").style.display = "block";
        document.getElementById("video_info").hidden = true;
    }

    initializeWebRTCPlayer(name, token, noStreamCallback) {
        this.hideHLSElements();
        var pc_config = {
            'iceServers': [{
                'urls': 'stun:stun.l.google.com:19302'
            }]
        };

        var sdpConstraints = {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true

        };
        var mediaConstraints = {
            video: false,
            audio: false
        };

        // var appName = window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/") + 1);
        // var path = window.location.hostname + ":" + window.location.port + appName + "websocket";

        var path = "demostream.sweb1.com:5080/WebRTCAppEE/websocket";
        var websocketURL = "ws://" + path;

        if (window.location.protocol.startsWith("https")) {
            websocketURL = "wss://" + path.replace("5080", "5443");
        }

        //webRTCAdaptor is a global variable
        this.webRTCAdaptor = new window.WebRTCAdaptor({
            websocket_url: websocketURL,
            mediaConstraints: mediaConstraints,
            peerconnection_config: pc_config,
            sdp_constraints: sdpConstraints,
            remoteVideoId: "cameraDeviceRemoteVideo",
            isPlayMode: true,
            debug: true,
            callback: (info, description) => {
                if (info == "initialized") {
                    console.log("initialized");
                    this.webRTCAdaptor.getStreamInfo(name);
                }
                else if (info == "streamInformation") {
                    console.log("stream information");
                    this.webRTCAdaptor.play(name, token);
                }
                else if (info == "play_started") {
                    //joined the stream
                    console.log("play started");
                    document.getElementById("video_info").style.display = "none";
                    this.playWebRTCVideo();
                } else if (info == "play_finished") {
                    //leaved the stream
                    console.log("play finished");
                    //check that publish may start again
                    setTimeout(() => {
                        this.webRTCAdaptor.getStreamInfo(name);
                    }, 3000);
                }
                else if (info == "closed") {
                    //console.log("Connection closed");
                    if (typeof description != "undefined") {
                        console.log("Connecton closed: " + JSON.stringify(description));
                    }
                }

            },
            callbackError: (error) => {
                //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

                console.log("error callback: " + JSON.stringify(error));

                if (error == "no_stream_exist") {
                    if (typeof noStreamCallback != "undefined") {
                        noStreamCallback();
                        
                    }
                    // if (this.playOrder.includes("HLS")) {

                    //     window.tryToHLSPlay(name, token, this.hlsNoStreamCallback);

                    // } else {
                    //     setTimeout(() => {
                    //         this.webRTCAdaptor.getStreamInfo(name);
                    //     }, 3000);
                    // }
                }
                if (error == "notSetRemoteDescription") {
                    /*
                    * If getting codec incompatible or remote description error, it will redirect HLS player.
                    */
                    window.tryToHLSPlay(name, token, this.hlsNoStreamCallback);

                }
            }
        });
    }




    render() {
        return (<><div id="video_info" style={{ position: "fixed", right: "0px", left: "0px", color: "white", textAlign: "center", fontSize: "30px", top: "100px" }}>
            Stream will start playing automatically<br />when it is live
	</div>

            <div id="video_container">
                <video id="video-player"
                    className="video-js vjs-default-skin vjs-big-play-centered"
                    controls preload="auto" muted>
                    <p className="vjs-no-js">
                        To view this video please enable JavaScript, and consider upgrading
				to a web browser that <a
                            href="http://videojs.com/html5-video-support/" target="_blank">supports
					HTML5 video</a>
                    </p>
                </video>
            </div>


            <video id="cameraDeviceRemoteVideo" autoPlay controls playsInline muted style={{ display: "none", width: "100vw", maxHeight: "100vh", position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px" }}></video>
            <img id="play_button" src="images/play.png" onClick={this.playWebRTCVideo}
                style={{ position: "absolute", top: "30px", left: "30px", display: "none" }} />
        </>
        )
    }
}