import React, { Component } from 'react';
import checkIcon from '../includes/images/icon_check.png'

export default class PreferenceCard extends Component {

    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.preference.toggleChecked();
        if (this.props.onClick) {
            this.props.onClick(this.props.title);
        }
    }

    render() {

        var preference = this.props.preference;

        var className = "card preferenceCard NavCard";
        if (preference.isChecked()) {
            className += " cardSelected";
        }
        if (this.props.isFocused) {
            className += " cardFocus";
        }

    return (
        <div className={className}>
            <a onClick={this.onClick}>
            {
                preference.icon_character &&
                <div className="preferenceIconChar"><img alt="" className="icon" src={preference.icon_character} /></div>
            }
            <div>{ preference.title }</div>
            {   
                preference.isChecked() && 
                <img className="icon" alt="" src={checkIcon} />
            }
            </a>
        </div>
      )
    }
}