import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class MoreChannelsLinkCard extends Component {

  render() {
    var link = "/channels";

    return (
        <div className={"card channelCard moreChannelsLinkCard NavCard " + (this.props.isFocused?" cardFocus":"")}>
          <Link to={link}>            
            <span>More Channels</span>
          </Link>
        </div>
      )
  }
}