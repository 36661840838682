import React, { Component } from 'react';

import Backend from './backend'
import SearchBar from './SearchBar';

import { Channel, OnAirItem, MediaRow, MediaDetail, LanguagePreference } from './models'
import NavHelper from './NavHelper'

import { DPadNav } from './dpadnav'

import OnAirItemView from './OnAirItemView'
import ChannelCard from './ChannelCard'
import TVInputCard from './TVInputCard'
import FacetCard from './FacetCard'
import BannerCard from './BannerCard'
import DeeplinkListView from './DeeplinkListView'
import MediaRowView from './MediaRowView'
import MoreChannelsLinkCard from './MoreChannelsLinkCard'
import loaderSVG from '../includes/images/loader.svg'
import { getBannerLink } from './utils';
import { Helmet } from 'react-helmet'

var API = "api/v3/epg/extended_on_air?format=json"

var API_PEOPLE = "api/v1/epg/facets?facet_type=person_facet_id&auto_suggest=false"

var API_DEEPLINKS = "api/v4/epg/reco/deeplinks"

var API_PROMOTIONS = "api/v4/engagement/standard_promotions?target_id=" + Backend.getEnv().getBannerZoneID()

var VIEW_KEY = "on_air";

var isHybridMode = false;

var row_banners = -1;

export default class OnAirView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            "isLoading": true,
            "isSearchActive": false,
            "channelsEpisodeIndex": {},
            "navState": {
                "row": 1,
                "cols": {}
            },
            "activeBanner": null
        }

        this.backend = Backend.get();
        this.env = Backend.getEnv();
        this.fetchData = this.fetchData.bind(this);
        this.onSearchStateChanged = this.onSearchStateChanged.bind(this);
        this.onBannerChanged = this.onBannerChanged.bind(this);
        this.goToPrevBanner = this.goToPrevBanner.bind(this)
        this.goToNextBanner = this.goToNextBanner.bind(this)

        this.nav = DPadNav.get();
    }

    componentDidMount() {

        isHybridMode = Backend.getEnv().isHybridMode();

        var that = this;
        setTimeout(function () {
            that.backend = Backend.get();
            that.backend.bannerObservable.subscribe(that.onBannerChanged);
            that.fetchData();
            if (isHybridMode) {
                Backend.getEnv().showPip();
            }
        }, 100);
        this.nav.bind(document);
        this.nav.setNavState(this.state.navState);
        this.nav.onOKPressed = null;
        this.nav.onBackPressed = null;


        this.nav.onNavStateChanged = (navState) => {
            this.setState({
                navState: navState
            }, () => {
                this.nav.setNavState(navState)
                let isBannersFocussed = this.nav.isFocusedRow(row_banners)
                let scrollTopVal = this.nav.getTranslateChannelsY(isBannersFocussed)
                console.log(scrollTopVal, isBannersFocussed)
                if (isBannersFocussed) {
                    scrollTopVal = 0
                } else {
                    scrollTopVal = null
                }
                this.nav.scrollContent(scrollTopVal)
                this.backend.setNavState(VIEW_KEY, this.nav);
            });
            // if (navState.row !== row_search) {
            //   document.querySelector(".searchBar").blur();
            // }
        }

    }

    componentWillUnmount() {
        this.backend.bannerObservable.unsubscribe(this.onBannerChanged);
    }

    goToPrevBanner(event) {
        event.preventDefault();
        event.stopPropagation();
        this.nav.navState.row = row_banners
        this.nav.decrColPos(this.nav.navState.row)
        this.nav.onNavStateChanged(this.nav.navState);
    }
    goToNextBanner(event) {
        event.preventDefault();
        event.stopPropagation();
        this.nav.navState.row = row_banners
        this.nav.incrColPos(this.nav.navState.row)
        this.nav.onNavStateChanged(this.nav.navState);
    }

    onBannerChanged(banner) {

        if (banner === null) {
            return;
        }

        var that = this;
        if (this.state.activeBanner === null || this.state.activeBanner.image !== banner.image) {
            setTimeout(function () {
                that.setState({
                    "activeBanner": banner
                });
            }, 100);
        }
    }

    fetchData() {
        var that = this;
        this.setState({
            "isLoading": true,
        })
        if (Backend.getEnv().isHybridMode()) {
            that.backend.getJSONResultCached(API, 1000 * 60 * 137, function (err, response) {
                if (response) {
                    var on_air_items = OnAirItem.fromList(response.result);
                    var episodesMediaRow = MediaRow.fromOnAirItems(on_air_items);
                    var channels = Channel.fromList(response.channels);

                    that.setState({
                        "on_air_items": on_air_items,
                        "channels": channels,
                        "episodesMediaRow": episodesMediaRow,
                        "isLoading": false

                    })
                } else {
                    that.setState({
                        "isLoading": false
                    })
                }
            });
        }
        that.backend.getJSONResultCached(API_PROMOTIONS, 1000 * 60 * 60, function (err, response) {
            if (response) {

                that.setState({
                    "promotions": response.standard_promotions,
                    "activeBanner": response.standard_promotions?.[0],
                    "isLoading": false

                })
                console.log("Promotions", response.standard_promotions)
            } else {
                that.setState({
                    "isLoading": false
                })
            }
        });
        let languages = LanguagePreference.getLanguagesConfig() || "english"
        var deeplinksApi = API_DEEPLINKS + "?languages=" + languages;
        that.backend.getJSONResultCached(deeplinksApi, 1000 * 60 * 20, function (err, response) {
            if (response) {
                that.setState({
                    "deeplinks": new MediaDetail(response),
                });
            }
        });

    }

    onSearchStateChanged(state) {
        this.setState({
            "isSearchActive": state
        })
    }

    showSearchBar() {
        let searchBarContainer = document.querySelector(".searchbar-container")
        searchBarContainer.classList.add("is-active")
    }
    hideSearchBar() {
        let searchBarContainer = document.querySelector(".searchbar-container")
        searchBarContainer.classList.remove("is-active")
    }


    render() {
        var row_search = -1;
        var row_episodes = -1;
        var row_channels = -1;
        var row_tv_inputs = -1;
        var row_facets = -1;

        var overlayRowBound = 2;
        if (isHybridMode) {
            overlayRowBound = 3;
        }

        var counter = 0;
        row_search = counter;
        var searchBar = <SearchBar nav={this.nav} onStateChanged={this.onSearchStateChanged}
            isFocused={this.nav.isFocusedRow(row_search)}
            focusedCol={this.nav.getColPos(counter)}
            isHybridMode={isHybridMode} />;
        counter++

        var bannerRowItems = [];
        if (this.state.promotions) {
            bannerRowItems = this.state.promotions.map((promotion, index) => {
                let link = getBannerLink(promotion)
                return <BannerCard
                    key={index}
                    promotion={promotion}
                    isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(counter) === index}
                    backend={this.backend}
                    link={link}
                    onBannerChanged={this.onBannerChanged}
                />
            })
        }

        if (bannerRowItems.length > 0) {
            row_banners = counter;
            counter++;
        }

        var items = [];
        var i, j;
        if (!this.state.isSearchActive && this.state.episodesMediaRow) {
            items.push(<MediaRowView key={-1} mediaRow={this.state.episodesMediaRow}
                row={counter + 1} nav={this.nav}
                rowHeaderKey={counter}
                rowValKey={counter + 1}
                isFocusedGroups={this.nav.isFocusedRow(counter)}
                isFocusedDocs={this.nav.isFocusedRow(counter + 1)}
                focusedGroupCol={this.nav.getColPos(counter)}
                focusedDocsCol={this.nav.getColPos(counter + 1)}
            />);

            counter += 2;
        }

        var channel_items = [];
        if (!this.state.isSearchActive && this.state.channels && this.state.channels.length > 0) {
            row_channels = counter;
            for (i = 0; i < this.state.channels.length; i++) {
                var channel = this.state.channels[i];
                var episode = this.state.channelsEpisodeIndex[channel.id];
                channel_items.push(<ChannelCard key={channel.id} channel={channel} episode={episode}
                    isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(counter) === i} />);
                if (channel_items.length === 8) {
                    break; // REMOVE THIS
                }
            }
            counter++;
        }

        var tv_input_items = [];
        if (!this.state.isSearchActive && this.state.tv_inputs && this.state.tv_inputs.length > 0) {
            row_tv_inputs = counter;
            for (i = 0; i < this.state.tv_inputs.length; i++) {
                var tv_input = this.state.tv_inputs[i];
                tv_input_items.push(<TVInputCard key={tv_input.id} tv_input={tv_input}
                    isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(counter) === i} />);
            }
            counter++;
        }

        var facet_items = [];
        if (!this.state.isSearchActive && this.state.facets && this.state.facets.length > 0) {
            row_facets = counter;
            for (i = 0; i < this.state.facets.length; i++) {
                var facet = this.state.facets[i];
                facet_items.push(<FacetCard key={facet.id} facet={facet}
                    isFocused={this.nav.isFocusedRow(counter) && this.nav.getColPos(counter) === i} />);
            }
            counter++;
        }

        var deeplink_rows = [];

        if (this.state.deeplinks && this.state.deeplinks.mediaRows) {
            deeplink_rows = this.state.deeplinks.mediaRows.map((mediaRow, key) => {

                var hasGroups = mediaRow.mediaItemGroups && mediaRow.mediaItemGroups.length > 0;

                var rowHeaderKey = counter, rowValKey = counter;

                counter++;

                if (hasGroups) {
                    rowValKey++;
                    counter++;
                }

                var row = <MediaRowView key={key + 100} mediaRow={mediaRow}
                    row={rowValKey} nav={this.nav}
                    rowHeaderKey={rowHeaderKey}
                    rowValKey={rowValKey}
                    isFocusedGroups={this.nav.isFocusedRow(rowHeaderKey)}
                    isFocusedDocs={this.nav.isFocusedRow(rowValKey)}
                    focusedGroupCol={this.nav.getColPos(rowHeaderKey)}
                    focusedDocsCol={this.nav.getColPos(rowValKey)}
                />;
                return row;
            });
        }

        var translateStyleY = {
            // "transform": "translateY(" + this.nav.getTranslateChannelsY((this.nav.isFocusedRow(row_banners))) + "px)"
            zIndex: -5,
        }

        var bannerProps = {};
        if (this.state.activeBanner) {
            bannerProps = {
                backgroundImage: 'url(' + this.state.activeBanner.image + ')'
            }
        }
        let bannerNavControls = <div className="banner-nav-controls">
            <div className="prev-banner" onClick={this.goToPrevBanner}></div>
            <div className="next-banner" onClick={this.goToNextBanner}></div>
        </div>
        let activeBannerWithLink = null
        let onAirBannersWithLink = <div className="OnAirBanners" onMouseEnter={this.showSearchBar} onMouseLeave={this.hideSearchBar}>{bannerNavControls}</div>
        if (this.state.activeBanner) {
            activeBannerWithLink = <div className="CurrentBanner" onMouseEnter={this.showSearchBar} onMouseLeave={this.hideSearchBar} style={bannerProps}>
                {bannerNavControls}
                <div className="CurrentBannerMeta">
                    <div className="CurrentBannerTitle">{this.state.activeBanner.alt}</div>
                    <div className="CurrentBannerSubTitle">{this.state.activeBanner.bannertext}</div>
                    <div className="CurrentBannerDescription">{this.state.activeBanner.statustext}</div>
                </div>
                <div className="CurrentBannerOverlay"></div>
                {/* <div className="CurrentBannerOverlayBottom"></div> */}
            </div>
            activeBannerWithLink = getBannerLink(this.state.activeBanner, activeBannerWithLink, "opaque")
            onAirBannersWithLink = getBannerLink(this.state.activeBanner, onAirBannersWithLink, "opaque")
        }

        return (
            <div className="WebNav">
                <Helmet>
                    <title>{this.env.getProductName()}</title>
                </Helmet>
                {
                    this.state.isLoading &&
                    <img className="loader" alt="Loading" src={loaderSVG} />
                }
                <div className="transitionAll">
                    {!this.state.isLoading && onAirBannersWithLink}
                    {
                        this.state.activeBanner && activeBannerWithLink
                    }
                </div>
                <div className="transitionAll content-padding" style={translateStyleY}>
                    {!this.state.isLoading && <div className={"searchbar-container content-padding-x" + (this.nav.isFocusedRow(row_search) ? " is-active" : "")}>
                        {searchBar}
                    </div>}
                    {
                        bannerRowItems.length > 0 &&
                        <div className={"bannerRow NavRow " + (this.nav.isFocusedRow(row_banners) ? "activeRow" : "inactiveRow")}>
                            <div className="list-container" style={this.nav.getTranslateXStyle(row_banners)}>
                                {bannerRowItems}
                            </div>
                            <div className="clearBoth" />
                        </div>
                    }
                    <div className="content-rows">
                        <div className="content-bg"></div>

                        {items}
                        {
                            channel_items.length > 0 &&
                            <div className={"channelGroup NavRow " + (this.nav.isFocusedRow(row_channels) ? "activeRow" : "inactiveRow")}>
                                <div className="cardTitle">Channels</div>
                                <div className="list-container" style={this.nav.getTranslateXStyle(row_channels)}>
                                    {channel_items}
                                    <MoreChannelsLinkCard isFocused={this.nav.isFocusedRow(row_channels) && this.nav.getColPos(row_channels) === 8} />
                                </div>
                                <div className="clearBoth" />
                            </div>
                        }
                        {
                            tv_input_items.length > 0 &&
                            <div className={"TVInputGroup NavRow " + (this.nav.isFocusedRow(row_tv_inputs) ? "activeRow" : "inactiveRow")}>
                                <div className="cardTitle">TV Inputs</div>
                                <div className="list-container" style={this.nav.getTranslateXStyle(row_tv_inputs)}>
                                    {tv_input_items}
                                </div>
                                <div className="clearBoth" />
                            </div>
                        }
                        {
                            facet_items.length > 0 &&
                            <div className={"FacetGroup NavRow " + (this.nav.isFocusedRow(row_facets) ? "activeRow" : "inactiveRow")}>
                                <div className="cardTitle">People</div>
                                <div className="list-container" style={this.nav.getTranslateXStyle(row_facets)}>
                                    {facet_items}
                                </div>
                                <div className="clearBoth" />
                            </div>
                        }
                        {deeplink_rows}
                    </div>

                </div>

                {
                    this.nav.isFocusedRowBelow(overlayRowBound) && !this.state.activeBanner &&
                    <div className="HeaderOverlay"></div>
                }
                {
                    this.nav.isFocusedRowBelow(overlayRowBound + (this.state.activeBanner ? 2 : 0)) &&
                    <div className="BodyOverlay"></div>
                }
                {
                    !this.nav.isFocusedRowBelow(overlayRowBound + (this.state.activeBanner ? 2 : 0)) &&
                    <div className="FullOpaqueOverlay"></div>
                }

                <NavHelper nav={this.nav} />
            </div>
        );
    }
}