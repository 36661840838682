import React, { Component } from 'react';

export default class SubNavOptionCard extends Component {

    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick(this.props.title);
        }
    }

    render() {

        var className = "card subNavOptionCard NavCard ";
        if (this.props.isSelected) {
            className += " cardSelected";
        }
        if (this.props.isFocused) {
            className += " cardFocus";
        }

    return (
        <div className={className}>
            { this.props.title }
        </div>
      )
    }
}