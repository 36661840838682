import React, { Component } from 'react';

import EpisodeCard from './EpisodeCard'

export default class OnAirItemView extends Component {

  render() {

    var item = this.props.item;

    var cards = [];
    var episodes = item.epg.episodes;
    for (var i=0; i < episodes.length; i++) {
        var episode = episodes[i];
        cards.push(<EpisodeCard isFocused={this.props.isFocusedRow && this.props.focusedCol===i}
            key={episode.id} episode={episode} />);
    }

    return (
        <div className={"channelGroup NavRow " + (this.props.isFocusedRow?"activeRow":"inactiveRow")}>
            <div className="cardTitle">{ item.displayable }</div>
            <div className="list-container" style={this.props.nav.getTranslateXStyle(this.props.row)}>
                {cards}
            </div>
            <div className="clearBoth" />
        </div>
      );
  }
}

