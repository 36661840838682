import React, { Component } from 'react'
import Spinner from './Spinner'
import Backend from '../backend'
import { toast } from 'react-toastify'
import { Flip } from 'react-toastify';
const OTP_LENGTH = 6

export default class CloudRemotePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            otp: '',
            is_posting: false
        }
        this.backend = Backend.get()
        this.onOTPInput = this.onOTPInput.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.removeNotValidOutline = this.removeNotValidOutline.bind(this)
        this.removeCredData = this.removeCredData.bind(this)
        this.formRef = null

    }

    onOTPInput(event) {
        this.setState({
            otp: event.target.value.replace(/[^\d]/g, "").slice(0, OTP_LENGTH)
        })
    }

    removeNotValidOutline() {
        let form = this.formRef
        form.classList.remove('was-validated');
    }

    removeCredData(){
        this.backend.removeCloudRemoteUserData()
    }

    onSubmit(event) {
        if (event) {
            event.preventDefault()
            event.stopPropagation();
            console.log(event)
        }

        let form = this.formRef
        form.classList.remove('was-validated');
        this.setState({
            is_posting: true
        })

        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            this.setState({
                is_posting: false
            })
            return;
        }
        this.backend.getTokenForOTP(this.state.otp, (err, response) => {
            console.log(err, response)
            if (err) {
                let toast_msg = err || err.message
                toast.error(<div className='toast-content text-center error'>{toast_msg}</div>, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000, hideProgressBar: true, closeButton: false, className: 'toast-container custom', transition: Flip})
                this.setState({
                    is_posting: false
                })
            } else {
                let { token, device_id } = response
                this.backend.setAsCloudRemoteUser()
                this.backend.completeLogin(token, device_id)
                toast.success(<div className='toast-content success'>{"Success! Redirecting..."}</div>, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000, hideProgressBar: true, closeButton: false, className: 'toast-container custom', transition: Flip })
                setTimeout(()=>{
                    this.props.history.push({
                        pathname: '/channels',
                    })
                }, 2000)
                this.setState({
                    is_posting: false
                })
            }
        })
    }

    render() {
        return (
            <div className='w-100 h-100 cloud-remote-page'>
                <div className="cloud-remote-page-content">
                    <div className='m-b-10'>
                        <img alt="Sensy" className="logo v-middle m-r-10" src="https://www.sensyremote.com/images/logo.png" />
                        <h2 className='d-inline-block m-0 bold v-middle'>Connect to Cloud Remote</h2>
                    </div>
                    <div>
                        <h3 className='d-inline-block m-0'>Enter your OTP</h3>
                    </div>
                    <div>
                        <form className='d-flex flex-column align-items-center justify-content-center' ref={ref => this.formRef = ref} noValidate onSubmit={this.onSubmit}>
                            <input size={OTP_LENGTH} type="text" className="form-control otp-input" required value={this.state.otp} onFocus={this.removeNotValidOutline} onChange={this.onOTPInput} />
                            {!this.state.is_posting && <button type='submit' className='btn btn-light'>Submit</button>}
                            {this.state.is_posting && <Spinner classNames='text-center' />}
                        </form>
                    </div>
                </div>
                <div className='danger-btn text-center c-pointer' onClick={this.removeCredData}>
                    Remove all data
                </div>
            </div>
        )
    }
}
