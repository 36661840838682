import request from 'superagent';
import Logger from './Logger'

import TizenEnv from "./TizenEnv"
// import CoocaaLiteEnv from "./CoocaaLiteEnv"
import WebOSEnv from "./WebOSEnv"
import CloudDemoEnv from "./CloudDemoEnv"
import Observable from "./Observable"


import { OnAirItem, ChannelGroup, TVProviderPreference, UniqueID } from './models'
import OTTDemoEnv from './OTTDemoEnv';
import USDemoEnv from './USDemoEnv';
import PnpDemoEnv from './PnpDemoEnv';

const API_BASE = process.env.REACT_APP_API_BASE || "https://sensara.co/";
// var API_BASE = "http://dev.ext.sensalore.com:8484/";
// var API_BASE = "https://airtel.sensara.co/";
var API_ACCOUNT_CREATE = "api/v2/authenticate";
var API_SETUP_FCM = "api/v1/gcm";
var API_ONAIR = "api/v1/epg/on_air";
var API_CHANNELS = "api/v2/user/channels?type=top"
var API_LANGUAGES = "api/v2/user/languages"
var API_BASE_LCN = "api/v1/epg/providers/"
var API_SWITCH = "api/v1/user/switch"
var API_WEB_SWITCH = "api/v2/chatbot/push_test?user_id=51312796&channel_id="
var API_WEB_SWITCH_WITHOUT_USER = "api/v2/chatbot/push_test?via=demo&channel_id="
var API_WEB_REMOTE_KEY = "api/v2/chatbot/push_test?user_id=51312796&remote_key="
const API_CALL_SERVER = "api/v4/engagement/call_server"
var BANNER_API_BASE = "https://sweb1.com/partner_default/banners?zoneid=";
var CSRF_TOKEN = null
const WEBAPPS_LOGIN = API_BASE + 'webapps/login'
const API_WEBAPPS_TOKEN_FOR_CODE = 'webapps/token'
var backend = null;
var env = null;
var env_headers = {}

class HTTPResponseCacheItem {
  constructor(key, err, response, expires) {
    this.key = key;
    this.err = err;
    this.response = response;
    this.expires = expires;
    this.ts = new Date();
  }
}

class HTTPResponseCache {

  constructor() {
    this.cache = {};
  }

  get(key) {
    var result = this.cache[key];

    if (process.env.NODE_ENV === 'development') {
      result = null;
    }

    if (!result) {
      return;
    }

    var now = new Date();
    if (now - result.ts.getTime() > result.expires) {
      return;
    }

    return result;
  }

  set(key, err, response, expires) {
    var cacheObject = new HTTPResponseCacheItem(key, err, response, expires);
    this.cache[key] = cacheObject;
  }

  clear(key) {
    this.cache[key] = null;
  }

}

export default class Backend {

  constructor(props) {

    this.cache = new HTTPResponseCache();
    this.navStateCache = {};

    this.postForJSONResult = this.postForJSONResult.bind(this);
    this.getJSONResult = this.getJSONResult.bind(this);
    this.getJSONResultWithoutAuth = this.getJSONResultWithoutAuth.bind(this);
    this.getJSONResultCached = this.getJSONResultCached.bind(this);
    this.getChannelGroups = this.getChannelGroups.bind(this);
    this.fetchLCN = this.fetchLCN.bind(this);
    this.getNavState = this.getNavState.bind(this);
    this.setNavState = this.setNavState.bind(this);
    this.setActiveEpisodes = this.setActiveEpisodes.bind(this);
    this.setLanguages = this.setLanguages.bind(this);
    this.setAsCloudRemoteUser = this.setAsCloudRemoteUser.bind(this)

    this.lcn = null;
    //merge these two;;
    this.legitChNums = [];
    this.chNumsToChIds = null;

    this.bannerObservable = new Observable();
  }

  static get() {
    if (backend === null) {
      backend = new Backend();
      if (Backend.getEnv().isHybridMode()){
        backend.fetchLCN();
      }
      backend.initCSRFToken();
      return backend
    }
    return backend
  }

  static getEnv() {

    if (env !== null) {
      return env;
    }
    let build_type = Backend.getBuildType()

    if (window.tizen || build_type === 'tizen') {
      env = new TizenEnv();
    } else if (window.navigator.userAgent.toLowerCase().indexOf("webos") >= 0 || build_type === 'webOS') {
      env = new WebOSEnv();
      // } else if (build_type === 'CoocaaLite') {
      //   env = new CoocaaLiteEnv();
    } else if (build_type === 'cloudDemo') {
      console.debug('setting build type to Hybrid')
      env = new CloudDemoEnv();
      env_headers = env.getHeaders()
    } else if (build_type === 'ottDemo') {
      console.debug('setting build type to OTT')
      env = new OTTDemoEnv();
      env_headers = env.getHeaders()
    } else if (build_type === 'usDemo') {
      console.debug('setting build type to USOTT')
      env = new USDemoEnv();
      env_headers = env.getHeaders()
    } else if(build_type === 'pnpDemo') {
      console.debug('setting build type to USOTT')
      env = new PnpDemoEnv();
      env_headers = env.getHeaders()
    }

    return env;
  }

  static getBuildType() {
    return process.env.REACT_APP_BUILD_TYPE || 'cloudDemo'
  }

  static getDeployedBasePath() {
    return process.env.REACT_APP_HOMEPAGE || '/'
  }

  setNavState(key, nav) {
    this.navStateCache[key] = nav;
  }

  clearNavState(key) {
    this.navStateCache[key] = null;
  }

  getNavState(key) {
    return this.navStateCache[key];
  }

  setActiveEpisodes(episodeList, title) {
    this.activeEpisodes = OnAirItem.fromEpisodeList(episodeList.slice(0, 15), title);
  }

  getActiveEpisodes() {
    return this.activeEpisodes;
  }

  setAsCloudRemoteUser() {
    window.localStorage.setItem('isCloudRemoteUser', '1')
  }

  removeCloudRemoteUserData() {
    window.localStorage.removeItem('isCloudRemoteUser')
    window.localStorage.removeItem('authToken')
    window.localStorage.removeItem('device_id')
    window.location.reload()
  }

  shouldPerformSwitchOnCloudRemote() {
    return window.localStorage.getItem('isCloudRemoteUser') === '1'
  }

  performWebSwitch(channelId) {


    if (this.shouldPerformSwitchOnCloudRemote()) {
      this.getJSONResult(API_WEB_SWITCH_WITHOUT_USER + channelId, function (err, response) {
        console.debug(err, response);
      });

    } else {
      this.postForJSONResult(API_SWITCH, { "channel_id": channelId }, function (err, response) {
        console.debug("Logged switch", err, response);
      })

      this.getJSONResult(API_WEB_SWITCH + channelId, function (err, response) {
        console.debug(err, response);
      });
    }

  }

  performWebRemoteKey(key) {
    this.getJSONResult(API_WEB_REMOTE_KEY + key, function (err, response) {
      console.debug(err, response);
    });
  }

  ensureUserAccount() {
    console.debug("Ensuring user account");

    if (this.isLoggedIn()) {
      return;
    }

    this.startLogin();
  }

  isLoggedIn() {
    let token = this.getToken()
    let device_id = localStorage.getItem('device_id') // if there is no device_id fcm setup will fail
    return ((typeof (token) !== "undefined") && (token !== 'null') && device_id);
  }


  startLogin(next) {
    console.log("Setting login Next: " + next);
    // if (API_BASE !== '/' && (window.location.hostname !== new URL(API_BASE).hostname || window.location.port !== new URL(API_BASE).port)) {
      window.location.replace(WEBAPPS_LOGIN + '?next=' + encodeURIComponent(next));
    // } else {
      // window.location.replace(WEBAPPS_LOGIN);
    // }
  }

  postDataWithoutAuth(data, url, callback) {
    request
      .post(API_BASE + url)
      .send(data)
      .set('Accept', 'application/json')
      .set(env_headers)
      .set('X-CSRFToken', CSRF_TOKEN)
      .end(function (err, res) {
        callback(err, res);
      });
  }

  completeLogin(callback) {
    let curr_url = new URL(window.location)
    let code = curr_url.searchParams.get('code')
    let data = {
      code
    }
    this.postDataWithoutAuth(data, API_WEBAPPS_TOKEN_FOR_CODE, (err, response) => {
      if (err) {
        console.log("An error occured getting auth cred", err)
      } else {
        console.log(response)
        let { token, device_id, api_key } = response.body
        localStorage.setItem('authToken', token)
        localStorage.setItem('device_id', device_id)
        localStorage.setItem('api_key', api_key)
        curr_url.searchParams.delete('code')
        window.location = curr_url
        callback()
      }
    }, false)
  }


  logout() {
    window.localStorage.removeItem('authToken');
    window.location.replace('/insights');
  }

  getToken() {
    return window.localStorage.authToken;
  }

  initCSRFToken() {
    CSRF_TOKEN = this.getCookie('csrftoken')
    if (!CSRF_TOKEN) {
      console.log("Fetching CSRF token");
      request
        .get(API_BASE + "api/v1/epg/curation/token")
        .end(function (err, res) {
          if (!err) {
            CSRF_TOKEN = res.text
            let event = new CustomEvent("csrf-token-available");
            document.dispatchEvent(event);
          } else {
            console.log("Cound not get CSRF Token", err);
          }
        });
    }
    let event = new CustomEvent("csrf-token-available");
    document.dispatchEvent(event);
  }

  getCookie(name) {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
      let [k, v] = el.split('=');
      cookie[k.trim()] = v;
    })
    return cookie[name];
  }

  getTokenForOTP(otp, callback) {
    request
      .get(API_BASE + "api/v2/chatbot/resolve_token")
      .query({ "otp": otp })
      .set('Authorization', 'Token ' + this.getToken())
      .set('Accept', 'application/json')
      .set(env_headers)
      .end(function (err, res) {
        console.log(res, res?.body, res?.text)
        if (!err) {
          callback(err, res.body);
        } else {
          if (res) {
            var response = JSON.parse(res.text);
            callback(err, response.message);
          } else {
            callback("Failed", response)
          }
        }
      });
  }

  // isLoggedIn() {
  //   let token = this.getToken()
  //   return (typeof (token) !== "undefined" && token !== 'null');
  // }

  // startLogin(callback) {
  //   console.debug("CLO Start login");

  //   var data = {
  //     label: UniqueID.generateUUID(),
  //     device_id: UniqueID.getDeviceID(),
  //     device_name: "CloudDemo",
  //     password: UniqueID.generateUUID()
  //   }
  //   request
  //     .post(API_BASE + API_ACCOUNT_CREATE)
  //     .type('form')
  //     .send(data)
  //     .set('Accept', 'application/json')
  //     .set(env_headers)
  //     .end(function (err, res) {
  //       if (!err) {
  //         var response = JSON.parse(res.text);
  //         if (response.token) {
  //           console.debug(response);
  //           window.localStorage.cdv2AuthToken = response.token;
  //           console.debug("CLO Completing login. Reloading");
  //           window.location.reload();
  //         } else {
  //           alert("Login error. Please try again");
  //         }
  //         callback(response)
  //       } else {
  //         alert("Login error. Please try again");
  //       }
  //     })
  // }

  postFCMToken(fcm_token, callback) {

    var data = {
      device_id: localStorage.getItem('device_id'),
      token: fcm_token,
    }
    this.postForJSONResult(API_SETUP_FCM, data, (err, response) => {
      callback(err, response)
    })
  }

  // getToken() {
  //   return window.localStorage.cdv2AuthToken;
  // }

  fetchLCN() {
    console.debug("Fetching LCN");
    var that = this;
    var provider = TVProviderPreference.getProvider();
    var API = API_BASE_LCN + provider;
    this.getJSONResult(API, function (err, response) {
      if (err) {
        console.debug("Error fetching LCN", err);
      } else {
        console.debug("Fetched " + provider);
      }
      if (response) {
        that.lcn = {};
        that.chNumsToChIds = {};
        for (var lcn in response.channels) {
          var channel_id = response.channels[lcn].id;
          that.lcn[channel_id] = lcn;
          that.legitChNums.push(lcn);
          that.chNumsToChIds[lcn] = channel_id;
        }
      }
    });
  }

  getAuthTokenHeader() {
    let token = this.getToken()
    if(token){
      return `Token ${token}`;
    }
  }

  getJSONResult(path, callback) {
    let r = request
      .get(API_BASE + path)
      .set('Accept', 'application/json')

    if (this.getAuthTokenHeader()) {
      r = r.set('Authorization', this.getAuthTokenHeader())
    }

    r.set(env_headers)
      .end(function (err, res) {
        if (!err) {
          var response = JSON.parse(res.text);
          callback(err, response);
        } else {
          callback(err, res);
        }
      });
  }

  getJSONResultAsync(path) {
    return new Promise((resolve, reject)=>{
      this.getJSONResult(path, (err, res)=>{
        if(err){
          let error = new Error(err)
          error.response = res
          reject(error)
        } 
        resolve(res)
      })
    })
  }


  getJSONResultWithoutAuth(path, callback) {
    request
      .get(API_BASE + path)
      .set('Accept', 'application/json')
      .set(env_headers)
      .end(function (err, res) {
        if (!err) {
          var response = JSON.parse(res.text);
          callback(err, response);
        } else {
          callback(err, res);
        }
      });
  }

  postForJSONResult(path, data, callback) {
    request
      .post(API_BASE + path)
      .type('form')
      .send(data)
      .set('X-CSRFToken', CSRF_TOKEN)
      .set('Accept', 'application/json')
      .set('Authorization', this.getAuthTokenHeader())
      .set(env_headers)
      .end(function (err, res) {
        if (!err) {
          callback(err, res.body);
        } else {
          callback(err, res);
        }
      });
  }

  deleteForJSONResult(path, data, callback){
    request
      .delete(API_BASE + path)
      .type('form')
      .send(data)
      .set('X-CSRFToken', CSRF_TOKEN)
      .set('Accept', 'application/json')
      .set('Authorization', this.getAuthTokenHeader())
      .set(env_headers)
      .end(function (err, res) {
        if (!err) {
          callback(err, res.body);
        } else {
          callback(err, res);
        }
      });
  }

  deleteForJSONResultAsync(path, data) {
    return new Promise((resolve, reject)=>{
      this.deleteForJSONResult(path, data, (err, res)=>{
        if (err) {
          let error = new Error(err)
          error.response = res
          reject(error)
        }
        resolve(res)
      })
    })
   
  }

  postData(path, data, callback) {
    request
      .post(API_BASE + path)
      .send(data)
      .set('Accept', 'application/json')
      .set('Authorization', this.getAuthTokenHeader())
      .set(env_headers)
      .end(function (err, res) {
        if (!err) {
          var response = JSON.parse(res.text);
          callback(err, response);
        } else {
          callback(err, res);
        }
      });
  }

  getJSONResultCached(path, expires, callback) {

    var result = this.cache.get(path);

    if (result) {
      console.debug("[HIT]", path, result);
      callback(result.err, result.response);
      return;
    }

    console.debug("[MISS]", path);

    var that = this;
    this.getJSONResult(path, function (err, response) {
      if (!err) {
        that.cache.set(path, err, response, expires);
      }
      callback(err, response);
    });
  }

  sendPushMessageWithoutAuth(data, callback) {
    request
      .post(API_BASE + "api/v2/chatbot/push_message_unprotected")
      .send(data)
      .set('Accept', 'application/json')
      .set(env_headers)
      .end(function (err, res) {
        var response = res.text;
        if (!err) {
          console.log("Send Push Message Successful");
          callback(err, response);
        } else {
          console.log("Send Push Message Failed");
          callback(err, response);
        }
      });
  }

  getBanner(zoneId, callback) {
    request
      .get(BANNER_API_BASE + zoneId)
      .set('Accept', 'application/json')
      .end(function (err, res) {
        if (!err) {
          var response = JSON.parse(res.text);
          callback(err, response);
        } else {
          callback(err, res);
        }
      });
  }

  getChannelGroups(callback) {
    this.getJSONResultCached(API_CHANNELS, 1000 * 60 * 5, function (err, response) {

      var channel_groups = ChannelGroup.fromList(response);
      var indexed_groups = ChannelGroup.getIndex(channel_groups);

      if (callback) {
        callback(channel_groups, indexed_groups);
      }

    });
  }

  setLanguages(config) {
    var that = this;
    this.postForJSONResult(API_LANGUAGES, { "languages": config }, function (err, response) {
      console.debug("[SET Languages]", response);
      that.cache.clear(API_ONAIR);
    });
  }

  performCallServer(action_id, action_title, callback) {
    this.postForJSONResult(API_CALL_SERVER, { action_id, action_title }, callback);
  }
}


export {
  CSRF_TOKEN
}