import React, { Component } from 'react';

export default class CardMeta extends Component {

  render() {
    return (
        <div className="cardMeta">
          <div className="cardMetaTitle">{this.props.title}</div>
          <div className="cardMetaSubTitle">{this.props.subTitle}</div>
        </div>
      )
  }
}