import React, { Component } from 'react';

import CenterCropImage from './CenterCropImage';
import CardMeta from './CardMeta';
import moment from 'moment';
import { truncate } from 'underscore.string';
import { Link } from 'react-router-dom';

export default class TimelineItemCard extends Component {

  render() {
    var feed_item = this.props.item;
    var title = feed_item.title;
    var to = "/detail/" + feed_item.item_type + "/" + feed_item.item_id;

    var subTitle = "";
    if (feed_item.timestamp && feed_item.timestamp > 0) {
      subTitle = moment(feed_item.timestamp * 1000).fromNow();
    }

    return (
        <div className={"card feed_itemCard NavCard " + (this.props.isFocused?"cardFocus":"")}>
          <Link to={to}>
            <CenterCropImage src={feed_item.image} width={225} alt={feed_item.title} />
            <CardMeta title={truncate(title, 13)} subTitle={subTitle} />
          </Link>
          <div className="cardOverlay" />
        </div>
      )
  }
}