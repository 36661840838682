import BaseEnv from './BaseEnv'
import Backend from './backend'
import DemoActionsManager from './DemoActionsManager';


export default class CloudDemoEnv extends BaseEnv {

    constructor(props) {
        super(props)

        console.debug("CloudDemoEnv called");

        this.switchChannel = this.switchChannel.bind(this);
        this.showPip = this.showPip.bind(this);
        this.hidePip = this.hidePip.bind(this);
        this.hideOverlay = this.hideOverlay.bind(this);
        this.isPipShown = false;
        this.actionsManager = new DemoActionsManager(this);
        this.backend = Backend.get();
    }

    isBackgroundPosterEnabled() {
      return false;
    }

    switchChannel(channelId) {
      console.debug("Need to switch to channel ID: " + channelId);
      this.backend.performWebSwitch(channelId);
    //   window.location.hash = "/tv/" + channelId;
        let event = new CustomEvent("perform-route-change", { detail: { path: "/tv/" + channelId, data: {}}});
        document.dispatchEvent(event);

    }

    switchToSource(source) {
    }

    sendRemoteKey(key) {
        key = key.toString();
        this.backend.performWebRemoteKey(key);
    }

    showDeeplink(deeplink) {

        // console.debug("Clicked deeplink", deeplink);

        // var split_url = deeplink.split("/");
        // var hostname = split_url[2];

        // var appId = null;
        // var contentTarget = null;
        // if (hostname.endsWith(".netflix.com")) {
        //     appId = "netflix";
        //     var contentId = null;
        //     if (split_url.indexOf("title") >= 0) {
        //         contentId = split_url[split_url.indexOf("title") + 1];
        //     }
        //     if (contentId === null && split_url.indexOf("watch") >= 0) {
        //         contentId = split_url[split_url.indexOf("watch") + 1];
        //     }
        //     if (contentId !== null && !isNaN(contentId)) {
        //         contentTarget = "-Q m=http://api-global.netflix.com/catalog/titles/movies/" + contentId;
        //     }
        // } else if (deeplink.startsWith("#/youtube/")) {
        //     appId = "youtube.leanback.v4";
        //     contentTarget = "v=" + split_url[split_url.length-1];
        // }

        // console.debug("App", appId, "Target", contentTarget);

        // if (appId !== null && contentTarget !== null) {
        //     webOS.service.request("luna://com.webos.applicationManager/", {
        //         method: "launch",
        //         parameters: {
        //             "checkUpdateOnLaunch": true,
        //             "id": appId,
        //             "params": {
        //                 "contentTarget": contentTarget
        //             }
        //         },
        //         onSuccess: function (inResponse) {
        //             console.debug(inResponse);
        //             return true;
        //         },
        //         onFailure: function (inError) {
        //             console.debug(inError);
        //             return;
        //         }
        //     });

        //     return true;
        // }

        // return false;
    }

    showPip() {

        if (this.isPipShown) {
            console.debug("Player showPip: Already shown");
            return;
        }

        this.isPipShown = true;
        window.cloudDemoPlayer.setupPlayer()
    }

    hidePip() {
        if (!this.isPipShown) {
            console.debug("Player hidePip: Already hidden");
            return;
        }

        this.isPipShown = false;
        window.cloudDemoPlayer.resetPlayer()
    }

    hideOverlay() {
    }

    getTVInputs() {
        
    }

    getHeaders(){
        return {
            'X-Api-Host': 'com.cloud.demo',
            'X-Device-Manufacturer': 'Cloud Demo',
            'X-Device-Model': 'Cloud Demo',
            'X-Device-Product': 'Cloud Demo',
            'X-Device-Fingerprint': 'Cloud Demo',
        }
    }
}
