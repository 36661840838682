import React, { Component } from 'react';
import QRCode from 'qrcode.react';

export default class SendToPhoneCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            logo_img: this.props.logo_img,
            title: this.props.title,
            sub_title: this.props.sub_title,
            link: this.props.link,
        }
    }
    componentDidMount() {
        let card = document.querySelector('.sendToPhoneCard')
        if (card) {
            card.style.bottom = '10px';
        }
    }
    render() {
        let qr_code_style = {
            'background': 'white',
            'padding': '5px',
            'borderRadius': '5px'
        }

        return (<div className='sendToPhoneCard'>
            <div className='px-1 py-1'>
                <strong>Send to Phone</strong>
            </div>
            <div className='px-1'>
                {this.state.logo_img && <img src={this.state.logo_img} alt="logo" className='img-responsive sendToPhoneLogoImg' />}
                <p><strong>{this.state.title}</strong></p>
                <p>{this.state.sub_title}</p>

                {this.state.link && <div ><hr className='dark' /><a className='py-1' href={this.state.link}>Get link via SMS</a></div>}
                {this.state.link && <div>
                    <hr className='dark' />
                    <p>Scan QR Code</p>
                    <div className="text-center">
                        <QRCode value={this.state.link} className='px-1 py-1' style={qr_code_style} />,
                    </div>
                </div>}
            </div>
            <div className='text-center'>
                <div className="c-pointer sendToPhoneCard-close-btn" onClick={() => { this.props.close_send_to_phone_card() }}>Close</div>
            </div>
        </div>)
    }
}