import React, { Component } from 'react'
import { KEYCODES, checkIsFullScreen, goFullScreen } from './utils';

export default class SlideShow extends Component {

    constructor(props) {
        super(props)

        this.state = {
            index: props.slideShowStartIndex
        }
        this.slideShowRef = React.createRef()
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    handleKeyPress(event) {
        if (this.props.is_shown) {
            event.stopPropagation();
            let index = this.state.index
            switch (event.keyCode) {
                case KEYCODES.LEFT:
                    index -= 1;
                    if (index > -1) {
                        this.setState({
                            index
                        })
                    }
                    break;
                case KEYCODES.RIGHT:
                    index += 1
                    if (index < this.props.items?.length) {
                        this.setState({
                            index
                        })
                    }
                    break;
            }
        }

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.slideShowStartIndex !== this.props.slideShowStartIndex) {
            console.log("updated slideShowStartIndex", this.props.slideShowStartIndex)
            this.setState({
                index: this.props.slideShowStartIndex
            })
        }
        if (prevProps.is_shown !== this.props.is_shown && this.props.is_shown) {
            if (this.slideShowRef?.current) {
                goFullScreen(this.slideShowRef.current)
            } else {
                console.error("something went wrong - couldn't go fullscreen")
            }
        }
    }

    render() {
        let content
        if (!this.props.is_shown) {
            return null;
        }
        // console.log("mediaRow.mediaItems", this.props.items)
        let item = this.props.items[this.state.index]
        let image = item?.image_hd || item?.image
        content = <img className='fullscreen-image-candidate' src={image} />
        return (
            <div ref={this.slideShowRef} className='slideshow center'>
                {content}
            </div>
        )
    }
}
