import SDKActionsManager from "./SDKActionsManager";
import AlertsManager from './Alerts/AlertsManager'
import { AddedLanguageAlert, VannilaAlert } from "./Alerts/alertTemplates";
import React from "react";
import { LanguagePreference } from "./models"
import EngagementManager from "./Engagement/EngagementManager";
import DatabaseManager from "./database/DatabaseManager";
import moment from "moment";
import PhoneNumPad from "./PhoneNumPad";
import { toast } from 'react-toastify'
import FullscreenViewManager from "./FullscreenViews/FullScreenViewManager";

const API_SET_FAVORITES = "api/v2/user/favorites"
const API_SET_SEEN = "api/v2/user/seen"
const API_WATCHLIST = "api/v2/user/watchlist "
const API_NOT_INTERESTED = "api/v2/user/not_interested"
const API_TV_2_PHONE = '/api/v4/engagement/tv_to_phone'

const PROMOTION_TARGET_TYPES = {
    'SCREENSAVER': 11
}

export default class DemoActionsManager extends SDKActionsManager {

    constructor(env) {
        super()
        this.env = env;
    }

    switchToChannel(channel_id, title, response) {
        response = response || "Switching..."
        // AlertsManager.addAlert(<VannilaAlert key={response} text={response} />)
        toast(<VannilaAlert key={response} text={response} />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
        this.env.switchChannel(channel_id);
    }

    sendSTBRemoteKey(remoteKey) {
        this.env.sendRemoteKey(remoteKey)
    }

    async changeFavoriteState(item_type, item_id, currentState, callback) {
        let data = {
            item_type,
            item_id,
        }
        if (currentState === false) {
            this.env.backend.postForJSONResult(API_SET_FAVORITES, data, (err, response) => {
                if (err) {
                    console.log("And error occured")
                } else {
                    console.log("Response", response)
                    // AlertsManager.addAlert(<AddedFavoriteAlert key={'added favorite'} />)
                    toast(<VannilaAlert key={response} text={"Added to Favorites"} />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
                }
                callback()
            });
        } else {
            this.env.backend.deleteForJSONResult(API_SET_FAVORITES, data, (err, response) => {
                if (err) {
                    console.log("And error occured while adding to favorites")
                } else {
                    console.log("Response", response)
                    // AlertsManager.addAlert(<AddedFavoriteAlert key={'added favorite'} />)
                    toast(<VannilaAlert key={response} text={"Item removed from your favorites"} />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
                }
                callback()
            });
        }

    }

    async changeSeenState(toast_msg, seen_item_type, seen_item_id, currentState, callback) {
        let data = {
            "item_type": seen_item_type,
            "item_id": seen_item_id,
        }
        if (currentState === false) {
            this.env.backend.postForJSONResult(API_SET_SEEN, data, (err, response) => {
                if (err) {
                    console.log("And error occured while adding to seen")
                } else {
                    console.log("Add to seen response", response)
                    toast(<VannilaAlert key={response} text={toast_msg} classNames="added-to-watched-alert" />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
                }
                callback()
            });
        } else {
            this.env.backend.deleteForJSONResult(API_SET_SEEN, data, function (err, response) {
                if (err) {
                    console.log("And error occured while removing from seen")
                } else {
                    console.log("Remove from seen response", response)
                    toast(<VannilaAlert key={response} text={"Item removed from your seen list"} classNames="added-to-watched-alert" />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
                }
                callback()
            });
        }
    }

    changeWatchlistState(toast_msg, item_type, item_id, currentState, callback) {
        let data = {
            item_type,
            item_id
        }
        if (currentState === false) {
            this.env.backend.postForJSONResult(API_WATCHLIST, data, (err, response) => {
                if (err) {
                    console.log("And error occured while adding to watchlist")
                } else {
                    console.log("Add to watchlist response", response)
                    toast(<VannilaAlert key={response} text={toast_msg} classNames="added-to-watched-alert" />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
                }
                callback()
            });
        } else {
            this.env.backend.deleteForJSONResult(API_WATCHLIST, data, (err, response) => {
                if (err) {
                    console.log("And error occured while removing from watchlist")
                } else {
                    console.log("Remove from watchlist response", response)
                    toast(<VannilaAlert key={response} text={"Item removed from your watchlist"} classNames="added-to-watched-alert" />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
                }
                callback()
            });
        }
    }

    async changeNotInterestedState(toast_msg, item_type, item_id, currentState, callback) {
        let data = {
            item_type,
            item_id
        }
        if (currentState === false) {
            this.env.backend.postForJSONResult(API_NOT_INTERESTED, data, (err, response) => {
                if (err) {
                    console.log("And error occured while adding to not-interested list")
                } else {
                    console.log("Response", response)
                    toast(<VannilaAlert key={response} text={toast_msg} classNames="added-to-watched-alert" />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
                }
                callback()
            });
        } else {
            this.env.backend.deleteForJSONResult(API_NOT_INTERESTED, data, (err, response) => {
                if (err) {
                    console.log("And error occured while removing from not-interested list")
                } else {
                    console.log("Response", response)
                    toast(<VannilaAlert key={response} text={"Item removed from your not interested list"} classNames="added-to-watched-alert" />, { autoClose: 3000, hideProgressBar: true, className: 'vannila-alert-container p-0', closeButton: false, position: toast.POSITION.BOTTOM_RIGHT })
                }
                callback()
            });
        }

    }

    displayMediaDetail(media_detail, callback) {
        callback({
            pathname: '/search',
            state: {
                detail: media_detail
            }
        })
    }

    setPreferredLanguages(new_pref_langs) {
        LanguagePreference.addLanguages(new_pref_langs)
        AlertsManager.addAlert(<AddedLanguageAlert key={'added language'} languages={new_pref_langs} />)
    }

    formatExpiresAt(promotion) {
        // remove the trailing z in -> 2020-03-31T12:32:00+00:00Z
        // store it as a date obj for convenience 
        promotion.original_expires_at = promotion.expires_at
        promotion.expires_at = promotion.expires_at ? moment(promotion.expires_at.slice(0, promotion.expires_at.length - 1)).toDate() : null
        return promotion
    }

    showEngagementStrip(chatMessage) {
        let current_location = window.location.href
        console.log("Got Engagement action!", chatMessage)
        let promotion = chatMessage.action_meta.image_promotion || chatMessage.action_meta.link_promotion
        promotion = this.formatExpiresAt(promotion)
        if (!promotion.expires_at || promotion.expires_at > Date.now()) {
            if (promotion.event_target && promotion.event_target.id === PROMOTION_TARGET_TYPES['SCREENSAVER']) {
                this.storeScreensaverPromotion(promotion)
            }
            else if (current_location.includes('tv')) {
                EngagementManager.removeEngagement()
                EngagementManager.addEngagement(chatMessage)
            }

        } else {
            console.log("Oops! its an expired promo👵")
        }
    }

    async storeScreensaverPromotion(promotion) {
        let db = DatabaseManager.getConnection()
        var id = await db.screensaver_promotions.put({ title: promotion.title, expires_at: promotion.expires_at, promotion_id: promotion.id, original_promotion: promotion });
        console.log("successfully saved promotion: ", id);

    }

    sendToPhone(chatMessage) {
        console.log("send to phone chatmessage".chatMessage)
        let data = {}
        if (chatMessage.action_meta) {
            let promotion = chatMessage.action_meta.image_promotion || chatMessage.action_meta.link_promotion
            let current_location = window.location.href
            let current_channel = current_location.split('/tv/')[1]
            if (current_channel.includes('/')) {
                current_channel = current_channel.split('/')[0]
            }
            data = { campaign_id: promotion.campaign_id, channel_id: current_channel }
            if (Object.keys(promotion).includes('product_id')) {
                data['product_id'] = promotion['product_id']
            }
        }

        let onOKPressedAction = () => {
            console.log(data)
            toast.dismiss()
            this.env.backend.postForJSONResult(API_TV_2_PHONE, data, function (err, response) {
                if (err) {
                    console.log("TV2Phone: error occured")
                } else {
                    console.log("TV2Phone Response", response)
                }
            });
        }
        toast(<PhoneNumPad onOKPressed={onOKPressedAction} />, { autoClose: false, className: 'tv2phone-card p-0', closeButton: false, draggable: false, closeOnClick: false, position: toast.POSITION.BOTTOM_RIGHT })
    }

    show360Image(image360) {
        FullscreenViewManager.show360Image(image360)
    }

    showPanorama(panorama_identifier) {
        FullscreenViewManager.showPanorama(panorama_identifier)
    }
}