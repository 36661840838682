import { EditableText } from '@blueprintjs/core'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import './CameraDevicesEmbedSampleView.scss'
var STREAM_QUERY_PARAM = 'id'


export default class CameraDevicesEmbedSampleView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected_camera_device_id: '',
            ...this.getInitState(props, 59256046)
        }
        this.getInitState = this.getInitState.bind(this)
        this.onInputChange = this.onInputChange.bind(this)
    }


    getInitState(props, default_stream_id='') {
        console.log("props", props);
        let search_params = new URLSearchParams(this.props.location.search)
        if (search_params.has(STREAM_QUERY_PARAM)) {
            return {
                selected_camera_device_id: parseInt(search_params.get(STREAM_QUERY_PARAM)),
            }
        }
        return {
            selected_camera_device_id: default_stream_id
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                ...this.getInitState(this.props)
            })
        }
    }

    onInputChange(value) {
        let search_params = new URLSearchParams(this.props.location.search)
        let streamId = value.trim()
        console.log("streamid ", streamId)
        if (streamId) {
            search_params.set(STREAM_QUERY_PARAM, streamId)
        } else {
            search_params.delete(STREAM_QUERY_PARAM)
        }

        this.props.history.push({
            ...this.props.location,
            search: search_params.toString(),
        })
    }

    render() {
        let helmet = <Helmet>
            <title>WeAreLive</title>
            <body className="with-bootstrap with-blueprint" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>

        return (
            <div className='camera-devices-embed-sample-view container px-3 py-5'>
                {helmet}
                <div className='center flex-column'>
                    <h2><strong>Sample Embedded stream -
                        {/* <input
                            type="text"
                            value={this.state.selected_camera_device_id}
                            onChange={this.onInputChange}
                            style={{ backgroundColor: "black", color: "white", border: "none", marginLeft: "10px" }}
                        /> */}
                        <EditableText
                            className='editable-text'
                            value={this.state.selected_camera_device_id}
                            onChange={this.onInputChange}
                            placeholder={'Stream ID'}
                        />
                    </strong></h2>
                    {this.state.selected_camera_device_id ? <iframe
                        className='mt-5'
                        width="769"
                        height="432"
                        src={`http://wearelive.world/embed/streams?id=${this.state.selected_camera_device_id}`}
                        frameborder="0"
                        allow=" autoplay; clipboard-write;"
                        allowfullscreen />
                        : <div>Invalid Stream ID</div>}
                </div>
            </div>
        )
    }
}
