import React, { Component } from 'react';
import Logger from './Logger'
import _ from 'underscore';

import Backend from './backend'
import SearchBar from './SearchBar';

import { DPadNav } from './dpadnav'
import { MediaDetail } from './models'

import MediaRowView from './MediaRowView'
import { toast } from 'react-toastify';
import micIcon from '../includes/images/icon_mic.png'
import { Link } from 'react-router-dom';
import { KEYCODES } from './utils'
import hark from 'hark'
var API_SEARCH = "api/v4/epg/universal_search?format=json&q=";
// var API_SEARCH = "api/v2/chatbot/message?q=";
var API_VOICE_SEARCH = "api/v4/chatbot/message";
var API_SEARCH_WITH_AUDIO_API = "api/v4/chatbot/audio_message"
// const API_FACET_AUTOFILL = "api/v1/epg/facet?prefix=";
const getSearchSuggestionsEndpoint = (query, productID) => `api/v1/epg/facet?prefix=${encodeURIComponent(query)}&product_id=${productID}`

var ROW_SEARCH = 0;
// var ROW_ACTIONS = 1;
var ROW_SEARCH_SUGGESTIONS = 1
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
let rec;
if (SpeechRecognition !== undefined) {
    rec = new SpeechRecognition()
} else {
    rec = null
}
const recognition = rec;
if (recognition !== null) {
    recognition.continous = true
    recognition.interimResults = true
    recognition.lang = 'en-US'
}

export default class MediaDetailView extends Component {

    constructor(props) {
        super(props)
        let detail = null
        console.log(this.props.location)
        if (this.props.location.state && this.props.location.state.detail) {
            detail = new MediaDetail(this.props.location.state.detail)
        }
        this.state = {
            "detail": detail,
            "isLoading": true,
            "is_loading_details": false,
            "isSearchActive": false,
            "channelsEpisodeIndex": {},
            "navState": {
                "row": 0,
                "cols": {},
            },
            "isOverlayActive": false,
            "feedId": props.match.params.id,
            "can_listen": false,
            "listening": false,
            "facet_autofills": [],
            "highlighted_search_suggestion": 0,
            "useAzure": false,
            "query": ""
        }

        this.backend = Backend.get();
        this.env = Backend.getEnv();
        this.searchInputRef = React.createRef()
        this.onChange = this.onChange.bind(this);
        this.nav = new DPadNav(this.state.navState);
        this.fetchDetailForVoiceQuery = _.debounce(this.fetchDetailForVoiceQuery.bind(this), 1000);
        this.fetchDetailForQuery = _.debounce(this.fetchDetailForQuery.bind(this), 1000);
        this.fetchFacetAutoComplete = _.debounce(this.fetchFacetAutoComplete.bind(this), 1000);
        this.handleListen = this.handleListen.bind(this)
        this.toggleListen = this.toggleListen.bind(this)
        this.handleAudioRecord = this.handleAudioRecord.bind(this)
        this.startSilenceDetection = this.startSilenceDetection.bind(this)
        this.createDownloadLink = this.createDownloadLink.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.setSearchTerm = this.setSearchTerm.bind(this)
    }

    onChange(value) {
        this.fetchFacetAutoComplete(value)
        this.setState({
            isQueryPrefix: true,
            detail: null,
            query: value
        })
    }

    setSearchTerm() {
        let { query: value } = this.state
        this.props.history.push({
            pathname: "/search",
            search: "?q=" + encodeURIComponent(value)
        })
        this.searchInputRef.current.focus()
        this.fetchDetailForQuery(value)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            let query = new URLSearchParams(this.props.location.search)

            console.log('received query ', query.get('q'))
            // this.onChange(props.location.query['q'],true)
            this.setState({
                isQueryPrefix: true,
                query: query.get('q'),
                detail: null,
            })
            this.fetchDetailForQuery(query.get('q'))
        }
    }

    componentDidMount() {

        this.nav.bind(document);

        var that = this;
        this.nav.onNavStateChanged = function (navState) {
            that.setState({
                navState: navState
            }, () => {
                that.nav.scrollContent()
            });
            if (navState.row !== ROW_SEARCH) {
                let searchBar = document.querySelector(".searchBar")
                if (searchBar) searchBar.blur()
            } else {
                let searchBar = document.querySelector(".searchBar")
                if (searchBar) searchBar.focus()
            }
        }
        let searchBar = document.querySelector(".searchBar")
        if (searchBar) searchBar.focus()

        Logger.log("Props", this.props);
        if (this.props.location?.search) {
            console.log('received query ', this.props.location.search)
            let query = new URLSearchParams(this.props.location.search)
            if (query.get('q')) {
                this.onChange(query.get('q'))
            }
        }
        this.env = Backend.getEnv();
        this.env.hidePip()
        document.addEventListener("keydown", this.handleKeyPress, true);

    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, true);
    }

    handleKeyPress(event) {
        switch (event.keyCode) {
            case KEYCODES.ESC:
            case KEYCODES.BACK:
            case KEYCODES.MENU:
                console.log("pushing..")
                this.props.history.push({
                    pathname: "/on_air",
                })
                event.preventDefault()
                event.stopPropagation()
            default: break;
        }
    }

    fetchDetailForVoiceQuery(prefix) {
        Logger.log("Fetching Voice Search Results for " + prefix);

        var that = this;
        this.setState({
            is_loading_details: true,
        })
        var data = { 'message': prefix }
        this.backend.postForJSONResult(API_VOICE_SEARCH, data, function (err, response) {
            console.log(response)
            that.setState({
                is_loading_details: false,
            })
            if (response) {
                console.log(response)
                that.env.actionsManager.executeAction(response, (data) => {
                    console.log(data)
                    that.props.history.push(data)
                    if (data.state.detail) {
                        that.setState({
                            detail: new MediaDetail(data.state.detail)
                        })
                    }
                })
            }
        });
    }

    fetchDetailForQuery(prefix) {
        Logger.log("Fetching Search Results for " + prefix);

        var that = this;
        this.setState({
            is_loading_details: true,
        })
        this.backend.getJSONResult(API_SEARCH + encodeURIComponent(prefix), function (err, response) {
            console.log(err)
            that.setState({
                is_loading_details: false,
            })
            if (response) {
                console.log(response)
                that.setState({
                    "detail": new MediaDetail(response)
                })
            }
        });
    }

    fetchFacetAutoComplete(prefix) {
        // this.nav.reset()
        // Logger.log("Fetching autofill Results for " + prefix);
        this.setState({
            is_loading_options: true,
        })
        this.backend.getJSONResult(getSearchSuggestionsEndpoint(prefix, this.env.getProductID()), (err, response) => {
            let suggestions = (response?.results || []).map(item => { return item['title'] })
            this.setState({
                "facet_autofills": suggestions,
                "is_loading_options": false
            })
        })
    }


    fetchDetailForAudioMessage(blob) {
        this.setState({
            is_loading_details: true,
        })

        let file = new File([blob], "file", { type: 'audio/webm;codecs=opus' })
        let data = new FormData()
        data.append('file', file, 'audio.wav')
        console.log(data)
        this.backend.postData(API_SEARCH_WITH_AUDIO_API, data, (err, response) => {
            if (err) {
                console.log("err from azure", err)
                this.setState({
                    is_loading_details: false,
                })
            }
            else if (response) {
                console.log("azure response", response)
                this.setState({
                    is_loading_details: false,
                    query: response.message,
                })
                this.env.actionsManager.executeAction(response, (data) => {
                    console.log(data)
                    this.props.history.push(data)
                    if (data.state.detail) {
                        this.setState({
                            detail: new MediaDetail(data.state.detail)
                        })
                    }
                })
            }
        });
    }

    handleListen() {
        if (recognition !== null) {
            if (this.state.can_listen) {
                recognition.start()
                console.log("speech recognition starting")
                var inputField = document.getElementById('search-input-field')
                let finalTranscript = ''
                recognition.onresult = event => {
                    let interimTranscript = ''

                    for (let i = event.resultIndex; i < event.results.length; i++) {
                        const transcript = event.results[i][0].transcript;
                        if (event.results[i].isFinal) finalTranscript += transcript + ' ';
                        else interimTranscript += transcript;
                        inputField.placeholder = interimTranscript
                    }
                    if (finalTranscript == null) { // on purpose
                        finalTranscript = interimTranscript
                    }
                    inputField.value = finalTranscript
                    this.setState({
                        query: finalTranscript
                    })
                    inputField.focus()
                    if (finalTranscript !== "") {
                        this.fetchDetailForVoiceQuery(finalTranscript)
                    }
                }

                recognition.onstart = () => {
                    this.setState({
                        "listening": true,
                    })
                }
                recognition.onend = () => {
                    recognition.stop();
                    this.setState({
                        "listening": false,
                        "can_listen": false,
                    })
                }
            } else {
                recognition.stop()
            }
        }
    }

    handleAudioRecord() {
        if (navigator.mediaDevices) {
            console.log("media devices supported")
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    let audioContext = new (window.AudioContext || window.webkitAudioContext)();
                    let input = audioContext.createMediaStreamSource(stream);
                    this.audioRecorder = new window.Recorder(input, { numChannels: 1 })
                    this.audioRecorder.record()
                    this.setState({ listening: true }, () => {
                        this.startSilenceDetection(stream, this.audioRecorder)
                    })
                }, (error) => {
                    console.log("error", error)//should replace with toast
                    toast(<div className='toast-content'>Please check your microphone!</div>, { className: 'toast-container custom', closeButton: false, hideProgressBar: true, draggable: false, closeOnClick: false, position: toast.POSITION.BOTTOM_CENTER })
                });
        } else {
            toast(<div className='toast-content'>Your browser doesn't support recording</div>, { className: 'toast-container custom', closeButton: false, hideProgressBar: true, draggable: false, closeOnClick: false, position: toast.POSITION.BOTTOM_CENTER })
            console.log("media devices not supported")//should replace with toast
        }
    }

    createDownloadLink(blob) {
        const downloadLink = document.getElementById('download');
        let audio_msg_url = URL.createObjectURL(blob);
        if (downloadLink) {
            downloadLink.href = audio_msg_url
            downloadLink.download = 'acetest.wav';
        }
        this.fetchDetailForAudioMessage(blob)
    }

    startSilenceDetection(stream, rec) {
        console.log("setting up silence detector..")
        var options = {};
        var speechEvents = hark(stream, options);
        speechEvents.on('stopped_speaking', ()=> {
            console.log('stopped_speaking');
            rec.stop();
            stream.getAudioTracks()[0].stop();
            rec.exportWAV(this.createDownloadLink);
            this.setState({
                listening: false
            })
        });
    }


    toggleListen() {
        if (!this.state.useAzure) {
            if (recognition !== null) {
                console.log("handling mic button click ")
                this.setState({
                    "can_listen": !this.state.can_listen
                }, this.handleListen)
            } else {
                alert("Oops! Looks like you're using an unsupported browser for voice search")
            }
        } else {
            if (!this.state.listening) {
                console.log("gonna use azure")
                this.handleAudioRecord()
            } else {
                console.log("stopped listening..")
                this.audioRecorder.stop()
                this.setState({
                    listening: false
                })
            }
        }
    }

    render() {
        let { detail } = this.state

        var searchSuggestions = this.state.facet_autofills
        var counter = 1;

        var searchBar = <SearchBar nav={this.nav} onStateChanged={this.onSearchStateChanged}
            isFocused={this.nav.isFocusedRow(ROW_SEARCH)}
            focusedCol={this.nav.getColPos(0)} />;

        counter++;

        var rows = [];
        if (detail) {
            console.log(this.state.detail.mediaRows)
            rows = this.state.detail.mediaRows.map((mediaRow, key) => {

                var hasGroups = mediaRow.mediaItemGroups && mediaRow.mediaItemGroups.length > 0;

                var rowHeaderKey = counter, rowValKey = counter;

                counter++;

                if (hasGroups) {
                    rowValKey++;
                    counter++;
                }

                var row = <MediaRowView key={key} mediaRow={mediaRow}
                    row={rowValKey} nav={this.nav}
                    rowHeaderKey={rowHeaderKey}
                    rowValKey={rowValKey}
                    isFocusedGroups={this.nav.isFocusedRow(rowHeaderKey)}
                    isFocusedDocs={this.nav.isFocusedRow(rowValKey)}
                    focusedGroupCol={this.nav.getColPos(rowHeaderKey)}
                    focusedDocsCol={this.nav.getColPos(rowValKey)}
                />;
                return row;
            });
           
        }
        var defaultSearchSuggestions = ["English Movies", "Science Fiction", "English Crime movies", "Superhero"]

        if (searchSuggestions.length === 0) {
            searchSuggestions = [...defaultSearchSuggestions]
        }
        var searchSuggestionRows = []

        for (let i = 0; i < searchSuggestions.length; i++) {
            searchSuggestionRows.push(<div key={searchSuggestions[i]} className={'searchSuggestionItem ' + (this.nav.isFocused(ROW_SEARCH_SUGGESTIONS, i) ? "NavCard cardFocus highlightedSearchSuggestionItem" : "NavCard")}>
                <Link to={`/search?q=${encodeURIComponent(searchSuggestions[i])}`}>{searchSuggestions[i]}</Link>
            </div>)
        }



        Logger.log("Rows", this.state.detail && this.state.detail.mediaRows.length, rows.length);

        var rowHeight = 340;
        var translateStyleY = {
            // "transform": "translateY(" + this.nav.getTranslateChannelsY() + "px)"
        }
        var input_field_value, searchInputStyle;
        console.log('rows length ' + rows.length)
        if (this.state.query && !(this.nav.isFocusedRow(ROW_SEARCH) || this.nav.isFocusedRow(ROW_SEARCH_SUGGESTIONS))) {
            input_field_value = "Recommendations for " + this.state.query
        } else if (this.nav.isFocusedRow(ROW_SEARCH) || this.nav.isFocusedRow(ROW_SEARCH_SUGGESTIONS)) {
            input_field_value = this.state.query
            searchInputStyle = {
                "backgroundColor": "#FFF",
                "color": "#333",
                "padding": "15px",
                "fontStyle": "initial",
                "textDecoration": "initial",
            }
        } else {
            input_field_value = ""
            searchInputStyle = {
                "backgroundColor": "#FFF",
                "color": "#333",
                "padding": "15px",
                "fontStyle": "initial",
                "textDecoration": "initial",
            }
        }
        if (this.state.listening) {
            searchInputStyle["backgroundImage"] = "url('./static/images/recording.svg')"
            searchInputStyle["backgroundPosition"] = "calc(100% - 10px) 50%"
            searchInputStyle["backgroundRepeat"] = "no-repeat"
            searchInputStyle["backgroundSize"] = "40px 40px"
        }
        if (this.state.is_loading_details) {
            searchInputStyle["backgroundImage"] = "url('./static/images/loading.svg')"
            searchInputStyle["backgroundPosition"] = "calc(100% - 10px) 50%"
            searchInputStyle["backgroundRepeat"] = "no-repeat"
            searchInputStyle["backgroundSize"] = "40px 40px"

        }

        if (this.state.detail?.mediaRows?.length === 0) {
            rows = <div className='text-center' style={{margin: "5rem auto"}}>{`Sorry, we have no results for ${input_field_value}`}</div>
        }
        return <div className="MediaDetail transitionAll content-padding" style={translateStyleY}>
            <div className="searchBarContainer NavRow">

                <input
                    ref={this.searchInputRef}
                    id="search-input-field"
                    autoComplete="off"
                    className={"NavCard searchBar " + (this.nav.isFocusedRow(ROW_SEARCH) ? " " : "") + (this.nav.isFocused(ROW_SEARCH, 0) ? "cardFocus " : "")}
                    style={searchInputStyle}
                    placeholder="Search TV"
                    value={input_field_value}
                    onChange={(event) => { this.onChange(event.target.value) }}
                    onKeyDown={(event) => {
                        if (event.keyCode === KEYCODES.ENTER) {
                            this.setSearchTerm()
                        }
                    }}
                    tabIndex="-1" />
                <div className='audio-toggle-switch'>
                    <label className="switch m-r-10">
                        <input type="checkbox" value={this.state.useAzure} onChange={() => { this.setState({ useAzure: !this.state.useAzure }) }} />
                        <span className="slider round"></span>
                    </label>
                    <div>
                        Use Azure
                    </div>
                    {/* <a id="download">Download</a> */}
                </div>
                {
                    (this.nav.isFocusedRow(ROW_SEARCH) || searchInputStyle) && <div style={{ display: "inline-block" }} >
                        <span className={"SearchBarVoiceSearch NavCard " + (this.nav.isFocused(ROW_SEARCH, 1) ? "cardFocus" : "")}><a onClick={this.toggleListen}><img alt="voice search" src={micIcon} /></a></span>
                    </div>
                }

            </div>


            <div className='clearBoth searchSuggestionsContainer NavRow list-container' style={this.nav.getTranslateXStyle(ROW_SEARCH_SUGGESTIONS)}>
                {searchSuggestionRows}
            </div>
            <div className="clearBoth" />
            {rows}
        </div >
    }
}