import React, { Component } from 'react'
import Backend from './backend'
import DatabaseManager from './database/DatabaseManager'
import { KEYCODES } from './utils'

const API_FETCH_SCREENSAVER = 'api/v4/engagement/test_predefined_promotion?mode=pull&id=-10'

export default class Screensaver extends Component {
    constructor(props) {
        super(props)

        this.state = {
            screensaver: null
        }
        this.db = DatabaseManager.getConnection()
        this.backend = Backend.get()
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.getScreensaver = this.getScreensaver.bind(this)
        this.fetchAndSetScreensaver = this.fetchAndSetScreensaver.bind(this)
        this.executeEngagementAction = this.executeEngagementAction.bind(this)
    }


    async componentDidMount() {

        document.addEventListener('keydown', this.handleKeyPress, true)

        console.log('gonna show screensaver')
        let screensaver = await this.getScreensaver()
 
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.handleKeyPress, true)
    }

    handleKeyPress(event){
        console.log("key press in screensaver page")
        event.stopPropagation()
        event.preventDefault()
        switch (event.keyCode) {
            case KEYCODES.ENTER:
                this.executeEngagementAction()
                break;
            default:
                break;
        }
        return false;
    }

    executeEngagementAction(){
        console.log("executing engagement action")
        Backend.getEnv().actionsManager.executeAction(this.state.screensaver.action)
        this.props.onDismiss()
    }

    async getScreensaver() {
        let available_screensavers = await this.db.screensaver_promotions.toArray()
        console.log(available_screensavers)
        if(available_screensavers.length > 0){
            let chosen_screensaver = available_screensavers[0]
            let screensaver = chosen_screensaver.original_promotion
            this.setState({
                screensaver,
            }, async () => {
                //deletes expired promotion
                await this.db.screensaver_promotions
                    .where('expires_at')
                    .below(Date.now())
                    .delete();
            })
        }else{
            console.log("oops no screensaver available. Asking server 🗼")
            this.fetchAndSetScreensaver()
        }

    }


    fetchAndSetScreensaver() {
        this.backend.getJSONResult(API_FETCH_SCREENSAVER, (error, response) => {
            if (error) {
                console.log(error)
            } else {
                console.log(response)
                this.setState({
                    screensaver: response,
                })
            }
        })
    }

    render() {
        if (!this.state.screensaver) {
            return null
        }
        return (
            <div>
                {this.state.screensaver && <div className='screensaver-container'>
                    {this.state.screensaver.image
                        ? <img className='screensaver' src={this.state.screensaver.image} />
                        : <video className='screensaver' autoPlay controls>
                            <source src={this.state.screensaver.video} type="video/mp4" />
                                Your browser does not support the video tag.
                        </video>
                    }
                </div>
                }
            </div>
        )
    }
}
