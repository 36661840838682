import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import LoginRequiredComponent from './modules/LoginRequiredComponent';
import OnAirView from './modules/OnAirView';
import TVView from './modules/TVView';
import ChannelsView from './modules/ChannelsView';
import DetailView from './modules/DetailView';
import YouTubeView from './modules/YouTubeView';
import SearchView from './modules/SearchView';
import LoginView from './modules/LoginView';

import LanguagePreferenceView from './modules/LanguagePreferenceView';
import TVProviderPreferenceView from './modules/TVProviderPreferenceView';
import PairWithAlexaView from './modules/PairWithAlexaView';
import Backend from './modules/backend';
import CloudRemotePage from './modules/CloudRemote/CloudRemotePage';
import CameraDevicesView from './modules/CameraDevice/CameraDevicesView';
import CameraDevicesEmbedSampleView from './modules/CameraDevice/CameraDevicesEmbedSampleView';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducers/reduxStoreReducer'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

let build_type = Backend.getBuildType()
let basename = Backend.getDeployedBasePath()
const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
        trace: true,
    })
)

// Sentry.init({
//     dsn: "http://bdd1e124393d4972ad86ad9ab14f0abf:06dc46c051d04af9986919dba84f23bb@sentry.sensara.tv/6",
//     integrations: [new Integrations.BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
// });
// console.log("basename", `${basename}/firebase-messaging-sw.js`)


// npm_config_homepage='/ottdemo' -> to dynamically set the homepage in package.json
ReactDOM.render(
    <Router basename={basename}>
        <Provider store={store}>
        <App>
            <Switch>
                <Route path="/login" component={LoginView} />
                <Route path="/cloudremote" component={CloudRemotePage} />
                <Route path="/streams" component={CameraDevicesView} />
                <Route path="/embed/streams" component={CameraDevicesView} />
                <Route path="/embed/sample" component={CameraDevicesEmbedSampleView} />
                <Route path="/detail/shoppable">
                    <Route path="/detail/:itemType/:itemId" component={DetailView} />
                </Route>

                <LoginRequiredComponent>
                    <Switch>
                        <Route path="/on_air" component={OnAirView} />
                        <Route path="/tv/:id" component={TVView} />
                        <Route path="/channels" component={ChannelsView} />
                        <Route path="/settings/languages" component={LanguagePreferenceView} />
                        <Route path="/settings/provider" component={TVProviderPreferenceView} />
                        <Route path="/settings/pair_with_alexa" component={PairWithAlexaView} />
                        <Route path="/youtube/:id" component={YouTubeView} />
                        <Route path="/search" component={SearchView} />
                        <Route path="/you" component={DetailView} />
                        <Route path="/special/:topic" component={DetailView} />
                        <Route path="/detail/:itemType/:itemId" component={DetailView} />
                        <Route exact path="/">
                            <Redirect to="/on_air" />
                        </Route>
                    </Switch>
                </LoginRequiredComponent>
            </Switch>
        </App>
        </Provider>
    </Router>,
    document.getElementById('root')
);
