import React, { Component } from 'react';

import MediaItemView from './MediaItemView'
import SlideShow from './SlideShow';

import { checkIsFullScreen } from './utils'

export const MEDIA_ROW_CONTENT_TYPES = {
    "LINK": 0,
    "SLIDESHOW": 301,
    "PANORAMA": 302,
    "360_VIEW": 303,
}

export default class MediaRowView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showSlideShow: false,
        }
        this.slideShowRef = React.createRef()
        this.showSlideShow = this.showSlideShow.bind(this)
        this.onFullScreenChange = this.onFullScreenChange.bind(this)
    }


    componentDidMount() {
        ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'].forEach(evt =>
            document.addEventListener(evt, this.onFullScreenChange)
        );
    }

    componentWillUnmount() {
        ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'].forEach(evt =>
            document.removeEventListener(evt, this.onFullScreenChange)
        );
    }

    onFullScreenChange() {
        let isFullScreen = checkIsFullScreen()
        // if the use exits fullscreen state has to be updated to reflect
        let { showSlideShow } = this.state
        if (showSlideShow && !isFullScreen) {
            showSlideShow = false
        }
        this.setState({
            showSlideShow,
        })
    }

    showSlideShow(index) {
        console.log("showSlideShow")
        this.setState({ showSlideShow: true, slideShowItemIndex: index })
    }

    render() {

        const mediaRow = this.props.mediaRow;

        var hasGroups = mediaRow.mediaItemGroups && mediaRow.mediaItemGroups.length > 0;

        var groups = [];
        if (hasGroups) {
            for (let j = 0; j < mediaRow.mediaItemGroups.length; j++) {
                var group = mediaRow.mediaItemGroups[j];
                var groupClass = "MediaItemGroup NavCard";
                if ((this.props.isFocusedDocs || this.props.isFocusedGroups) && group.id === this.props.focusedGroupCol + 1) {
                    groupClass += " MediaItemGroupActive";
                    if (this.props.isFocusedGroups) {
                        groupClass += " MediaItemGroupSelected";
                    }
                }
                groups.push(<div className={groupClass} key={j} onClick={(event) => {
                    this.props.nav.navJumpTo(this.props.rowHeaderKey, j, event.target)
                }}>{group.title}<div className="cardOverlay" /></div>);
            }
        }

        var filteredItems = [];
        if (!hasGroups) {
            filteredItems = mediaRow.mediaItems;
        } else {
            for (var i = 0; i < mediaRow.mediaItems.length; i++) {
                if (mediaRow.mediaItems[i].group_ids.indexOf(this.props.focusedGroupCol + 1) >= 0) {
                    filteredItems.push(mediaRow.mediaItems[i]);
                }
            }
        }

        var icons = {};
        if (mediaRow.mediaItemIcons) {
            for (var i = 0; i < mediaRow.mediaItemIcons.length; i++) {
                var mediaItemIcon = mediaRow.mediaItemIcons[i];
                icons[mediaItemIcon.id] = mediaItemIcon;
            }
        }

        const items = filteredItems.map((mediaItem, index) =>
            <MediaItemView
                key={index}
                mediaItem={mediaItem}
                showSlideShow={() => this.showSlideShow(index)}
                displayConfig={mediaRow.displayConfig}
                icons={icons}
                isFocused={this.props.isFocusedDocs && this.props.focusedDocsCol === index}
            />
        );


        return <div className={"MediaRow " + ((this.props.isFocusedGroups || this.props.isFocusedDocs) ? "activeRow" : "inactiveRow")}>
            <div className="cardTitle">{mediaRow.title}</div>
            {
                groups.length > 0 &&
                <div className="MediaRowGroups NavRow list-container" style={this.props.nav.getTranslateXStyle(this.props.rowHeaderKey)}>
                    {groups}
                </div>
            }
            <div className={"MediaRowItems NavRow list-container " + (groups.length > 0 ? "hasMediaRowGroups" : "")} style={this.props.nav.getTranslateXStyle(this.props.rowValKey)}>
                {items}
            </div>
            <div className="clearBoth" />
            <SlideShow
                slideShowStartIndex={this.state.slideShowItemIndex}
                is_shown={this.state.showSlideShow}
                items={mediaRow.mediaItems} />
        </div>

    }
}
