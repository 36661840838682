import React, { Component } from 'react';
import Backend from './modules/backend'
import AlertsManager from './modules/Alerts/AlertsManager';
import PreRollAdManager from './modules/PreRollAds/PreRollAdManager';
import DatabaseManager from "./modules/database/DatabaseManager";
import Screensaver from './modules/Screensaver';
import { ToastContainer } from 'react-toastify'
import { isTVView } from './modules/utils';
import { withRouter } from 'react-router';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import FullscreenViewManager from './modules/FullscreenViews/FullScreenViewManager';

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      show_screensaver: false
    }
    this.env = Backend.getEnv();
    this.inactivityTimer = null
    this.resetTimer = this.resetTimer.bind(this)
    this.setUserAsActive = this.setUserAsActive.bind(this)

  }

  componentDidMount() {
    console.debug("App Mounted", this.env);
    ['keydown', 'scroll', 'mousemove'].forEach(evt =>
      document.addEventListener(evt, this.setUserAsActive, false)
    );
    document.addEventListener('perform-route-change', (event) => {
      const {path, data} = event.detail
      console.log("performing route change", path, data)
      this.props.history.push({
        pathname: path,
      })
    })
    DatabaseManager.getConnection()
    this.resetTimer()
  }

  componentWillUnmount() {
    clearTimeout(this.inactivityTimer)
    ['keydown', 'scroll', 'mousemove'].forEach(evt =>
      document.removeEventListener(evt, this.setUserAsActive, false)
    );
  }

  setUserAsActive() {
    this.resetTimer()
  }

  resetTimer() {
    if (this.state.show_screensaver) {
      this.setState({
        show_screensaver: false
      })
    }
    clearTimeout(this.inactivityTimer)
    this.inactivityTimer = setTimeout(() => {
      console.log('timeout⏳')
      if (!isTVView()){
        // this.setState({
        //   show_screensaver: true
        // })
      }
    }, 60 * 1000)
  }

  render() {

    var className = "App";

    // Enables transitions
    className += " transition-on";

    let isDetailView = this.props.location.pathname.lastIndexOf("/episode", 0) === 0;
    let isYouTubeView = this.props.location.pathname.lastIndexOf("/youtube", 0) === 0;
    let isSearchView = this.props.location.pathname.lastIndexOf("/search", 0) === 0;
    let isCloudRemotePage = this.props.location.pathname.lastIndexOf("/cloudremote", 0) === 0;

    if (isDetailView || isSearchView) {
      className += " BackgroundOverlay";
    } else if (isYouTubeView) {
      className += " YouTubeView"
    } else if (isCloudRemotePage){
      className += ' p-0'
    }
    

    return (
      <div className={className}>
        {this.props.children}
        {
          this.env.isBackgroundPosterEnabled() &&
          <div className="backgroundPoster" />
        }
        <AlertsManager />
        <PreRollAdManager />
        <FullscreenViewManager />
        <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        {this.state.show_screensaver && <Screensaver onDismiss={this.resetTimer} />}
      </div>
    );

  }
}

export default withRouter(App);
