import React, { Component } from 'react';
import LazyLoad from 'react-lazy-load';

const spacer = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";


export default class CenterCropImage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      show_bg_color: true
    }
  }


  render() {
    let { show_bg_color } = this.state
    let { src } = this.props

    const style = {
      backgroundImage: 'url(' + this.props.src + ')',
      visibility: "none"
    };

    if (!show_bg_color) {
      style.backgroundColor = "transparent"
      style.visibility = "auto"
      src = spacer
    }

    if (this.props.height) {
      style.height = this.props.height + "px";
    }

    if (this.props.width) {
      style.width = this.props.width + "px";
    }

    return (
      <LazyLoad height={this.props.height || 200} width={this.props.width || 300} offsetTop={700}>
        <img alt="" className="centerCropImage" src={src} style={style} onLoad={() => {
          this.setState({
            show_bg_color: false
          })
        }} />
      </LazyLoad>
    )
  }
}