import Logger from './Logger'
import Backend from './backend'
import CloudDemoEnv from './CloudDemoEnv';
import DemoActionsManager from './DemoActionsManager';

export default class PnpDemoEnv extends CloudDemoEnv {

    constructor(props) {
        super(props)

        Logger.log("PnpDemoEnv called");

        this.isHybridMode = this.isHybridMode.bind(this);
        this.switchChannel = this.switchChannel.bind(this);
        this.showPip = this.showPip.bind(this);
        this.hidePip = this.hidePip.bind(this);
        this.hideOverlay = this.hideOverlay.bind(this);
        this.isPipShown = false;
        this.actionsManager = new DemoActionsManager(this);
        this.backend = Backend.get();
    }

    isHybridMode() {
        return false;
    }

    isBackgroundPosterEnabled() {
        return false;
    }

    switchChannel(channelId) {
    }

    switchToSource(source) {
    }

    sendRemoteKey(key) {
        key = key.toString();
        this.backend.performWebRemoteKey(key);
    }

    showDeeplink(deeplink) {
    }

    showPip() {
    }

    hidePip() {
    }

    hideOverlay() {
    }

    getTVInputs() {
    }

    getHeaders() {
        return {
            'X-Api-Host': 'com.cloud.demo',
            'X-Device-Manufacturer': 'Cloud Demo',
            'X-Device-Model': 'Cloud Demo',
            'X-Device-Product': 'PnP OTT Web Demo',
            'X-Device-Fingerprint': 'PnP OTT Web Demo',
            'X-API-Key': "api-key-8451df3c-axb7-4034"
        }
    }

    isAlexaEnabled(){
        return false
    }

    isLanguageSelectionEnabled() {
        return false
    }

    getProductID() {
        return 1452
    }

    getProductName() {
        return "PnP"
    }

    isYoutubeTrailersEnabled(){
        return false
    }

    getBannerZoneID(){
        return 38 
    }
}
