import React, { Component } from 'react';
import Logger from './Logger'

import CenterInsideImage from './CenterInsideImage';
import CardMeta from './CardMeta';
import Backend from './backend'

import { truncate } from 'underscore.string';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class ChannelCard extends Component {

  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    //set referrer
    if ((this.props.location.pathname.lastIndexOf('on_air') !== -1) && (Backend.getEnv().referrerURL.lastIndexOf("/on_air") === -1)){
      Backend.getEnv().referrerURL.push("/on_air");
    } else if ((this.props.location.pathname.lastIndexOf('channels') !== -1) && (Backend.getEnv().referrerURL.lastIndexOf("/channels") === -1)){
      Backend.getEnv().referrerURL.push("/channels");
    }

    this.props.history.push({
      pathname: "/detail/channel/" + this.props.channel.id,
    })

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    var channel = this.props.channel;
    var title = channel.name;
    var link = '#';

    if (!channel && this.props.episode) {
        channel = this.props.episode.channel;
        link = "/search/" + this.props.episode.channel.name;
    }

    return (
        <div className={"card channelCard NavCard " + (this.props.isFocused?"cardFocus":"")}>
          <Link to={link} onClick={this.onClick}>
            <CenterInsideImage src={"http://sensara-static-files.s3.amazonaws.com/channels-sized/"+channel.id+".jpg"} alt={channel.name} />
            <CardMeta title={truncate(title, 13)} />
            <div className="cardOverlay" />
          </Link>
        </div>
      )
  }
}

export default withRouter(ChannelCard)