import React, { Component } from 'react';

import ChannelCard from './ChannelCard'

import Backend from './backend'

export default class ChannelGroupView extends Component {
  
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    var group = this.props.group;
    var episodes = group.episodes;
    var backend = Backend.get();
    backend.setActiveEpisodes(episodes, group.title);
  }

  render() {

    var group = this.props.group;

    var items = [];
    var episodes = group.episodes;
    for (var i=0; i < episodes.length; i++) {
        var episode = episodes[i];

        items.push(<ChannelCard isFocused={this.props.isFocusedRow && this.props.focusedCol===i}
            key={episode.channel.id} channel={episode.channel} episode={episode} onClick={this.onClick} />);
    }

    var translateStyle = {
        "transform": "translateX("+this.props.nav.getTranslateX(247, this.props.row)+"px)"
    }

    return (
        <div className={"channelGroup NavRow " + (this.props.isFocusedRow?"activeRow":"inactiveRow")}>
            <div className="cardTitle">{ group.title }</div>
            <div className="list-container" style={translateStyle}>
                {items}
            </div>
            <div className="clearBoth" />
        </div>
      );
  }
}