import React from 'react';
import { Link } from "react-router-dom";
import { ACTION_VIEW_DEEPLINK, ACTION_VIEW_DETAIL } from "./SDKActionsManager";

export const KEYCODES = {
    "UP": 38,
    "DOWN": 40,
    "ESC": 27,
    "ENTER": 13,
    "BACKSPACE": 8,
    "RIGHT": 39,
    "LEFT": 37,
    "BACK": 461,
    "MENU": 93,
}

export const scrollToTop = () => {
    requestAnimationFrame(() => {
        let app = document.querySelector(".App")
        if (app.scrollTop !== 0) {
            app.style.display = "none"
            requestAnimationFrame(() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                requestAnimationFrame(() => {
                    app.style.display = "block"
                    app.scrollTop = 0;
                })
            })
        }
    })
}

export const isTVView = () => {
    return window.location.href.indexOf("/tv/", 0) !== -1
}

export const checkIsFullScreen = () => {
    return document.fullScreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen || (document.msFullscreenElement != null);
}

export const goFullScreen = (element) => {
    var isFullScreen = checkIsFullScreen()
    console.log(isFullScreen, element);
    if (isFullScreen === false && element) {

        if (element.requestFullscreen) {
            element.requestFullscreen().catch(err => {
                console.log("rejected", err);
            });
        }
        else if (element.msRequestFullscreen) {
            element.msRequestFullscreen().catch(err => {
                console.log("rejected", err);
            });
        }
        else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen().catch(err => {
                console.log("rejected", err);
            });
        }
        else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen().catch(err => {
                console.log("rejected", err);
            });
        }
    }
}

export const getBannerLink = (promotion, children = null, className) => {
    let action_type = promotion?.action.action_type
    let link = children
    if (action_type && [ACTION_VIEW_DEEPLINK, ACTION_VIEW_DETAIL].includes(action_type)) {
        if (action_type === ACTION_VIEW_DETAIL) {
            let [itemType, itemId] = promotion?.action.action_id.split(":", 2)
            link = <Link className={className} to={`/detail/${itemType}/${itemId}`}>
                {children}
            </Link>
        } else if (action_type === ACTION_VIEW_DEEPLINK) {
            link = <a className={className} target="_blank" href={promotion?.action.action_id}>
                {children}
            </a>
        }
    }
    return link
}